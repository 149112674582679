<html>
  <button mat-stroked-button color="primary" class="access_button" (click)="openAccessProtocol()"><mat-icon class="access_icon">vpn_key</mat-icon>Zutrittsprotokoll öffnen</button>
  <mat-divider [inset]="true"></mat-divider>
  <mat-card-header class="divider">
  <mat-card-title>RegioLock Geräte</mat-card-title>
</mat-card-header>
  <div>
    <mat-progress-bar mode="indeterminate" *ngIf="loadRegiolocks"></mat-progress-bar>
    <table mat-table [dataSource]="regiolockSource" matSort class="mat-elevation-z8 regiolocktable" #regiolockTable="matSort" *ngIf="!loadRegiolocks">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > Status </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="getSatelliteStatus(element)==1" class="status_indicator status_green"></div>
          <div *ngIf="getSatelliteStatus(element)==2" class="status_indicator status_grey"></div>
          <div *ngIf="getSatelliteStatus(element)==3" class="status_indicator status_red"></div>
        </td>
      </ng-container>

        <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Aktionen </th>
       <td mat-cell *matCellDef="let element">
        <mat-icon class="actionDelete" (click)="deleteDevice(element._id)">desktop_access_disabled</mat-icon>
        <mat-icon class="actionEdit" (click)="editDevice(element)">edit</mat-icon>
        <mat-icon *ngIf="satelliteStatus==1" class="actionEdit" (click)="restartSatellite(element)">refresh</mat-icon>
        <mat-icon class="actionEdit" (click)="openSatelliteProtocol(element)">list_alt</mat-icon>

       </td>
       </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedRegiolockColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedRegiolockColumns;"></tr>
    </table>
    <button mat-raised-button color="primary" class="regiolockbutton" (click)="newRegiolock()">Neues RegioLock Gerät</button>
  </div>
  <div class="divider"></div>
  <mat-divider [inset]="true"></mat-divider>
  <mat-card-header class="divider">
    <mat-card-title>Codetabellen</mat-card-title>
  </mat-card-header>
  <div>
    <mat-progress-bar mode="indeterminate" *ngIf="loadCodeTables"></mat-progress-bar>
    <table mat-table [dataSource]="codetablesource" matSort class="mat-elevation-z8 regiolocktable" #codeTable="matSort" *ngIf="!loadCodeTables">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Aktiv Column -->
      <ng-container matColumnDef="aktiv">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > Aktiv </th>
        <td mat-cell *matCellDef="let element"><mat-checkbox [checked]="element.active" (change)="changeActiveStateCode($event, element)"></mat-checkbox></td>
      </ng-container>

        <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Aktionen </th>
       <td mat-cell *matCellDef="let element">
        <mat-icon class="actionDelete" (click)="deleteTable(element._id)">delete</mat-icon>
        <mat-icon class="actionEdit" (click)="editCodeTable(element)">edit</mat-icon>

       </td>
       </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedCodetableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedCodetableColumns;"></tr>
    </table>
    <button mat-raised-button color="primary" class="regiolockbutton" (click)="newCodetable()">Neue Codetabelle</button>
  </div>
  <!-- <div class="divider"></div>
  <mat-divider [inset]="true"></mat-divider>
  <mat-card-header class="divider">
    <mat-card-title>Zeittabellen</mat-card-title>
  </mat-card-header>
 <div>
    <mat-progress-bar mode="indeterminate" *ngIf="loadTimeTables"></mat-progress-bar>
    <table mat-table [dataSource]="timetablesource" matSort class="mat-elevation-z8 regiolocktable" #timeTable="matSort" *ngIf="!loadTimeTables">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>


      <ng-container matColumnDef="aktiv">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > Aktiv </th>

 </ng-container>


    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Aktionen </th>
       <td mat-cell *matCellDef="let element">

        <mat-icon class="actionEdit" (click)="editDevice(element._id)">edit</mat-icon>

       </td>
       </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedTimetableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedTimetableColumns;"></tr>
    </table>
    <button mat-raised-button color="primary" class="regiolockbutton" (click)="newTimetable()">Neue Zeittabelle</button>
  </div>-->
</html>

