import { TokenService } from './../token.service';
import { Machine, Client, User, Manufacturer, MachineType, MachineConfig, Location, Satellite, Product } from './../schemas';
import { Component, EventEmitter, Inject, Input, OnInit, Output, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { DatePipe } from '@angular/common';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { config, Subscription, Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';

export interface DialogData {
  option: number;
  formdata: Machine;
  locations: any;
  manufacturers: any;
  machinetypes: any;
}

export interface DialogDataConfirm {
  text: string;
  option: number;
}

export interface DialogDataProtocol {
  selectedMachine: Machine;
  option: number;
  products: any;
}


export interface DialogDataRestart {
  id: string;
}

export interface DialogDataInit {
  id: string;
}

export interface DialogDataTemp {
  machine: Machine;
}


@Component({
  selector: 'app-machines',
  templateUrl: './machines.component.html',
  styleUrls: ['./machines.component.css']
})
export class MachinesComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() currentClient: Client;
  @Input() loggedInUser: User;
  @ViewChild('machinesTable', { read: MatSort, static: true }) macSort: MatSort;
  @ViewChild('machinesPaginator', {static: true}) macPaginator: MatPaginator;
  @ViewChild('productsTable', { read: MatSort, static: false }) proSort: MatSort;
  //@ViewChild('productsPaginator', {static: false}) proPaginator: MatPaginator;

  token: string;
  tokenSubscribtion: Subscription;
  displayedColumns: string[] = ['name', 'location', 'actions'];
  displayedColumnsProd: string[] = ['shelf', 'active', 'actions', 'name', 'stock', 'maxstock'];
  dataSource = new MatTableDataSource();
  proddataSource = new MatTableDataSource();
  formProducts: any;
  formActive: any;
  formStock: any;
  formMaxStock: any;
  formShelfs: any;
  machinesForm: any;
  machineLocations = [];
  machineManufacturers = [];
  machineTypes = [];
  allMachines = [];
  allProducts = [];
  loadingMachines = false;
  loadingProducts = false;
  machineSelected = false;
  loadingProtocoll = false;
  selectedMachine: Machine;
  machineIsBinded = false;
  satellitesIntervall: any;
  temperatureIntervall: any;
  protocollIntervall: any;
  satelliteStatus: number;
  satelliteStatusOK = false;
  initstatus = 0;
  selectedSatellite: Satellite;
  satelliteSatusLoading = false;
  tempAvailable = false;
  tempLoading = false;
  showAllProducts = false;
  evatype = 0;



  constructor(private api: ApiService, public dialog: MatDialog, private tokenService: TokenService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.tokenSubscribtion = this.tokenService.currentToken.subscribe(token => this.token = token);
    this.proddataSource.filterPredicate = (data: Product, filter: string) => {
     return data.active == Boolean(filter);
     };
    this.getMyMachines();
    this.getAllMachinetypes();
    this.getAllManufacturers();
    this.machinesForm = this.fb.group({
      formProducts: this.fb.array ([]),
      formActive: this.fb.array ([]),
      formShelfs: this.fb.array ([]),
      formStock: this.fb.array ([]),
      formMaxStock: this.fb.array ([])
    });

   }

   ngOnDestroy(): void {
    clearInterval(this.satellitesIntervall);
    clearInterval(this.protocollIntervall);
    this.tokenSubscribtion.unsubscribe();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.macSort;
    this.dataSource.paginator = this.macPaginator;
    this.proddataSource.sort = this.proSort;
    //this.proddataSource.paginator = this.proPaginator;
  }

  applyFilter = (event: Event) => {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterProduct = (event: Event) => {
    const filterValue = (event.target as HTMLInputElement).value;
    this.proddataSource.filter = filterValue.trim().toLowerCase();
  }

  fillProducts = (index: number) => {
    const formValue = this.machinesForm.value;
    const maxProducts = formValue.formMaxStock[index];
    const currentProducts = formValue.formStock[index];
    let difference = 0;
    if (currentProducts < maxProducts) {
      this.formStock.controls[index].setValue(maxProducts);
      difference = maxProducts - currentProducts;
    }
  }

  fillAllProducts = () => {
    let index = 0;
    this.formShelfs.controls.forEach(() => {
      const maxProducts = this.formMaxStock.controls[index].value;
      const currentProducts = this.formStock.controls[index].value;
      let difference = 0;
      if (currentProducts < maxProducts) {
        this.formStock.controls[index].setValue(maxProducts);
        difference = maxProducts - currentProducts;
      }
      index++;
    });


  }



  newMachine = () => {
    const dialogRef = this.dialog.open(DialogMachine, {
      width: '40%',
      data: {
        option: 1,
        formdata: {
          name: '',
          location: 0,
          manufacturer: 0,
          model: 0,
          serialnumber: '',
          owner: this.currentClient._id,
          temp_min: null,
          temp_max: null,
          hum_min: null,
          hum_max: null,
          config: '',
          raspkey: '',
          active: true
        },
        locations: this.machineLocations,
        manufacturers: this.machineManufacturers,
        machinetypes: this.machineTypes
      },
      hasBackdrop: true,
      disableClose: true
    });

    dialogRef.componentInstance.getValues.subscribe((option: number) => {
    if (option === 1) {
      return this.token;
    }
    });

    dialogRef.afterClosed().subscribe(result => {
    if (result !== 0) {
      const formValue = result.formdata;
      if (!result.raspkeyValidated) {
        formValue.raspkey = '';
      } else {
        formValue.raspkey = result.raspkey;
      }
      const productsArray = [];
      // Konfiguration aus der Datenbank auslesen
      const readConfig = new Promise ((resolve) => {
        this.api.getConfigForModel(formValue.model, this.token)
        .subscribe((res: any) => {
          if (res) {
            resolve(res);
          }
          });
      });
      readConfig.then((configuration: MachineConfig) => {
        configuration.productIdObject.forEach((productId) => {
          const productsObject = {
            shelf: productId.shelf,
            product: '',
            stock: 0,
            active: true,
            lastupdate: Date.now(),
            laststock: 0,
            lastvalue: 0,
            maxproducts: 0
          };
          productsArray.push(productsObject);
        });
        formValue.confrows = productsArray;
        formValue.config = configuration._id;

        this.api.addMachine(formValue, this.token)
      .subscribe((res: any) => {
        if (result.raspkeyValidated) {
        // Satellit für Bindung vorbereiten
        this.api.getSatelliteWithKey(formValue.raspkey, this.token)
        .subscribe((resU: any) => {
          if (resU) {
          const selectedSatellite = resU[0];
          selectedSatellite.curr_machine = res._id;
          selectedSatellite.mode = 1;
          selectedSatellite.option = 3;
          selectedSatellite.curr_location = formValue.location;
          selectedSatellite.available = true;
          // Update Satellitendaten
          this.api.updateSatellite(selectedSatellite._id, selectedSatellite, this.token)
        .subscribe((resUp: any) => {
          if (resUp) {
          }
          });
          }
          });
        }
        this.getMyMachines();
        }, (err: any) => {
        });
      });
    }
    });
  }

getMyMachines = () => {
  this.loadingMachines = true;
  this.getAllLocations().then(() => {
    this.getMyProducts().then(() =>  {
    this.api.getMachinesForClient(this.currentClient._id, this.token)
    .subscribe((res: any) => {
        this.dataSource.data = res;
        this.allMachines = res;
        this.loadingMachines = false;
      }, (err: any) => {
      });
  });
  });
}

openDialogProtocol = (element: Machine) => {
  this.dialog.open(DialogProtocoll, {
    width: '60%',
    data: {
      option: 1,
      selectedMachine: element,
      products: ''
    },
    hasBackdrop: true,
    disableClose: false
  });
}

openDialogTemp = (element: Machine) => {
  this.dialog.open(DialogTemp, {
    width: '60%',
    data: {
      machine: element
    },
    hasBackdrop: true,
    disableClose: false
  });
}

openDialogSells = (element: Machine) => {
  this.dialog.open(DialogProtocoll, {
    width: '60%',
    data: {
      option: 2,
      selectedMachine: element,
      products: this.allProducts
    },
    hasBackdrop: true,
    disableClose: false
  });
}

getAllManufacturers = () => {
  this.api.getActiveManufacturers(this.token)
  .subscribe((res: any) => {
    this.machineManufacturers = res;
    }, (err: any) => {
    });
}

getAllMachinetypes = () => {
  this.api.getActiveMachineTypes(this.token)
  .subscribe((res: any) => {
      this.machineTypes = res;
    }, (err: any) => {
    });
}

getAllLocations = () => {
  return new Promise<void>((resolve) => {
  this.api.getLocationsForClient(this.currentClient._id, this.token)
  .subscribe((res: any) => {
      this.machineLocations = res;
      resolve();
    }, (err: any) => {
    });
  });
}

deleteMachine = (id: string) => {
  const dialogRef = this.dialog.open(DialogConfirmMachine, {
    width: '40%',
    data: {
     text: 'Soll der Automat wirklich gelöscht werden?',
     option: 1
      },
      hasBackdrop: true,
      disableClose: true
  });
  dialogRef.afterClosed().subscribe(result => {
  if (result === 1) {
    this.loadingMachines = true;
    this.api.deleteMachine(id, this.token)
    .subscribe((res: any) => {
      // Satelliten entbinden
      const machine = res.raspkey;
      if (machine.length > 0) {
      this.api.getSatelliteWithKey(machine, this.token)
      .subscribe((resS: any) => {
        this.machineSelected = false;
        this.unbindSatellite(3, resS[0]._id);
        clearInterval(this.satellitesIntervall);
      });
    }
      // Produkte in der Produktedatenbank entbinden
      const confrows = res.confrows;
      if (confrows) {
        confrows.forEach((shelf) => {
          if (shelf.product) {
            // Produkt holen
            this.api.getProduct(shelf.product, this.token)
            .subscribe((resP: any) => {
              if (resP) {
                const gotProduct: Product = resP;
                const productShelfs = gotProduct.shelfs;
                productShelfs.forEach((prod, index) => {
                  let shelfsLength = prod.shelf.length;
                  if (prod.machine === id) {
                    // Eintrag aus dem Produktearray entfernen
                    gotProduct.shelfs.splice(index, 1);
                    shelfsLength = shelfsLength - 1;
                    if (shelfsLength === 0) {
                      // Löschen des gesamten Arrays, wenn der letzte Eintrag entfernt wurde
                      gotProduct.shelfs.splice(index, 1);
                    }
                  }
                });
                // Geänderten Eintrag wieder in die Datenbank werfen
                this.api.updateProduct(gotProduct._id, gotProduct, this.token)
                .subscribe(() => {
                  this.getMyMachines();
                });
              }
            });
          }
        });
      }
      this.getMyMachines();
      }, (err: any) => {
      });
    }
  });
}

initMachine = () => {
  const dialogRef = this.dialog.open(DialogConfirmMachine, {
    width: '40%',
    data: {
     text: "Soll das Gerät neu initialisiert werden? Hierbei werden alle Verkäufe zwischen der letzten erfolgreichen Auslesung und dem Ende der Initialisierung nicht gespeichert",
     option: 1
      },
      hasBackdrop: true,
      disableClose: true
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result === 1) {
      const values = {
        init_request: true,
        initack: false
      };
      if (this.selectedSatellite) {
        const satellite = this.selectedSatellite;
        this.api.updateSatellite(satellite._id, values, this.token)
      .subscribe((res: any) => {
        this.dialog.open(DialogInitMachine, {
          width: '40%',
          data: {
           id: satellite._id
            },
            hasBackdrop: true,
            disableClose: true
        });
        }, (err: any) => {
        });
      }
      }
    });
}

updateMachine = (machine: Machine) => {
  const dialogRef = this.dialog.open(DialogMachine, {
    width: '40%',
    data: {
      option: 2,
      formdata: machine,
      locations: this.machineLocations,
      manufacturers: this.machineManufacturers,
      machinetypes: this.machineTypes
    },
    hasBackdrop: true,
    disableClose: true
  });

  dialogRef.afterClosed().subscribe(result => {
  if (result !== 0) {
    this.machineSelected = false;
    const formValue = result.formdata;
    if (!result.raspkeyValidated) {
      formValue.raspkey = '';
    } else {
      formValue.raspkey = result.raspkey;
    }
    this.api.updateMachine(machine._id, formValue, this.token)
    .subscribe((res: any) => {
      if (result.raspkeyValidated) {
      // Satellit für Bindung vorbereiten
      this.api.getSatelliteWithKey(formValue.raspkey, this.token)
      .subscribe((resU: any) => {
        if (resU) {
        const selectedSatellite = resU[0];
        selectedSatellite.curr_machine = res._id;
        selectedSatellite.curr_location = formValue.location;
        selectedSatellite.mode = 1;
        selectedSatellite.available = true;
        // Update Satellitendaten
        this.api.updateSatellite(selectedSatellite._id, selectedSatellite, this.token)
      .subscribe((resUp: any) => {
        if (resUp) {
        }
        });
        }
        });
      }
      this.getMyMachines();
      }, (err: any) => {
      });
  }
  });
}

changeActiveState = (evt: any, element: Machine) => {
  element.active = evt.checked;
  this.api.updateMachine(element._id, element, this.token)
  .subscribe(() => {
  this.getMyMachines();
    }, (err: any) => {
    });
}

changeStateSaveProducts = (evt: any) => {
  let dialogText = '';
  if (evt.checked) {
    dialogText = 'Soll die Speicherung der Verkäufe aktiviert werden? Es werden alle weiteren Verkäufe in die Statistik aufgenommen und der Warenstand entsprechend reduziert.';
  } else {
    dialogText = 'Soll die Speicherung der Verkäufe deaktiviert werden? Alle weiteren Verkäufe werden nicht in die Statistik aufgenommen. Als Information werden die Verkäufe im Statusprotokoll verzeichnet.';
  }
  const dialogRef = this.dialog.open(DialogConfirmMachine, {
    width: '40%',
    data: {
     text: dialogText,
     option: 1
      },
      hasBackdrop: true,
      disableClose: true
  });

dialogRef.afterClosed().subscribe(result => {
    if (result === 1) {
      this.selectedMachine.saveSells = evt.checked;
      this.api.updateMachine(this.selectedMachine._id, this.selectedMachine, this.token)
      .subscribe(() => {
      this.getMyMachines();
        }, () => {
        });
    } else {
      if (evt.checked) {
        this.selectedMachine.saveSells = false;
      } else {
        this.selectedMachine.saveSells = true;
      }
          }
    this.updateSelectedMachine();
  });


}

updateSelectedMachine = () => {
  this.api.getMachine(this.selectedMachine._id, this.token).subscribe((res: any) => {
    this.selectedMachine = res;
    clearInterval(this.satellitesIntervall);
    clearInterval(this.temperatureIntervall);
    if (res.raspkey.length > 0) {
      this.machineIsBinded = true;
      this.satelliteSatusLoading = true;
      this.getSatellitesStatus();
      this.getTemperature();
    } else {
      this.machineIsBinded = false;
      clearInterval(this.satellitesIntervall);
      clearInterval(this.temperatureIntervall);
    }

  });
}

changeActiveStateProduct = (evt: any, element: any) => {
  // Dialog öffnen
  const oldProd = element.product;
  const shelf = element.shelf;
  const i = this.proddataSource.filteredData.indexOf(element);
  let dialogText = '';
  if (element.product.length > 0 && !evt.checked) {
    dialogText = 'Soll das Warenfach wirklich deaktiviert werden? Zugewiesene Produkte werden gelöscht!';
  } else if (!evt.checked) {
    dialogText = 'Soll das Warenfach wirklich deaktiviert werden?';
  } else {
    dialogText = 'Soll das Warenfach aktiviert werden?';
  }
  const dialogRef = this.dialog.open(DialogConfirmMachine, {
      width: '40%',
      data: {
       text: dialogText,
       option: 1
        },
        hasBackdrop: true,
        disableClose: true
    });

  dialogRef.afterClosed().subscribe(result => {

      if (result === 1) {
        element.active = evt.checked;
        // Produkt in der Maschine ändern
        this.loadingMachines = true;
        const shelfindex = this.selectedMachine.confrows.findIndex(elementF => elementF.shelf === shelf);
        this.selectedMachine.confrows[shelfindex].product = '';
        this.selectedMachine.confrows[shelfindex].active = evt.checked;
        this.selectedMachine.confrows[shelfindex].stock = 0;
        this.updateProductsTable();
        // Upload der neuen Maschine
        this.api.updateMachine(this.selectedMachine._id, this.selectedMachine, this.token)
        .subscribe(() => {
          this.getMyMachines();
          // Update der Produktdaten
          // Altes Produkt updaten, wenn ein Produkt gebunden war
          if (oldProd.length > 0) {
          this.api.getProduct(oldProd, this.token)
          .subscribe((resP: any) => {
            if (resP) {
              const gotProduct: Product = resP;
              const productShelfs = gotProduct.shelfs;
              productShelfs.forEach((prod, index) => {
                if (prod.machine === this.selectedMachine._id) {
                  prod.shelf.forEach((s, ind) => {
                    if (s === shelf) {
                      // Eintrag aus dem Produktearray entfernen
                      gotProduct.shelfs[index].shelf.splice(ind, 1);
                    }
                  });
                }
              });
              // Geänderten Eintrag wieder in die Datenbank werfen
              this.api.updateProduct(gotProduct._id, gotProduct, this.token)
              .subscribe();
            }
          });
        }
          }, (err: any) => {
          });
      } else {
        this.formActive.at(i).setValue(oldProd);
      }
      });

}

getLocationName = (id: string) => {
  return this.machineLocations.find(t => t._id === id).name;
}

getProductName = (id: string) => {
  if (this.allProducts.find(t => t._id === id) === undefined) {
    return 'LEER';
  } else {
    return this.allProducts.find(t => t._id === id).name;
  }
}

getManufacturerName = (id: string) => {
  return this.machineManufacturers.find(t => t._id === id).name;
}

getModelName = (id: string) => {
  return this.machineTypes.find(t => t._id === id).name;
}

selectMachine = (element: Machine) => {
  this.machineSelected = true;
  this.selectedMachine = element;
  this.updateProductsTable();
  if (element.raspkey.length > 0) {
    this.machineIsBinded = true;
    this.satelliteSatusLoading = true;
    // Abfrage, welcher EVA-DTS Typ verwendet wird
    this.api.getMachineType(element.model, this.token)
    .subscribe((resConfig: any) => {
      if (resConfig) {
        if (resConfig.evatype !== 0) {
          this.evatype = resConfig.evatype;
        } else {
          this.evatype = 0;
        }
        this.getSatellitesStatus();
        this.getTemperature();
      }
      });

  } else {
    this.machineIsBinded = false;
    clearInterval(this.satellitesIntervall);
    clearInterval(this.temperatureIntervall);
  }
}

setShowAllProducts = (showAll: boolean) => {
  this.showAllProducts = showAll;
  this.updateProductsTable();
}

updateProductsTable = () => {
  const element = this.selectedMachine;
  this.formProducts = this.machinesForm.get('formProducts') as FormArray;
  this.formActive = this.machinesForm.get('formActive') as FormArray;
  this.formStock = this.machinesForm.get('formStock') as FormArray;
  this.formShelfs = this.machinesForm.get('formShelfs') as FormArray;
  this.formMaxStock = this.machinesForm.get('formMaxStock') as FormArray;
  this.formProducts.controls = [];
  this.formActive.controls = [];
  this.formShelfs.controls = [];
  this.formStock.controls = [];
  this.formMaxStock.controls = [];
  const tempObjectProducts = [];
  element.confrows.forEach((conf) => {
    if (this.showAllProducts) {
      this.formProducts.push(new FormControl(conf.product));
      this.formActive.push(new FormControl(conf.active));
      this.formShelfs.push(new FormControl(conf.shelf));
      this.formStock.push(new FormControl(conf.stock, [Validators.pattern('^([1-9]?\\d)$'), Validators.required]));
      this.formMaxStock.push(new FormControl(conf.maxproducts, [Validators.pattern('^([1-9]?\\d)$'), Validators.required]));
      tempObjectProducts.push(conf);
    } else {
      if (conf.active) {
      this.formProducts.push(new FormControl(conf.product));
      this.formActive.push(new FormControl(conf.active));
      this.formShelfs.push(new FormControl(conf.shelf));
      this.formStock.push(new FormControl(conf.stock, [Validators.pattern('^([1-9]?\\d)$'), Validators.required]));
      this.formMaxStock.push(new FormControl(conf.maxproducts, [Validators.pattern('^([1-9]?\\d)$'), Validators.required]));
      tempObjectProducts.push(conf);
      }
    }
  });
  this.proddataSource.data = tempObjectProducts;
}

changeProduct = (element: any, evt: any) => {
  let newValue = '';
  if (evt.value) {
    newValue = evt.value;
  }
  const shelf = element.shelf;
  const oldProd = element.product;
  const i = this.proddataSource.filteredData.indexOf(element);
  let dialogText = '';
  if (oldProd) {
    dialogText = 'Soll das Produkt wirklich geändert werden? Der Warenstand des alten Produkts wird gelöscht!';
  } else {
    dialogText = 'Soll das Produkt wirklich zugewiesen werden?';
  }
  const dialogRef = this.dialog.open(DialogConfirmMachine, {
      width: '40%',
      data: {
       text: dialogText,
       option: 1
        },
        hasBackdrop: true,
        disableClose: true
    });
  dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        // Produkt in der Maschine ändern
        this.loadingMachines = true;
        // Filtern, um welches Produkt es sich handelt
        const shelfindex = this.selectedMachine.confrows.findIndex(elementF => elementF.shelf === shelf);
        this.selectedMachine.confrows[shelfindex].product = newValue;
        this.selectedMachine.confrows[shelfindex].stock = 0;
        // Upload der neuen Maschine
        this.api.updateMachine(this.selectedMachine._id, this.selectedMachine, this.token)
        .subscribe((res: any) => {
          // Update der Produktdaten
          // Altes Produkt updaten
          if (oldProd) {
          this.api.getProduct(oldProd, this.token)
          .subscribe((resP: any) => {
            if (resP) {
              const gotProduct: Product = resP;
              const productShelfs = gotProduct.shelfs;
              productShelfs.forEach((prod, index) => {
                let shelfsLength = prod.shelf.length;
                if (prod.machine === this.selectedMachine._id) {
                  prod.shelf.forEach((s, ind) => {
                    if (s === shelf) {
                      // Eintrag aus dem Produktearray entfernen
                      gotProduct.shelfs[index].shelf.splice(ind, 1);
                      shelfsLength = shelfsLength - 1;
                      if (shelfsLength === 0) {
                        // Löschen des gesamten Arrays, wenn der letzte Eintrag entfernt wurde
                        gotProduct.shelfs.splice(index, 1);
                      }
                    }
                  });
                }
              });
              // Geänderten Eintrag wieder in die Datenbank werfen
              this.api.updateProduct(gotProduct._id, gotProduct, this.token)
              .subscribe();
            }
          });
        }
          // Neues Produkt holen
          if (newValue) {
            this.api.getProduct(newValue, this.token)
          .subscribe((resP: any) => {
            if (resP) {
              const gotProduct: Product = resP;
              const productShelfs = gotProduct.shelfs;
              if (productShelfs.find(el => el.machine === this.selectedMachine._id) === undefined) {
                // Noch kein Eintrag für den Automaten vorhanden
                gotProduct.shelfs.push({
                  location: this.selectedMachine.location,
                  machine: this.selectedMachine._id,
                  shelf: [shelf]
                });
              } else {
              productShelfs.forEach((prod, index) => {
                // Neuen Eintrag in die Produktedatenbank eintragen
                  if (prod.machine === this.selectedMachine._id) {
                    gotProduct.shelfs[index].shelf.push(shelf);
                  }
              });
            }
              // Geänderten Eintrag wieder in die Datenbank werfen
              this.api.updateProduct(gotProduct._id, gotProduct, this.token)
              .subscribe();
            }
          });
        }
          }, (err: any) => {
          });
      } else {
        this.formProducts.at(i).setValue(oldProd);
      }
      this.getMyMachines();
      this.updateSelectedMachine();
      });


}

getTemperature = () => {
  this.tempLoading = true;
  this.api.getMachine(this.selectedMachine._id, this.token).subscribe((res: any) => {
    if (res.curr_hum > 0) {
      this.selectedMachine = res;
      this.tempAvailable = true;
      this.tempLoading = false;
        } else {
        this.tempAvailable = false;
        }
    this.temperatureInterval();
  });
}


getSatellitesStatus = () => {
  this.api.getSatelliteWithKey(this.selectedMachine.raspkey, this.token)
  .subscribe((res: any) => {
    this.satelliteSatusLoading = false;
    if (res) {
    if (res.find(t => t.raspkey === this.selectedMachine.raspkey)) {
      const satellite = res.find(t => t.raspkey === this.selectedMachine.raspkey);
      this.selectedSatellite = satellite;
      if (satellite.curr_machine === this.selectedMachine._id && satellite.flag_stat1) {
        this.initstatus = satellite.initstatus;
        const currentTime = new Date().getTime();
        const lastUpdateTime = new Date(satellite.lastUpdate).getTime();
        const diff = currentTime - lastUpdateTime;
        const difference = Math.floor(diff / 1000 % 60);
        if (difference > 20) {
          this.satelliteStatus = 3;
          this.satelliteStatusOK = true;
        } else {
          this.satelliteStatus = 1;
          this.satelliteStatusOK = false;
        }
      } else if (satellite.active) {
        this.satelliteStatus = 2;
        this.satelliteStatusOK = false;
      }
    }
  }
    }, (err: any) => {
    });
  this.satellitesInterval();
}

satellitesInterval = () => {
  clearInterval(this.satellitesIntervall);
  if (this.machineIsBinded) {
  this.satellitesIntervall = setInterval(() => {
   this.getSatellitesStatus();
  }, 9000);
}
}

temperatureInterval = () => {
  clearInterval(this.temperatureIntervall);
  if (this.machineIsBinded) {
  this.temperatureIntervall = setInterval(() => {
   this.getTemperature();
  }, 9000);
}
}

unbindSatellite = (optionV: number, id: string) => {
  let satId = id;
  if (optionV === 1) {
    satId = this.selectedSatellite._id;
  }
  const dialogRef = this.dialog.open(DialogConfirmMachine, {
    width: '40%',
    data: {
     text: 'Soll das Gerät wirklich entbunden werden?',
     option: optionV
      },
      hasBackdrop: true,
      disableClose: true
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result === 1) {
      const values = {
        flag_stat1: false,
        option: 0,
        ack_option: false,
        curr_location: '',
        curr_machine: '',
        available: false,
        int_ip: '',
        ext_ip: ''
      };
      this.api.updateSatellite(satId, values, this.token)
      .subscribe((resU: any) => {
        this.selectedMachine.raspkey = '';
        if (optionV === 1) {
        this.api.updateMachine(this.selectedMachine._id, this.selectedMachine, this.token)
        .subscribe(() => {
  clearInterval(this.satellitesIntervall);
  clearInterval(this.protocollIntervall);
  this.getMyMachines();
  this.dialog.open(DialogConfirmMachine, {
    width: '40%',
    data: {
     text: 'Bindung aufgehoben. Das Gerät muss für eine erneute Bindung manuell neu gestartet werden (Aus- und Einstecken).',
     option: 2
      },
      hasBackdrop: true,
      disableClose: true
  });
    }, (err: any) => {
    });
  }
        }, (err: any) => {
        });

      }
    });
}

restartSatellite = (element: any) => {
  const dialogRef = this.dialog.open(DialogConfirmMachine, {
    width: '40%',
    data: {
     text: 'Soll das Gerät wirklich neu gestartet werden?',
     option: 1
      },
      hasBackdrop: true,
      disableClose: true
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result === 1) {
      const values = {
        option: 1,
        ack_option: false
      };
      if (this.selectedSatellite) {
        const satellite = this.selectedSatellite;
        this.api.updateSatellite(satellite._id, values, this.token)
      .subscribe((res: any) => {
        this.dialog.open(DialogRestartMachine, {
          width: '40%',
          data: {
           id: satellite._id
            },
            hasBackdrop: true,
            disableClose: true
        });
        }, (err: any) => {
        });
      }
      }
    });
}

getMyProducts = () => {
  return new Promise<void>((resolve) => {
    this.api.getProducts(this.currentClient._id, this.token)
  .subscribe((res: any) => {
    if (res) {
      this.allProducts = res;
      resolve();
    }
    }, (err: any) => {
    });
  });
}


ensure<T>(argument: T | undefined | null, message: string = 'This value was promised to be there.'): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }
  return argument;
}

editStock = () => {
const formValue = this.machinesForm.value;

// Neue Maschinendaten holen
this.api.getMachine(this.selectedMachine._id, this.token).subscribe((res: any) => {
  formValue.formShelfs.forEach((row, index) => {
    const shelfindex = res.confrows.findIndex(elementF => elementF.shelf === row);
    res.confrows[shelfindex].stock = +formValue.formStock[index];
    res.confrows[shelfindex].maxproducts = +formValue.formMaxStock[index];
    });
    // Daten speichern
this.api.updateMachine(res._id, res, this.token)
.subscribe((resUp: any) => {
  this.updateSelectedMachine();
  this.getMyMachines();
  }, (err: any) => {
  });
});
}

}

@Component({
  selector: 'dialog-machine',
  templateUrl: 'dialog-machine.html',
  styleUrls: ['./machines.component.css']
})
export class DialogMachine implements OnInit {
  getValues = new EventEmitter();
  newForm: FormGroup;
  loading = false;
  success = false;
  checked = true;
  machineLocations: any;
  machineManufacturers: any;
  machineModels: any;
  modelDisabled = true;
  falseRaspKey = false;
  raspKeyBounded = false;
  loadRaspKey = false;
  raspKeyValid = false;
  showCheckButton = false;
  raspKeyChecked = false;
  showCheckHint = false;
  raspKey: string;
  token: string;
  tokenSubscribtion: Subscription;


  constructor(
    public dialogRef: MatDialogRef<DialogMachine>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private api: ApiService,
    private tokenService: TokenService) {}

   onSubmit(): void {
     const returnValue = {
       formdata: this.newForm.value,
       raspkeyValidated: this.raspKeyValid,
       raspkey: this.raspKey
     };
     this.tokenSubscribtion.unsubscribe();
     this.dialogRef.close(returnValue);
  }

  cancleDialog(): void {
    this.tokenSubscribtion.unsubscribe();
    this.dialogRef.close(0);
  }

  public errorHandling = (control: string, error: string) => {
    return this.newForm.controls[control].hasError(error);
  }

  manufSelected = (event: any) => {
    if (event !== 0){
      this.modelDisabled = false;
    } else {
      this.modelDisabled = true;
      this.newForm.patchValue({model: 0});
    }
  }

  checkRaspKey = () => {
    const key = this.newForm.get('raspkey').value;
    this.raspKeyValid = false;
    this.showCheckButton = false;
    if (key) {
      this.loadRaspKey = true;
      this.api.checkRaspKey(key, this.token)
      .subscribe((res: any) => {
      this.showCheckHint = false;
      if (res === 0) {
        // Fehler: Key nicht gefunden
        this.raspKeyChecked = true;
        this.falseRaspKey = true;
        this.raspKeyValid = false;
        this.raspKeyBounded = false;
        this.loadRaspKey = false;
        this.showCheckButton = true;
      } else if (res === 1) {
        // Fehler: Bereits gebunden
        this.raspKeyChecked = true;
        this.falseRaspKey = false;
        this.raspKeyBounded = true;
        this.raspKeyValid = false;
        this.loadRaspKey = false;
        this.showCheckButton = true;
      } else if (res === 2) {
        // Erfolgreich
        this.raspKeyChecked = true;
        this.falseRaspKey = false;
        this.raspKeyBounded = false;
        this.raspKeyValid = true;
        this.loadRaspKey = false;
        this.showCheckButton = false;
        this.raspKey = key;
        this.newForm.controls.raspkey.disable();
      }
    }, (err: any) => {
    });
    } else {
      this.raspKeyValid = false;
      this.falseRaspKey = false;
      this.raspKeyChecked = false;
      this.raspKeyBounded = false;
      this.showCheckButton = false;
      this.newForm.get('raspkey').reset();
      this.newForm.updateValueAndValidity();
    }

  }

  raspKeyTouched = () => {
    const key = this.newForm.get('raspkey').value;
    if (key) {
      this.showCheckButton = true;
      this.showCheckHint = true;
    } else {
      this.raspKeyValid = false;
      this.falseRaspKey = false;
      this.raspKeyChecked = false;
      this.raspKeyBounded = false;
      this.showCheckButton = false;
      this.showCheckHint = false;
      this.newForm.get('raspkey').reset();
      this.newForm.updateValueAndValidity();
    }
  }

  falseKeyValidator = () => {
    return (): { [key: string]: boolean | null} => {
      if (this.falseRaspKey) {
        return { falseKey: true };
      } else {
        return null;
      }
    };
  }

  bindedValidator = () => {
    return (): { [key: string]: boolean | null} => {
      if (this.raspKeyBounded) {
        return { binded: true };
      } else {
        return null;
      }
    };
  }

deleteRaspKey = () => {
  this.raspKeyValid = false;
  this.falseRaspKey = false;
  this.raspKeyChecked = false;
  this.raspKeyBounded = false;
  this.showCheckButton = false;
  this.newForm.controls.raspkey.enable();
  this.newForm.get('raspkey').reset();
  this.newForm.updateValueAndValidity();
}

getManufacturerName = (id: string) => {
  return this.machineManufacturers.find(t => t._id === id).name;
}

getModelName = (id: string) => {
  return this.machineModels.find(t => t._id === id).name;
}

  ngOnInit(): void {
    this.tokenSubscribtion = this.tokenService.currentToken.subscribe(token => this.token = token);
    if (this.data.option === 2) {
      if (this.data.formdata.raspkey.length > 0){
        this.raspKeyValid = true;
        this.raspKeyChecked = true;
        this.raspKey = this.data.formdata.raspkey;
      }
      if (this.data.formdata.model.length > 0){
        this.modelDisabled = false;
      }
    }
    this.machineLocations = this.data.locations;
    this.machineManufacturers = this.data.manufacturers;
    this.machineModels = this.data.machinetypes;
    this.newForm = this.fb.group({
    name: [this.data.formdata.name, [Validators.required, Validators.minLength(3)]],
    location: [this.data.formdata.location, Validators.pattern('^.{2,}')],
    manufacturer: [this.data.formdata.manufacturer, [Validators.pattern('^.{2,}')]],
    model: [this.data.formdata.model, [Validators.pattern('^.{2,}')]],
    serialnumber: [this.data.formdata.serialnumber],
    temp_min: [this.data.formdata.temp_min],
    temp_max: [this.data.formdata.temp_max],
    hum_min: [this.data.formdata.hum_min],
    hum_max: [this.data.formdata.hum_max],
    owner: [this.data.formdata.owner],
    raspkey: [this.data.formdata.raspkey, [this.falseKeyValidator(), this.bindedValidator()]],
    config: [this.data.formdata.config],
    active: [this.data.formdata.active]
  });
}
}


@Component({
  selector: 'dialog-confirm-mach',
  templateUrl: 'dialog-confirm.html',
  styleUrls: ['./machines.component.css']
})

export class DialogConfirmMachine implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DialogConfirmMachine>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataConfirm) {}

   confirm(): void {
    this.dialogRef.close(1);
  }

  ngOnInit(): void {
    if (this.data.option === 3) {
    this.dialogRef.close(1);
    }
  }


  cancleDialog(): void {

    this.dialogRef.close(0);
  }
}

@Component({
  selector: 'dialog-restart-mach',
  templateUrl: 'dialog-restart.html',
  styleUrls: ['./machines.component.css']
})
export class DialogRestartMachine implements OnInit {

token: string;
tokenSubscribtion: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DialogRestartMachine>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataRestart,
    private api: ApiService,
    public dialog: MatDialog,
    private tokenService: TokenService) {}

  cancleDialog(): void {
    this.tokenSubscribtion.unsubscribe();
    this.dialogRef.close();
  }


  ngOnInit(): void {
    this.tokenSubscribtion = this.tokenService.currentToken.subscribe(token => this.token = token);
    let counter = 0;
    const intervall = setInterval(() => {
      this.api.getSatellite(this.data.id, this.token)
  .subscribe((res: any) => {
    if (res.ack_option) {
      this.dialogRef.close();
      this.dialog.open(DialogConfirmMachine, {
        width: '40%',
        data: {
         text: 'Neustart erfolgreich durchgeführt',
         option: 2
          },
          hasBackdrop: true,
          disableClose: true
      });
      clearInterval(intervall);
    } else if (counter > 15) {
      this.dialogRef.close();
      this.dialog.open(DialogConfirmMachine, {
        width: '40%',
        data: {
         text: 'Neustart konnte nicht durchgeführt werden',
         option: 2
          },
          hasBackdrop: true,
          disableClose: true
      });
      clearInterval(intervall);
    }
    }, (err: any) => {
      clearInterval(intervall);
    });
      counter++;
    }, 1000);

}
}

@Component({
  selector: 'dialog-init-mach',
  templateUrl: 'dialog-init.html',
  styleUrls: ['./machines.component.css']
})
export class DialogInitMachine implements OnInit {

token: string;
tokenSubscribtion: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DialogInitMachine>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataInit,
    private api: ApiService,
    public dialog: MatDialog,
    private tokenService: TokenService) {}

  cancleDialog(): void {
    this.tokenSubscribtion.unsubscribe();
    this.dialogRef.close();
  }


  ngOnInit(): void {
    this.tokenSubscribtion = this.tokenService.currentToken.subscribe(token => this.token = token);
    let counter = 0;
    const intervall = setInterval(() => {
      this.api.getSatellite(this.data.id, this.token)
  .subscribe((res: any) => {
    if (res.initack) {
      this.dialogRef.close();
      this.dialog.open(DialogConfirmMachine, {
        width: '40%',
        data: {
         text: 'Initialisierung erfolgreich angestoßen',
         option: 2
          },
          hasBackdrop: true,
          disableClose: true
      });
      clearInterval(intervall);
    } else if (counter > 15) {
      this.dialogRef.close();
      this.dialog.open(DialogConfirmMachine, {
        width: '40%',
        data: {
         text: 'Fehler beim Anstoßen der Initialisierung',
         option: 2
          },
          hasBackdrop: true,
          disableClose: true
      });
      clearInterval(intervall);
    }
    }, (err: any) => {
      clearInterval(intervall);
    });
      counter++;
    }, 1000);

}
}

@Component({
  selector: 'dialog-protocol',
  templateUrl: 'dialog-protocol.html',
  styleUrls: ['./machines.component.css']
})

export class DialogProtocoll implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('protocollTable', { read: MatSort, static: false }) protSort: MatSort;
  @ViewChild('protocollPaginator', {static: false}) protPaginator: MatPaginator;
  @ViewChild('sellTable', { read: MatSort, static: false }) sellSort: MatSort;
  @ViewChild('sellPaginator', {static: false}) sellPaginator: MatPaginator;

  displayedColumnsProt: string[] = ['time', 'type', 'device', 'message'];
  displayedColumnsSel: string[] = ['time', 'product'];
  protdataSource = new MatTableDataSource();
  selldataSource = new MatTableDataSource();
  loadingProtocoll = false;
  initialLoading = true;
  protocollIntervall: any;
  sellIntervall: any;
  allProducts: any;
  selectedMachine: Machine;
  token: string;
  tokenSubscribtion: Subscription;


  constructor(
    public dialogRef: MatDialogRef<DialogConfirmMachine>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataProtocol,
    private api: ApiService,
    private tokenService: TokenService
    ) {}

   confirm(): void {
    this.dialogRef.close(0);
  }

  ngOnDestroy(): void {
    if (this.data.option === 1) {
    clearInterval(this.protocollIntervall);
    } else {
      clearInterval(this.sellIntervall);
    }
    this.tokenSubscribtion.unsubscribe();
  }

  ngOnInit(): void {
    this.tokenSubscribtion = this.tokenService.currentToken.subscribe(token => this.token = token);
    this.selectedMachine = this.data.selectedMachine;
    if (this.data.option === 1) {
      this.getMachineStatusProtocoll();
    } else {
      this.allProducts = this.data.products;
      this.getMachineSellProtocoll();
    }
  }

  ngAfterViewInit(): void {
    if (this.data.option === 1) {
    this.protdataSource.sort = this.protSort;
    this.protdataSource.paginator = this.protPaginator;
    } else {
      this.selldataSource.sort = this.sellSort;
      this.selldataSource.paginator = this.sellPaginator;
    }
  }

  applyFilterProtocoll = (event: Event) => {
    const filterValue = (event.target as HTMLInputElement).value;
    this.protdataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterSell = (event: Event) => {
    const filterValue = (event.target as HTMLInputElement).value;
    this.selldataSource.filter = filterValue.trim().toLowerCase();
  }

  getMachineStatusProtocoll = () => {
    this.loadingProtocoll = true;
    const httpValues = {
      machineId: this.selectedMachine._id,
      timeStart: '',
      timeEnd: '',
      option: 1,
      ack: null
    };
    this.api.getMachineProtocoll(httpValues, this.token)
    .subscribe((res: any) => {
      this.loadingProtocoll = false;
      this.initialLoading = false;
      this.protdataSource.data = res;

      }, (err: any) => {
      });
    this.startStatusIntervall();
  }

  getMachineSellProtocoll = () => {
    this.loadingProtocoll = true;
    const httpValues = {
      machineId: this.selectedMachine._id,
      timeStart: '',
      timeEnd: '',
      option: 1
    };
    this.api.getMachineSells(httpValues, this.token)
    .subscribe((res: any) => {
      this.loadingProtocoll = false;
      this.initialLoading = false;
      this.selldataSource.data = res;
      }, (err: any) => {
      });
    this.startSellIntervall();
  }

  startStatusIntervall = () => {
    clearInterval(this.protocollIntervall);
    this.protocollIntervall = setInterval(() => {
      this.getMachineStatusProtocoll();
      }, 10000);
  }

  startSellIntervall = () => {
    clearInterval(this.sellIntervall);
    this.sellIntervall = setInterval(() => {
      this.getMachineSellProtocoll();
      }, 10000);
  }

  cancleDialog(): void {
    this.dialogRef.close(0);
  }

  getProductName = (id: string) => {
    if (this.allProducts.find(t => t._id === id) === undefined) {
      return 'FEHLER';
    } else {
      return this.allProducts.find(t => t._id === id).name;
    }
  }

}

@Component({
  selector: 'dialog-temp',
  templateUrl: 'dialog-temp.html',
  styleUrls: ['./machines.component.css'],
  providers:[DatePipe]
})

export class DialogTemp implements OnInit, OnDestroy {
  loadingTemps = false;
  tempsVorhanden = false;
  machineid: string;
  token: string;
  tokenSubscribtion: Subscription;
  chartsArrayTemp = [];
  chartsArrayHum = [];
  tempRefLines = [];
  humRefLines = [];
  tempMinVal = 0;
  humMinVal = 0;



  constructor(
    public dialogRef: MatDialogRef<DialogTemp>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataTemp,
    private api: ApiService,
    private tokenService: TokenService,
    public datepipe: DatePipe
    ) {}

   confirm(): void {
    this.dialogRef.close(0);
  }

  ngOnDestroy(): void {
    this.tokenSubscribtion.unsubscribe();
  }

  ngOnInit(): void {
    this.tokenSubscribtion = this.tokenService.currentToken.subscribe(token => this.token = token);
    this.machineid = this.data.machine._id;
    this.getTemperatureChart();
  }

  getTemperatureChart = () => {
    this.loadingTemps = true;
    const startdate = new Date(new Date().getTime() - (3 * 24 * 60 * 60 *1000));
    const enddate = new Date();
    if ((this.data.machine.temp_min > -40) && (this.data.machine.temp_max < 40)) {
      this.tempRefLines = [{'name': 'Obere Grenze', 'value':this.data.machine.temp_max  }, {'name': 'Untere Grenze', 'value':this.data.machine.temp_min}];
      if (this.data.machine.temp_min === 0) {
        this.tempMinVal = -2;
      } else {
        this.tempMinVal = this.data.machine.temp_min;
      }
    } else {
      this.tempRefLines = [];
      this.tempMinVal = -10;
    }
    if (this.data.machine.hum_min >= 0 && this.data.machine.hum_max <= 100) {
      this.humRefLines = [{'name': 'Obere Grenze', 'value':this.data.machine.hum_max}, {'name': 'Untere Grenze', 'value':this.data.machine.hum_min}];
      if (this.data.machine.hum_min === 0) {
        this.humMinVal = 1;
      } else {
        this.humMinVal = this.data.machine.hum_min;
      }
    } else {
      this.humRefLines = [];
      this.humMinVal = 1;
    }

    this.api.getTemps(this.machineid, startdate, enddate, this.token)
    .subscribe((res: any) => {
     // Konstruktion des Datenarrays
     this.chartsArrayTemp = [];
     this.chartsArrayHum = [];
     const tempArray = [];
     const humArray = [];
     const resLength = res.length;
     let loop = 0;
     res.forEach(element => {
       loop ++;
       const tempObjectTemp = {
        'value': element.temp,
        'name': new Date(element.time),
       };
       const tempObjectHum = {
        'value': element.hum,
        'name': new Date(element.time),
       };
       tempArray.push(tempObjectTemp);
       humArray.push(tempObjectHum);

       if (loop === resLength) {
         const tempObjectT = {
           'name': 'Temperatur',
           'series': tempArray
         };
         const tempObjectH = {
           'name': 'Luftfeuchtigkeit',
           'series': humArray
         };
         this.chartsArrayTemp.push(tempObjectT);
         this.chartsArrayHum.push(tempObjectH);
         this.loadingTemps = false;
         this.tempsVorhanden = true;
       }
     });
      }, (err: any) => {
      });
  }

  returnTime = (val) => {
    const date = new Date(val);
    return this.datepipe.transform(date, 'dd.MM.yyyy HH:mm');
  }

}
