<h2 mat-dialog-title *ngIf="data.option == 1">Neue Codetabelle</h2>
<h2 mat-dialog-title *ngIf="data.option == 2">Codetabelle bearbeiten</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="newForm" (ngSubmit)="onSubmit()">
    <table width="100%">
      <tbody>
        <tr>
          <td>
    <mat-form-field [style.width.%]="80">
      <mat-label>Name der Tabelle *</mat-label>
      <input matInput formControlName="name" placeholder="z.B. Lager Türe">
      <mat-error *ngIf="errorHandling('name', 'required') || errorHandling('name', 'minlength')">
       Mindestens 3 Zeichen erforderlich!
      </mat-error>
        </mat-form-field></td>
        </tr>


  <tr>
    <table width="100%" class="codetable">
      <thead>
<th class="codetablethleft">Code</th>
<th class="codetablethright">Bezeichnung</th>
<th class="codetablethright">Gültig von</th>
<th class="codetablethright">Gültig bis</th>
<th class="codetablethright">Aktivzeiten</th>
<th class="codetablethright">Aktiv</th>
<th class="codetablethright">Aktionen</th>
      </thead>
      <tbody formArrayName="codes" class="codeTable" *ngFor="let code of newForm.get('codes')['controls']; let i = index;">
        <tr>
          <td class="codetabletdleft">
            <span>{{newForm.controls.codes.controls[i].value.code}}#</span>
          </td>
          <td class="codetabletdright">
            <span>{{newForm.controls.codes.controls[i].value.bezeichnung}}</span>
          </td>
          <td class="codetabletdright">
            <span>{{getDateValue(newForm.controls.codes.controls[i].value.start)}}</span>
          </td>
          <td class="codetabletdright">
            <span>{{getDateValue(newForm.controls.codes.controls[i].value.end)}}</span>
          </td>
          <td class="codetabletdright">
            <span>{{getTimesString(newForm.controls.codes.controls[i].value.activetimes)}}</span>
          </td>
          <td class="codetabletdright textcenter"><mat-checkbox [checked]="newForm.controls.codes.controls[i].value.active" (change)="changeActiveStateCode($event, i)"></mat-checkbox></td>
          <td class="codetabletdright"><mat-icon class="actionEdit" (click)="accessProtocol(code)">vpn_key</mat-icon><mat-icon class="actionEdit" (click)="editCode(i)">edit</mat-icon><mat-icon class="actionDelete" (click)="deleteEntry(i)">delete</mat-icon></td>
        </tr>



</tbody>
    </table>
    <a mat-mini-fab color="primary" (click)="addCode()" class="addCodeButton"><mat-icon>plus_one</mat-icon></a>


  </tr>
  <tr class="popupbutton">
    <td><button mat-raised-button color="primary" [disabled]="!newForm.valid" type="submit" mat-dialog-close class="button">Speichern</button>
   <button mat-raised-button color="warn" mat-dialog-close (click)="cancleDialog()" class="button">Abbrechen</button></td>
</tr>
  </tbody>
  </table>
  </form>
</mat-dialog-content>

