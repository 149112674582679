import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap, } from 'rxjs/operators';
import { Machine, Client, User, Manufacturer, MachineType, MachineConfig, Satellite, Product, StatusProtocoll, RegioLock, RegioLockCode, RegioLockProtocol } from './schemas';
import { Location } from './schemas';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};
const apiUrl = 'https://connect.regio.team/api/';
//const apiUrl = 'http://localhost:27000/api/';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  // tslint:disable-next-line: typedef
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }

/* Vorlagen
  getArticles(): Observable<Article[]> {
    return this.http.get<Article[]>(apiUrl + 'article')
      .pipe(
        tap(article => console.log('fetched articles')),
        catchError(this.handleError('getArticles', []))
      );
  }

  getArticle(id: number): Observable<Article> {
    const url = `${apiUrl + 'article'}/?active=true`;
    return this.http.get<Article>(url).pipe(
      tap(_ => console.log(`fetched article id=${id}`)),
      catchError(this.handleError<Article>(`getArticle id=${id}`))
    );
  }

  addArticle(article: Article, token: any): Observable<Article> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`
      })
    };
    return this.http.post<Article>(apiUrl + 'article', article, httpOptions).pipe(
      tap((art: Article) => console.log(`added article w/ id=${art._id}`)),
      catchError(this.handleError<Article>('addArticle'))
    );
  }

  updateArticle(id: any, article: Article): Observable<any> {
    const url = `${apiUrl + 'article'}/${id}/${true}`;
    return this.http.put(url, article, httpOptions).pipe(
      tap(_ => console.log(`updated article id=${id}`)),
      catchError(this.handleError<any>('updateArticle'))
    );
  }

  deleteArticle(id: any): Observable<Article> {
    const url = `${apiUrl + 'article'}/${id}`;
    return this.http.delete<Article>(url, httpOptions).pipe(
      tap(_ => console.log(`deleted article id=${id}`)),
      catchError(this.handleError<Article>('deleteArticle'))
    );
  }*/

// Standorte
  addLocation(location: Location, token: string): Observable<Location> {
     const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
     return this.http.post<Location>(apiUrl + 'location', location, httpOptionsV).pipe(
      tap((art: Location) => console.log(`added location w/ id=${art._id}`)),
      catchError(this.handleError<Location>('addLocation'))
    );
  }

  getLocations(token: string): Observable<Location[]> {
     const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
     return this.http.get<Location[]>(apiUrl + 'location', httpOptionsV);
  }

  getLocationsForClient(id: string, token: string): Observable<Location[]> {
    const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
    return this.http.get<Location[]>(apiUrl + 'location/custloc/' + id, httpOptionsV);
  }

  getLocation(id: string, token: string): Observable<Location[]> {
     const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
     return this.http.get<Location[]>(apiUrl + 'location/' + id, httpOptionsV);
  }

  updateLocation(id: string, element: Location, token: string): Observable<Location[]> {
    const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
    return this.http.put<Location[]>(apiUrl + 'location/' + id, element, httpOptionsV);
  }

  deleteLocation(id: any, token: string): Observable<any> {
    const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
    return this.http.delete<Location[]>(apiUrl + 'location/' + id, httpOptionsV);
  }

// Automaten
addMachine(machine: Machine, token: string): Observable<Machine> {
   const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
   return this.http.post<Machine>(apiUrl + 'machine', machine, httpOptionsV).pipe(
    tap((art: Machine) => console.log(`added machine w/ id=${art._id}`)),
    catchError(this.handleError<Machine>('addMachine'))
  );
}
getMachines(token: string): Observable<Machine[]> {
   const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
   return this.http.get<Machine[]>(apiUrl + 'machine', httpOptionsV);
}

getMachine(id: string, token: string): Observable<Machine[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<Machine[]>(apiUrl + 'machine/getspec/' + id, httpOptionsV);
}

getMachinesForClient(id: string, token: string): Observable<Machine[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<Machine[]>(apiUrl + 'machine/custmac/' + id, httpOptionsV);
}

getMachinesForLocation(id: string, token: string): Observable<Machine[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<Machine[]>(apiUrl + 'location/machine/' + id, httpOptionsV);
}

updateMachine(id: string, element: Machine, token: string): Observable<MachineConfig[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.put<MachineConfig[]>(apiUrl + 'machine/' + id, element, httpOptionsV);
}

deleteMachine(id: any, token: string): Observable<any> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.delete<MachineType[]>(apiUrl + 'machine/' + id, httpOptionsV);
}

// Kunden
getClients(token: string): Observable<Client[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<Client[]>(apiUrl + 'client', httpOptionsV)
    .pipe(
      tap(client => console.log(client)),
      catchError(this.handleError('getClients', []))
    );
}
addClient(client: Client, token: string): Observable<Client> {
   const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
   return this.http.post<Client>(apiUrl + 'client', client, httpOptionsV).pipe(
    tap((art: Client) => console.log(`added client w/ id=${art._id}`)),
    catchError(this.handleError<Client>('addClient'))
  );
}
getClient(id: string, token: string): Observable<Client> {
   const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
   const url = `${apiUrl + 'client/specific'}/${id}`;
   return this.http.get<Client>(url, httpOptionsV).pipe(
    catchError(this.handleError<Client>(`getClient id=${id}`))
  );
}
getActiveClients(token: string): Observable<Client> {
   const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
   const url = `${apiUrl + 'client'}/active`;
   return this.http.get<Client>(url, httpOptionsV);
}

getSuppliers(token: string): Observable<Client[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<Client[]>(apiUrl + 'client/suppliers', httpOptionsV);
}

updateClient(id: string, element: any, token: string): Observable<Client[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.put<Client[]>(apiUrl + 'client/' + id, element, httpOptionsV);
}

// Benutzer
addUser(user: User, token: string): Observable<User> {
   const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
   return this.http.post<User>(apiUrl + 'user', user, httpOptionsV).pipe(
    tap((art: User) => console.log(`added user w/ id=${art._id}`)),
    catchError(this.handleError<User>('addUser'))
  );
}

getUser(id: string, token: string): Observable<User> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<User>(apiUrl + 'user/' + id, httpOptionsV);
}

getUserDataFromToken(token: string, userid: string): Observable<User> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<User>(apiUrl + 'user/getfromtoken/' + userid, httpOptionsV);
}

checkpw(pw: string, token: string): Observable<any> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.post<User>(apiUrl + 'user/checkpw', {pw: pw}, httpOptionsV);
}

changepw(pw: string, token: string): Observable<any> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.post<User>(apiUrl + 'user/changepw',{pw: pw}, httpOptionsV);
}

// Authentifizierung
auth(mail: string, pw: string): Observable<any> {
  return this.http.post<User>(apiUrl + 'auth/login', {username: mail, password: pw}, httpOptions).pipe();
}

validate(token: any): Observable<any> {
   const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
   return this.http.get<User>(apiUrl + 'user/validate', httpOptionsV).pipe();
}

verify(token: any): Observable<any> {
   const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
   return this.http.post<User>(apiUrl + 'user/verify', {token: token}, httpOptionsV).pipe();
}

refresh(userid: any, token: any): Observable<any> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.post<any>(apiUrl + 'auth/refresh', {user: userid, token: token}, httpOptionsV).pipe();
}

refreshLogin(token: any): Observable<any> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.post<any>(apiUrl + 'auth/refreshlogin', {token: token}, httpOptionsV).pipe();
}

logout(token: any): Observable<any> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.post<any>(apiUrl + 'auth/logout', '', httpOptionsV).pipe();
}

// Hersteller
addManufacturer(value: Manufacturer, token: string): Observable<Manufacturer> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.post<Manufacturer>(apiUrl + 'manufacturer', value, httpOptionsV);
}

getManufacturers(token: string): Observable<Manufacturer[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<Manufacturer[]>(apiUrl + 'manufacturer/getall', httpOptionsV);
}

getActiveManufacturers(token: string): Observable<Manufacturer[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<Manufacturer[]>(apiUrl + 'manufacturer/getactive', httpOptionsV);
}

updateManufacturers(id: string, element: Manufacturer, token: string): Observable<Manufacturer[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.put<Manufacturer[]>(apiUrl + 'manufacturer/' + id, element, httpOptionsV);
}

deleteManufacturer(id: any, token: string): Observable<any> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.delete<Manufacturer[]>(apiUrl + 'manufacturer/' + id, httpOptionsV);
}

// Maschinentypen
getMachineTypes(token: string): Observable<MachineType[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<MachineType[]>(apiUrl + 'machinetype/getall', httpOptionsV);
}

getMachineType(id: any, token: string): Observable<MachineType[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<MachineType[]>(apiUrl + 'machinetype/getspec/' + id, httpOptionsV);
}

getActiveMachineTypes(token: string): Observable<MachineType[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<MachineType[]>(apiUrl + 'machinetype/getactive', httpOptionsV);
}

addMachineType(value: MachineType, token: string): Observable<MachineType> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.post<MachineType>(apiUrl + 'machinetype', value, httpOptionsV);
}

updateMachineType(id: string, element: MachineType, token: string): Observable<MachineType[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.put<MachineType[]>(apiUrl + 'machinetype/' + id, element, httpOptionsV);
}

deleteMachineType(id: any, token: string): Observable<any> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.delete<MachineType[]>(apiUrl + 'machinetype/' + id, httpOptionsV);
}

// Konfigurationen
getConfigs(token: string): Observable<MachineConfig[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<MachineConfig[]>(apiUrl + 'machineconfig/getall', httpOptionsV);
}

getConfigForModel(model: string, token: string): Observable<MachineConfig[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<MachineConfig[]>(apiUrl + 'machineconfig/getformodel/' + model, httpOptionsV);
}

addMachineConfig(value: MachineConfig, token: string): Observable<MachineConfig> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.post<MachineConfig>(apiUrl + 'machineconfig', value, httpOptionsV);
}

updateMachineConfig(id: string, element: MachineConfig, token: string): Observable<MachineConfig[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.put<MachineConfig[]>(apiUrl + 'machineconfig/' + id, element, httpOptionsV);
}

deleteMachineConfig(id: any, token: string): Observable<any> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.delete<MachineType[]>(apiUrl + 'machineconfig/' + id, httpOptionsV);
}

// Satelliten
getSatellites(token: string): Observable<Satellite[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<Satellite[]>(apiUrl + 'satellite/getall', httpOptionsV);
}

getSatellitesForLocation(id: string, token: string): Observable<Satellite[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<Satellite[]>(apiUrl + 'satellite/getforloc/' + id, httpOptionsV);
}

checkRaspKey(key: string, token: string): Observable<Satellite[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<Satellite[]>(apiUrl + 'satellite/checkkey/' + key, httpOptionsV);
}

checkRaspKeyRegiolock(key: string, token: string): Observable<Satellite[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<Satellite[]>(apiUrl + 'satellite/checkkeylock/' + key, httpOptionsV);
}

getSatellite(id: string, token: string): Observable<Satellite[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<Satellite[]>(apiUrl + 'satellite/getspec/' + id, httpOptionsV);
}

addSatellite(value: Satellite, token: string): Observable<Satellite> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.post<Satellite>(apiUrl + 'satellite', value, httpOptionsV);
}

updateSatellite(id: string, element: any, token: string): Observable<Satellite[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.put<Satellite[]>(apiUrl + 'satellite/' + id, element, httpOptionsV);
}

getSatelliteWithKey(key: string, token: string): Observable<Satellite[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<Satellite[]>(apiUrl + 'satellite/getsat/' + key, httpOptionsV);
}

deleteSatellite(id: any, token: string): Observable<any> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.delete<Satellite[]>(apiUrl + 'satellite/' + id, httpOptionsV);
}

// Produkte
getProducts(id: string, token: string): Observable<Product[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<Product[]>(apiUrl + 'product/custprod/' + id, httpOptionsV);
}

getMyActiveProducts(id: string, token: string): Observable<Product[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<Product[]>(apiUrl + 'product/activeprod/' + id, httpOptionsV);
}

getProduct(id: string, token: string): Observable<Product> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<Product>(apiUrl + 'product/' + id, httpOptionsV);
}

getActiveProducts(token: string): Observable<Product[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<Product[]>(apiUrl + 'product/getactive', httpOptionsV);
}

addProduct(value: Product, token: string): Observable<Product> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.post<Product>(apiUrl + 'product', value, httpOptionsV);
}

updateProduct(id: string, element: any, token: string): Observable<Product[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.put<Product[]>(apiUrl + 'product/' + id, element, httpOptionsV);
}

deleteProduct(id: any, token: string): Observable<any> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.delete<Satellite[]>(apiUrl + 'product/' + id, httpOptionsV);
}

// Regiomat Protokolle
getMachineProtocoll(values: any, token: string): Observable<StatusProtocoll> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.post<StatusProtocoll>(apiUrl + 'regiomat/getstatus', values, httpOptionsV);
}

getMachineSells(values: any, token: string): Observable<StatusProtocoll> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.post<StatusProtocoll>(apiUrl + 'regiomat/getsells', values, httpOptionsV);
}

// Statistiken
getSellsClient(id: string, clientid: string, token: string): Observable<any> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  const values = {
    productid: id,
    clientid: clientid
  };
  return this.http.post<any>(apiUrl + 'regiomat/charts/allsellsclient/', values, httpOptionsV);
}

getSellsSupplier(id: string, supid: string, token: string): Observable<any> {
  const values = {
    productid: id,
    supid: supid
  };
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.post<any>(apiUrl + 'regiomat/charts/allsellssupplier/', values, httpOptionsV);
}

getSellsPerMachine(id: string, start: string, end: string, token: string): Observable<any> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  const values = {
    machine: id,
    startdate: start,
    enddate: end
  };
  return this.http.post<any>(apiUrl + 'regiomat/getmacsells/', values, httpOptionsV);
}

getTemps(id: string, start: Date, end: Date, token: string): Observable<any> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  const values = {
    machine: id,
    startdate: start,
    enddate: end
  };
  return this.http.post<any>(apiUrl + 'regiomat/charts/temp', values, httpOptionsV);
}

exportAllSellsClient(clientid: string, token: string): Observable<any> {
  const values = {
    clientid: clientid
  };
  return this.http.post<any>(apiUrl + 'regiomat/exportsellsclient', values, {headers: new HttpHeaders({'Authorization': `Bearer ${token}`})});
}

// Regiolock
addRegiolock(value: RegioLock, token: string): Observable<RegioLock> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.post<RegioLock>(apiUrl + 'regiolock/adddevice', value, httpOptionsV);
}

getRegiolocksForLocation(id: string, token: string): Observable<RegioLock[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<RegioLock[]>(apiUrl + 'regiolock/getforloc/' + id, httpOptionsV);
}

deleteRegiolockDevice(id: any, token: string): Observable<any> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.delete<MachineType[]>(apiUrl + 'regiolock/device/' + id, httpOptionsV);
}

updateRegiolock(id: string, value: RegioLock, token: string): Observable<RegioLockCode> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.put<RegioLockCode>(apiUrl + 'regiolock/device/' + id, value, httpOptionsV);
}

//RegioLock Code
addRegiolockCode(value: RegioLockCode, token: string): Observable<RegioLockCode> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.post<RegioLockCode>(apiUrl + 'regiolock/addcodetable', value, httpOptionsV);
}

updateCodetable(id: string, value: RegioLockCode, locationid: string, token: string): Observable<RegioLockCode> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.put<RegioLockCode>(apiUrl + 'regiolock/codetable/' + locationid + '/' + id, value, httpOptionsV);
}

getCodetablesForLocation(id: string, token: string): Observable<RegioLockCode[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<RegioLockCode[]>(apiUrl + 'regiolock/getcodetableforloc/' + id, httpOptionsV);
}

getActiveCodetablesForLocation(id: string, token: string): Observable<RegioLockCode[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<RegioLockCode[]>(apiUrl + 'regiolock/getactivecodetableforloc/' + id, httpOptionsV);
}

deleteCodetable(id: any, location: any, token: string): Observable<any> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.delete<any>(apiUrl + 'regiolock/codetable/' + location + '/' + id, httpOptionsV);
}

calculateCode(id: string, token: string): Observable<number> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<number>(apiUrl + 'regiolock/calccode/' + id, httpOptionsV);
}

getAccessProtocolForLocation(id: string, token: string): Observable<RegioLockProtocol[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<RegioLockProtocol[]>(apiUrl + 'regiolock/protocol/getforlocation/' + id, httpOptionsV);
}

getAccessProtocolForCode(location: string, tableid: string, internalid: string, token: string): Observable<RegioLockProtocol[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<RegioLockProtocol[]>(apiUrl + 'regiolock/protocol/getforcode/' + location + '/' + tableid + '/' + internalid, httpOptionsV);
}
getRegiolockProtocoll(satelliteid: string, token: string): Observable<StatusProtocoll[]> {
  const httpOptionsV = { headers: new HttpHeaders({'Authorization': `Bearer ${token}`}) };
  return this.http.get<StatusProtocoll[]>(apiUrl + 'regiolock/status/' + satelliteid, httpOptionsV);
}
}
