<h2 mat-dialog-title *ngIf="data.option == 1">Neues Gerät</h2>
<h2 mat-dialog-title *ngIf="data.option == 2">Gerät bearbeiten</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="newForm" (ngSubmit)="onSubmit()">
    <table width="100%">
      <tbody>
         <tr>
        <td colspan="4">
          <mat-form-field [style.width.%]="70" *ngIf="!loadRaspKey && !raspKeyValid">
            <mat-label>Regioconnect Key</mat-label>
            <input matInput formControlName="raspkey" (keyup)="raspKeyTouched()" (change)="raspKeyTouched()" [disabled]="raspKeyValid">
            <mat-hint align="start" *ngIf="!raspKeyChecked && !showCheckHint"><strong>Ist am Regioconnect Gerät vermerkt!</strong> </mat-hint>
            <mat-hint align="start" *ngIf="showCheckHint"><strong>Key muss noch geprüft werden!</strong> </mat-hint>
            <mat-hint align="start" class="hintSuccess" *ngIf="raspKeyValid && raspKeyChecked && !showCheckHint"><strong>Schlüssel OK</strong> </mat-hint>
            <mat-error *ngIf="errorHandling('raspkey', 'falseKey') && !raspKeyBounded">
            Falscher Schlüssel!
            </mat-error>
            <mat-error *ngIf="errorHandling('raspkey', 'binded')">
              Gerät bereits gebunden!
              </mat-error>
              <mat-error *ngIf="errorHandling('raspkey', 'ungeeignet')">
                Gerät nicht als RegioLock geeignet!
                </mat-error>
              </mat-form-field>
              <div class="spinner-wrapper" *ngIf="loadRaspKey"><mat-progress-spinner [diameter]="20" color="primary" mode="indeterminate"></mat-progress-spinner><span class="spinnertext"> Schlüssel wird geprüft...</span></div>
              <span class="checkButton"><a *ngIf="showCheckButton" mat-raised-button color="primary" (click)="checkRaspKey()">Prüfen</a></span>
              <div *ngIf="raspKeyValid || data.option === 2" class="raspkey_wrapper">
                <span class="raspkeyH">Regioconnect Key: </span>
                <span class="raspkey">{{newForm.controls.raspkey.value}}</span>
                <span *ngIf="!loadRaspKey && raspKeyValid && raspKeyChecked && data.option==1"><a class="deleteIcon" (click)="deleteRaspKey()"><mat-icon class="deleteIcon">delete_outline</mat-icon></a></span>
              </div>
        </td>
      </tr>
      <tr>
        <td colspan="4">
          <mat-form-field [style.width.%]="80">
            <mat-label>Name des Geräts *</mat-label>
            <input matInput formControlName="name" placeholder="z.B. Türe Lager">
            <mat-error *ngIf="errorHandling('name', 'required') || errorHandling('name', 'minlength')">
            Mindestens 3 Zeichen erforderlich!
            </mat-error>
              </mat-form-field></td>
              </tr>

              <tr class="table_borderbottom">
                <td><span class="relais_label">Relais 1</span></td>
                <td>
                  <mat-form-field [style.width.%]="80">
                    <mat-label>Funktion</mat-label>
                    <mat-select formControlName="conf_r1" (selectionChange)="changeFunction('to_r1','tbl_r1', $event)">
                      <mat-option *ngFor="let conf of conftypes" [value]="conf.value">
                        {{conf.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <span class="relais_label">=></span>
                  </td>
                  <td>
                  <mat-form-field [style.width.%]="80">
                    <mat-label>Tabelle</mat-label>
                    <mat-select formControlName="tbl_r1" [disabled]="getFunctionFieldValue('conf_r1')">
                      <mat-option value="0">
                        Keine
                      </mat-option>
                      <mat-option *ngFor="let table of codetables" [value]="table._id">
                        {{table.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field [style.width.%]="70">
                    <mat-label>Hysterese</mat-label>
                    <input type="number" matInput formControlName="to_r1" [readonly]="getFunctionFieldValue('conf_r1')">
                    <span matSuffix>s</span>
                  </mat-form-field>
                </td>
              </tr>

              <tr class="table_borderbottom">
                <td><span class="relais_label">Relais 2</span></td>
                <td>
                  <mat-form-field [style.width.%]="80">
                    <mat-label>Funktion</mat-label>
                    <mat-select formControlName="conf_r2" (selectionChange)="changeFunction('to_r2','tbl_r2', $event)">
                      <mat-option *ngFor="let conf of conftypes" [value]="conf.value">
                        {{conf.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <span class="relais_label">=></span>
                  </td>
                  <td>
                  <mat-form-field [style.width.%]="80">
                    <mat-label>Tabelle</mat-label>
                    <mat-select formControlName="tbl_r2" [disabled]="getFunctionFieldValue('conf_r2')">
                      <mat-option value="0">
                        Keine
                      </mat-option>
                      <mat-option *ngFor="let table of codetables" [value]="table._id">
                        {{table.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  </td>
                  <td>
                  <mat-form-field [style.width.%]="70">
                    <mat-label>Hysterese</mat-label>
                    <input type="number" matInput formControlName="to_r2" [readonly]="getFunctionFieldValue('conf_r2')">
                    <span matSuffix>s</span>
                  </mat-form-field>
                </td>
              </tr>

              <tr class="table_borderbottom">
                <td><span class="relais_label">Relais 3</span></td>
                <td>
                  <mat-form-field [style.width.%]="80">
                    <mat-label>Funktion</mat-label>
                    <mat-select formControlName="conf_r3" (selectionChange)="changeFunction('to_r3','tbl_r3', $event)">
                      <mat-option *ngFor="let conf of conftypes" [value]="conf.value">
                        {{conf.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <span class="relais_label">=></span>
                  </td>
                  <td>
                  <mat-form-field [style.width.%]="80">
                    <mat-label>Tabelle</mat-label>
                    <mat-select formControlName="tbl_r3" [disabled]="getFunctionFieldValue('conf_r3')">
                      <mat-option value="0">
                        Keine
                      </mat-option>
                      <mat-option *ngFor="let table of codetables" [value]="table._id">
                        {{table.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field [style.width.%]="70">
                    <mat-label>Hysterese</mat-label>
                    <input type="number" matInput formControlName="to_r3" [readonly]="getFunctionFieldValue('conf_r3')">
                    <span matSuffix>s</span>
                  </mat-form-field>
                </td>
              </tr>

              <tr class="table_borderbottom">
                <td><span class="relais_label">Relais 4</span></td>
                <td>
                  <mat-form-field [style.width.%]="80">
                    <mat-label>Funktion</mat-label>
                    <mat-select formControlName="conf_r4" (selectionChange)="changeFunction('to_r4','tbl_r4', $event)">
                      <mat-option *ngFor="let conf of conftypes" [value]="conf.value">
                        {{conf.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <span class="relais_label">=></span>
                  </td>
                  <td>
                  <mat-form-field [style.width.%]="80">
                    <mat-label>Tabelle</mat-label>
                    <mat-select formControlName="tbl_r4" [disabled]="getFunctionFieldValue('conf_r4')">
                      <mat-option value="0">
                        Keine
                      </mat-option>
                      <mat-option *ngFor="let table of codetables" [value]="table._id">
                        {{table.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field [style.width.%]="70">
                    <mat-label>Hysterese</mat-label>
                    <input type="number" matInput formControlName="to_r4" [readonly]="getFunctionFieldValue('conf_r4')">
                    <span matSuffix>s</span>
                  </mat-form-field>
                </td>
              </tr>
      <tr class="popupbutton">
        <td><button mat-raised-button color="primary" [disabled]="!newForm.valid || !raspKeyValid" type="submit" mat-dialog-close>Speichern</button></td>
        <td><button mat-raised-button color="warn" mat-dialog-close (click)="cancleDialog()">Abbrechen</button></td>
  </tr>

  </tbody>
  </table>
  </form>
</mat-dialog-content>

