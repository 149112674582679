<div>
  <h2 mat-dialog-title>Verlauf der letzten 3 Tage</h2>
<mat-dialog-content class="mat-typography">
  <mat-progress-bar mode="indeterminate" *ngIf="loadingTemps"></mat-progress-bar>
  <div #cont1 class="chartscontainer" *ngIf="!loadingTemps">
    <div class="chartone">
  <h2>Temperatur</h2>
 <!--Temperature Chart-->
 <ngx-charts-line-chart
 [view]="[cont1.offsetWidth, 400]"
 [yAxisLabel]="'°C'"
 [results]="chartsArrayTemp"
 [scheme]="'horizon'"
 [xAxis]="true"
 [yAxis]="true"
 [xAxisTickFormatting]="returnTime"
 [timeline]="true"
 [showXAxisLabel]="true"
 [showYAxisLabel]="true"
 [autoScale]="true"
 [referenceLines]="tempRefLines"
 [showRefLines]="true"
 [showRefLabels]="false"
 [yScaleMin]="tempMinVal"
 style="fill: white"
 *ngIf="tempsVorhanden"
 ></ngx-charts-line-chart>
</div>
<div class="charttwo" #cont2>
 <!--Humidity Chart-->
 <h2>Luftfeuchtigkeit</h2>
 <ngx-charts-line-chart
 [view]="[cont2.offsetWidth, 400]"
 [yAxisLabel]="'%'"
 [results]="chartsArrayHum"
 [scheme]="'forest'"
 [xAxisTickFormatting]="returnTime"
 [xAxis]="true"
 [yAxis]="true"
 [timeline]="true"
 [showXAxisLabel]="true"
 [showYAxisLabel]="true"
 [autoScale]="true"
 [referenceLines]="humRefLines"
 [showRefLines]="true"
 [showRefLabels]="false"
 [yScaleMin]="humMinVal"
 style="fill: white"
 *ngIf="tempsVorhanden"
 ></ngx-charts-line-chart>
</div>
</div>
</mat-dialog-content>
<mat-dialog-actions>
  <p><button mat-raised-button color="primary" mat-dialog-close class="dialogButton" (click)="confirm()">Schließen</button></p>
</mat-dialog-actions>
</div>
