import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {MatInputModule} from '@angular/material/input';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatTableModule} from '@angular/material/table';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './dashboard/dashboard.component';
import {MatCardModule} from '@angular/material/card';
import { DialogConfirmProfile, MyprofileComponent } from './myprofile/myprofile.component';
import { SalesComponent } from './sales/sales.component';
import { DialogConfirmProduct, DialogProduct, ProductsComponent } from './products/products.component';
import { StockComponent } from './stock/stock.component';
import { AgreementComponent } from './agreement/agreement.component';
import { BillingComponent } from './billing/billing.component';
import { ShipmentsComponent } from './shipments/shipments.component';
import { DialogConfirmMachine, DialogMachine, DialogProtocoll, DialogRestartMachine, MachinesComponent, DialogTemp, DialogInitMachine } from './machines/machines.component';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { LoginComponent } from './login/login.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { LocationsComponent, DialogLocation, DialogConfirmLocation, DialogRestartLocation } from './locations/locations.component';
import { AdminComponent, DialogManufacturer, DialogMachinetypes, DialogConfiguration, DialogConfirm, DialogSatellite, DialogRestart } from './admin/admin.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DialogImpressum, ImpressumComponent } from './impressum/impressum.component';
import { ClientsComponent } from './clients/clients.component';
import { DialogConfirmLock, DialogRegiolock, RegiolockComponent, DialogCodetable, DialogTimetable, DialogCodetableEdit, DialogProtocolRegiolock, DialogRestartLock } from './regiolock/regiolock.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { DatePipe } from '@angular/common'

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    MyprofileComponent,
    SalesComponent,
    ProductsComponent,
    StockComponent,
    AgreementComponent,
    BillingComponent,
    ShipmentsComponent,
    MachinesComponent,
    DialogMachine,
    LoginComponent,
    LocationsComponent,
    DialogLocation,
    DialogConfiguration,
    DialogRestart,
    DialogMachinetypes,
    DialogManufacturer,
    DialogConfirmLocation,
    DialogRestartLocation,
    DialogRestartMachine,
    DialogRestartLock,
    DialogInitMachine,
    DialogConfirmMachine,
    DialogConfirmProduct,
    DialogProtocoll,
    DialogProtocolRegiolock,
    DialogImpressum,
    DialogProduct,
    DialogSatellite,
    DialogConfirm,
    DialogConfirmProfile,
    DialogTemp,
    DialogRegiolock,
    DialogConfirmLock,
    DialogCodetable,
    DialogCodetableEdit,
    DialogTimetable,
    AdminComponent,
    ImpressumComponent,
    ClientsComponent,
    RegiolockComponent
  ],
  imports: [
    BrowserModule,
    MatDividerModule,
    FormsModule,
    MatListModule,
    ReactiveFormsModule,
    MatTableModule,
    HttpClientModule,
    MatProgressBarModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatAutocompleteModule,
    MatSortModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatListModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatSelectModule,
    FlexLayoutModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    NgxChartsModule
  ],
  providers: [
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
    MatDatepickerModule,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
