<html>
<div class="container" fxLayout="row wrap" fxLayoutGap="16px grid">
  <div fxFlex="30%" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-card class="productcard">
      <mat-card-header>
        <mat-card-title>Meine Produkte</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-progress-bar mode="indeterminate" *ngIf="loadingProducts"></mat-progress-bar>
        <div>
          <div class="filterField">
            <mat-form-field style="width: 100%">
              <mat-label>Filter</mat-label>
              <input matInput (keyup)="applyFilter($event)" #input>
            </mat-form-field>
          </div>
        <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8 machinetable" #productsTable="matSort">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header > Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
          <!-- Active Column -->
          <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Aktiv </th>
            <td mat-cell *matCellDef="let element"><mat-checkbox [checked]="element.active" (change)="changeActiveState($event, element)"></mat-checkbox></td>
          </ng-container>
          <!-- Deposit Column -->
          <ng-container matColumnDef="deposit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Pfand </th>
            <td mat-cell *matCellDef="let element"><mat-checkbox [checked]="element.deposit" (change)="changeDepositState($event, element)"></mat-checkbox></td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Aktionen </th>
        <td mat-cell *matCellDef="let element"> <!--<mat-icon class="actionDelete" (click)="deleteProduct(element)">delete</mat-icon>--> <mat-icon class="actionEdit" (click)="updateProduct(element)">edit</mat-icon><mat-icon class="actionEdit" (click)="selectProduct(element)">arrow_forward</mat-icon></td>
          </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


        </table>
        <mat-paginator #productsPaginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="newProduct()">Neues Produkt</button>
      </mat-card-actions>
    </mat-card>
  </div>
  <div fxFlex="30%" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-card class="productcard">
      <mat-card-header>
        <mat-card-title>Produktdaten</mat-card-title>
      </mat-card-header>
      <mat-card-content>

        <div *ngIf="!productSelected" class="noProductSelected">Bitte zuerst Produkt wählen (Symbol <mat-icon>arrow_forward</mat-icon>)</div>
        <div *ngIf="productSelected">
          <div>
            <table class="productsDataTable">
              <tr>
                <td class="productsDataTableth">Name:</td>
                <td class="productsDataTabletd">{{selectedProduct.name}}</td>
              </tr>
              <tr>
                <td class="productsDataTableth">Beschreibung:</td>
                <td class="productsDataTabletd">{{selectedProduct.description}}</td>
              </tr>
              <tr>
                <td class="productsDataTableth">EK:</td>
                <td class="productsDataTabletd">€ {{selectedProduct.ek.toString().replace('.', ',')}}</td>
              </tr>
              <tr>
                <td class="productsDataTableth">VK:</td>
                <td class="productsDataTabletd">€ {{selectedProduct.vk.toString().replace('.', ',')}}</td>
              </tr>
              <tr>
                <td class="productsDataTableth">Pfandprodukt:</td>
                <td class="productsDataTabletd">{{selectedProduct.deposit?"Ja":"Nein"}}</td>
              </tr>
            </table>
          </div>
          </div>

      </mat-card-content>
      <mat-card-actions>
      </mat-card-actions>
    </mat-card>
  </div>

  <div fxFlex="40%" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-card class="productcard">
      <mat-card-header>
        <mat-card-title>Verkaufsautomaten</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-progress-bar mode="indeterminate" *ngIf="loadingProductInfo"></mat-progress-bar>
        <div>
        <table mat-table matSort [dataSource]="dataSourceProd" class="mat-elevation-z8 machinetable" #prodTable="matSort">
        <!-- Standort Column -->
        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef mat-sort-header > Standort </th>
          <td mat-cell *matCellDef="let element"> {{getLocationName(element.location)}} </td>
          <td mat-footer-cell *matFooterCellDef> Gesamt </td>
        </ng-container>
      <!-- Automat Column -->
     <ng-container matColumnDef="machine">
    <th mat-header-cell *matHeaderCellDef mat-sort-header > Automat </th>
     <td mat-cell *matCellDef="let element"> {{getMachineName(element.machine)}} </td>
     <td mat-footer-cell *matFooterCellDef>  </td>
     </ng-container>
           <!-- Warenfach Column -->
           <ng-container matColumnDef="shelf">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Warenfach </th>
             <td mat-cell *matCellDef="let element"> {{element.shelf}} </td>
             <td mat-footer-cell *matFooterCellDef>  </td>
             </ng-container>
           <!-- Lagerstand Column -->
           <ng-container matColumnDef="stock">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Lagerstand </th>
             <td mat-cell *matCellDef="let element"> {{element.stock}} </td>
             <td mat-footer-cell *matFooterCellDef> {{getTotalStock()}} </td>
             </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Aktionen </th>
          <td mat-cell *matCellDef="let element"></td>
          <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedProdColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedProdColumns;"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedProdColumns"></tr>



        </table>
        <mat-paginator #prodPaginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      <div class="timeInfo" *ngIf="productSelected">Datenstand: {{dataDate | date:'HH:mm:ss'}} Uhr <span><mat-icon class="actionRefresh" (click)="refreshDataTable()">refresh</mat-icon></span></div>

      </mat-card-content>
      <mat-card-actions>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

</html>

