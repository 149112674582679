<div class="logincontent" *ngIf="loginPage">
  <mat-card class="logincard">
    <mat-card-header>
      <div mat-card-avatar class="card-header-image"></div>
      <mat-card-title>RegioAdmin</mat-card-title>
      <mat-card-subtitle>Willkommen</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content class="loginFormForm">
<div class="statusfield_grey" *ngIf="loginStatus1">Bitte einloggen</div>
<div class="statusfield_green" *ngIf="loginStatus2">Login erfolgreich. Bitte warten...</div>
<div class="statusfield_red" *ngIf="loginStatus3">Benutzerdaten falsch</div>
<div class="statusfield_grey" *ngIf="loginStatus4">Bitte neu einloggen</div>
<div class="progressbar" *ngIf="progressBar"><mat-progress-bar mode="indeterminate"></mat-progress-bar></div>
        <form [formGroup]="loginForm" (ngSubmit)="login()" *ngIf="showLoginForm">

          <mat-form-field class="loginformInput">
            <mat-label>Email</mat-label>
            <input matInput formControlName="mail">
            <mat-error *ngIf="loginForm.get('mail').hasError('required') || loginForm.get('mail').hasError('email')">
              Bitte eine gültige Mailadresse eingeben
            </mat-error>
          </mat-form-field>

          <mat-form-field class="loginformInput">
            <mat-label>Passwort</mat-label>
            <input matInput formControlName="pw" type="password">
            <mat-error *ngIf="loginForm.get('pw').hasError('required')">
              Bitte ein gültiges Passwort eingeben
            </mat-error>
          </mat-form-field>
          <button mat-raised-button [disabled]="!loginForm.valid" color="primary" class="loginbutton" type="submit">Login</button>
        </form>
    </mat-card-content>
  </mat-card>

</div>
<app-dashboard [loggedInUser]="loggedinUser" [token]="token" *ngIf="!loginPage"></app-dashboard>
<app-impressum *ngIf="impressum" (closeImpressum)="receiveImpressumClose()"></app-impressum>
<div id="footer">
  2020 - 2023 © Regio.Team GmbH | <a (click)="openImpressum()" class="impressumbutton">Impressum</a>
</div>
