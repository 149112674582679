<h2 mat-dialog-title *ngIf="data.option == 1">Neues Gerät</h2>
<h2 mat-dialog-title *ngIf="data.option == 2">Gerät bearbeiten</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="newForm" (ngSubmit)="onSubmit()">
    <table width="100%">
      <tbody>
        <tr>
          <td>
    <mat-form-field [style.width.%]="80">
      <mat-label>Name des Automaten *</mat-label>
      <input matInput formControlName="name" placeholder="z.B. Snackautomat Nr. 1">
      <mat-error *ngIf="errorHandling('name', 'required') || errorHandling('name', 'minlength')">
       Mindestens 3 Zeichen erforderlich!
      </mat-error>
        </mat-form-field></td>
        <td>
          <mat-form-field [style.width.%]="80">
            <mat-label>Standort</mat-label>
            <mat-select formControlName="location">
              <mat-option [value]=0>---</mat-option>
              <mat-option *ngFor="let location of machineLocations" [value]="location._id">
                {{location.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="errorHandling('location', 'pattern')">
              Standort muss ausgewählt werden!
             </mat-error>
          </mat-form-field>
        </td>
    </tr>
    <tr>
<td>
  <mat-form-field [style.width.%]="80" *ngIf="data.option == 1">
    <mat-label>Hersteller *</mat-label>
    <mat-select formControlName="manufacturer" (ngModelChange)="manufSelected($event)">
      <mat-option [value]=0>---</mat-option>
      <mat-option *ngFor="let manufacturer of machineManufacturers" [value]="manufacturer._id">
        {{manufacturer.name}}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="errorHandling('manufacturer', 'pattern')">
      Hersteller muss ausgewählt werden!
     </mat-error>
  </mat-form-field>
  <div *ngIf="data.option == 2" class="data_wrapper">
    <span class="dataH">Hersteller: </span>
    <span class="data">{{getManufacturerName(newForm.controls.manufacturer.value)}}</span>
  </div>
</td>
<td>
  <mat-form-field [style.width.%]="80" *ngIf="data.option == 1">
    <mat-label>Modell *</mat-label>
    <mat-select formControlName="model" [disabled]="modelDisabled">
      <mat-option [value]=0>---</mat-option>
      <mat-option *ngFor="let model of machineModels" [value]="model._id">
        {{model.name}}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="errorHandling('model', 'pattern') && !modelDisabled">
      Modell muss ausgewählt werden!
     </mat-error>
     <mat-hint align="start" *ngIf="modelDisabled"><strong>Zuerst Hersteller wählen!</strong> </mat-hint>
  </mat-form-field>
  <div *ngIf="data.option == 2" class="data_wrapper">
    <span class="dataH">Modell: </span>
    <span class="data">{{getModelName(newForm.controls.model.value)}}</span>
  </div>
</td>
</tr>
    <tr>
      <td>
        <mat-form-field [style.width.%]="80">
          <mat-label>Seriennummer</mat-label>
          <input matInput formControlName="serialnumber">
            </mat-form-field></td>
      <td>
      <!--<mat-checkbox formControlName="active" [attr.checked] = "checked ? 'checked' : null">Aktiv?</mat-checkbox> -->
      </td>
      </tr>
      <tr>
        <td>
          <mat-form-field [style.width.%]="80">
            <mat-label>Temperatur Min.</mat-label>
            <input matInput formControlName="temp_min">
            <p matSuffix>°C</p>
            <mat-error *ngIf="errorHandling('temp_min', 'pattern')">
              Wert muss zwischen -40 und +40 liegen
             </mat-error>
              </mat-form-field></td>
        <td>
          <mat-form-field [style.width.%]="80">
            <mat-label>Temperatur Max.</mat-label>
            <input matInput formControlName="temp_max">
            <p matSuffix>°C</p>
              </mat-form-field></td>
        </tr>
      <tr>
        <tr>
          <td>
            <mat-form-field [style.width.%]="80">
              <mat-label>Luftfeuchtigkeit Min.</mat-label>
              <input matInput formControlName="hum_min">
              <p matSuffix>%</p>
                </mat-form-field></td>
          <td>
            <mat-form-field [style.width.%]="80">
              <mat-label>Luftfeuchtigkeit Max.</mat-label>
              <input matInput formControlName="hum_max">
              <p matSuffix>%</p>
                </mat-form-field></td>
          </tr>
        <tr>
        <td colspan="2">
          <mat-form-field [style.width.%]="70" *ngIf="!loadRaspKey && !raspKeyValid">
            <mat-label>Regioconnect Key</mat-label>
            <input matInput formControlName="raspkey" (keyup)="raspKeyTouched()" (change)="raspKeyTouched()" [disabled]="raspKeyValid">
            <mat-hint align="start" *ngIf="!raspKeyChecked && !showCheckHint"><strong>Ist am Regioconnect Gerät vermerkt!</strong> </mat-hint>
            <mat-hint align="start" *ngIf="showCheckHint"><strong>Key muss noch geprüft werden!</strong> </mat-hint>
            <mat-hint align="start" class="hintSuccess" *ngIf="raspKeyValid && raspKeyChecked && !showCheckHint"><strong>Schlüssel OK</strong> </mat-hint>
            <mat-error *ngIf="errorHandling('raspkey', 'falseKey') && !raspKeyBounded">
            Falscher Schlüssel!
            </mat-error>
            <mat-error *ngIf="errorHandling('raspkey', 'binded')">
              Gerät bereits gebunden!
              </mat-error>
              </mat-form-field>
              <div class="spinner-wrapper" *ngIf="loadRaspKey"><mat-progress-spinner [diameter]="20" color="primary" mode="indeterminate"></mat-progress-spinner><span class="spinnertext"> Schlüssel wird geprüft...</span></div>
              <span class="checkButton"><a *ngIf="showCheckButton" mat-raised-button color="primary" (click)="checkRaspKey()">Prüfen</a></span>
              <div *ngIf="raspKeyValid" class="raspkey_wrapper">
                <span class="raspkeyH">Regioconnect Key: </span>
                <span class="raspkey">{{newForm.controls.raspkey.value}}</span>
                <span *ngIf="!loadRaspKey && raspKeyValid && raspKeyChecked && data.option==1"><a class="deleteIcon" (click)="deleteRaspKey()"><mat-icon class="deleteIcon">delete_outline</mat-icon></a></span>
              </div>

        </td>
      </tr>
      <tr>
        <td><button mat-raised-button color="primary" [disabled]="!newForm.valid" type="submit" mat-dialog-close>Speichern</button></td>
        <td><button mat-raised-button color="warn" mat-dialog-close (click)="cancleDialog()">Abbrechen</button></td>
  </tr>
  </tbody>
  </table>
  </form>
</mat-dialog-content>

