
<div class="maincontainer" [class.is-mobile]="mobileQuery.matches">
    <mat-toolbar color="primary" class="toolbar">
      <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
      <div class="app-name"></div>
    </mat-toolbar>
    <mat-sidenav-container class="sidenav-container"
                           [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
      <mat-sidenav #snav [mode]="'push'"
                   [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
        <mat-nav-list>
            <mat-list>
              <mat-list-item *ngIf="userAccessLevel == 10">
                  <mat-form-field>
                    <mat-label>Kundennummer</mat-label>
                    <mat-select (selectionChange)="changeClient($event.value)" [formControl]="selectClient">
                      <mat-option *ngFor="let client of clients" [value]="client._id">
                        {{client.clientnr}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </mat-list-item>
              <mat-divider></mat-divider>
                <div mat-subheader>Übersicht</div>
                <mat-list-item [ngClass]="{'is-active' : siteId === 10}" (click)="changeSite(10, 'Dashboard', 'dashboard')">
                  <mat-icon mat-list-icon>dashboard</mat-icon>
                  <div mat-line>Dashboard</div>
                </mat-list-item>
                <mat-list-item [ngClass]="{'is-active' : siteId === 11}" (click)="changeSite(11, 'Verkäufe', 'shopping_basket')">
                  <mat-icon mat-list-icon>shopping_basket</mat-icon>
                  <div mat-line>Verkäufe</div>
                </mat-list-item>
                <!--<mat-list-item [ngClass]="{'is-active' : siteId === 12}" (click)="changeSite(12, 'Lagerstand', 'list')">
                  <mat-icon mat-list-icon>list</mat-icon>
                  <div mat-line>Lagerstand</div>
                </mat-list-item>
                <mat-list-item [ngClass]="{'is-active' : siteId === 13}" (click)="changeSite(13, 'Lieferungen', 'local_shipping')">
                  <mat-icon mat-list-icon>local_shipping</mat-icon>
                  <div mat-line>Lieferungen</div>
                </mat-list-item>
                <mat-divider></mat-divider>
                <div mat-subheader>Abrechnung</div>
                <mat-list-item [ngClass]="{'is-active' : siteId === 20}" (click)="changeSite(20, 'Belege', 'euro_symbol')">
                  <mat-icon mat-list-icon>euro_symbol</mat-icon>
                  <div mat-line>Belege</div>
                </mat-list-item>
                <mat-list-item [ngClass]="{'is-active' : siteId === 21}" (click)="changeSite(21, 'Vertrag', 'business')">
                  <mat-icon mat-list-icon>business</mat-icon>
                  <div mat-line>Vertrag</div>
                </mat-list-item>-->
                <mat-list-item [ngClass]="{'is-active' : siteId === 31}" (click)="changeSite(31, 'Produkte', 'shopping_bag')">
                  <mat-icon mat-list-icon>shopping_bag</mat-icon>
                  <div mat-line>Produkte</div>
                </mat-list-item>
                <mat-list-item [ngClass]="{'is-active' : siteId === 33}" (click)="changeSite(33, 'Standorte', 'location_on')">
                  <mat-icon mat-list-icon>location_on</mat-icon>
                  <div mat-line>Standorte</div>
                </mat-list-item>
                <mat-list-item [ngClass]="{'is-active' : siteId === 32}" (click)="changeSite(32, 'Automaten', 'ad_units')">
                  <mat-icon mat-list-icon>ad_units</mat-icon>
                  <div mat-line>Automaten</div>
                </mat-list-item>
                <mat-divider></mat-divider>
                <div mat-subheader>Admin</div>
      <!--  <mat-list-item [ngClass]="{'is-active' : siteId === 40}" (click)="changeSite(40, 'Kunden', 'recent_actors')" *ngIf="userAccessLevel == 10">
                  <mat-icon mat-list-icon>recent_actors</mat-icon>
                  <div mat-line>Kunden</div>
                </mat-list-item>
 <mat-list-item [ngClass]="{'is-active' : siteId === 41}" (click)="changeSite(41, 'Benutzer', 'group')">
                  <mat-icon mat-list-icon>group</mat-icon>
                  <div mat-line>Benutzer</div>
                </mat-list-item>-->
                <mat-list-item [ngClass]="{'is-active' : siteId === 42}" (click)="changeSite(42, 'Systemeinstellungen', 'settings_applications')" *ngIf="userAccessLevel == 10">
                  <mat-icon mat-list-icon>settings_applications</mat-icon>
                  <div mat-line>System</div>
                </mat-list-item>
                <mat-divider></mat-divider>
                <div mat-subheader>Einstellungen</div>
                <mat-list-item [ngClass]="{'is-active' : siteId === 43}" (click)="changeSite(43, 'Mein Profil', 'account_circle')">
                  <mat-icon mat-list-icon>account_circle</mat-icon>
                  <div mat-line>Mein Profil</div>
                </mat-list-item>
                <mat-list-item (click)="logout()">
                  <mat-icon mat-list-icon>logout</mat-icon>
                  <div mat-line>Abmelden</div>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item (click)="showImpressum()">
                  <mat-icon mat-list-icon>info</mat-icon>
                  <div mat-line>Impressum</div>
                </mat-list-item>
              </mat-list>
        </mat-nav-list>
      </mat-sidenav>


      <mat-sidenav-content>
        <mat-toolbar>
         <span class="siteicon"><mat-icon mat-list-icon>{{siteIcon}}</mat-icon></span><span>{{siteTitle}}</span>
        </mat-toolbar>
        <div class="sitecontent">


      <div *ngIf="siteId === 10">
        <div class="container" fxLayout="row wrap" fxLayoutGap="16px grid">
            <div fxFlex="70%" fxFlex.xs="100%" fxFlex.sm="100%">
            Anzahl der angezeigten Tage:
           <mat-form-field class="selectorinput">
             <input matInput type="number" [(ngModel)]="daysInput" (keyup.enter)="createChartArray()">
           </mat-form-field>
           <button mat-raised-button color="primary" (click)="createChartArray()">Anzeigen</button>
         </div>
          <div fxFlex="70%" fxFlex.xs="100%" fxFlex.sm="100%">
            <mat-card class="dashboardcard umsaetze">
              <mat-card-header>
                <mat-card-title>Umsätze und Roherträge</mat-card-title>
              </mat-card-header>
              <mat-card-content #cont4>
                <mat-progress-bar mode="indeterminate" *ngIf="!showRevenueChart && !noData"></mat-progress-bar>
                <span *ngIf="noData" class="nodata">Keine Daten vorhanden</span>
                <!--Verkäufe pro Tag-->
                <ngx-charts-line-chart
                [view]="[cont4.offsetWidth, 400]"
                [xAxisLabel]="'Tag'"
                [yAxisLabel]="'€'"
                [results]="revenueArray"
                [legend]="true"
                [scheme]="'fire'"
                [xAxis]="true"
                [legendTitle]="'Art'"
                [yAxis]="true"
                [showXAxisLabel]="true"
                [animations]="true"
                [showYAxisLabel]="true"
                [autoScale]="true"
                style="fill: white"
                *ngIf="showRevenueChart && !noData"
                ></ngx-charts-line-chart>
              </mat-card-content>
            </mat-card>
          </div>

          <div fxFlex="30%" fxFlex.xs="100%" fxFlex.sm="100%">
            <mat-card class="dashboardcard umsaetze">
              <mat-card-content>
                <mat-progress-bar mode="indeterminate" *ngIf="!showRevenueChart && !noData"></mat-progress-bar>
                <!--Verkäufe pro Tag-->
              <mat-list *ngIf="showRevenueChart && !noData">
                <mat-list-item class="listheader">Gesamt der letzten {{daysInputChart}} Tage</mat-list-item>
                <mat-list-item>Umsatz: {{getUmsatzGesamt()}}</mat-list-item>
                <mat-list-item>Rohertrag: {{getGewinnGesamt()}}</mat-list-item>
                <mat-list-item>Pfandflaschen: {{getDepositCount()}} ({{getDepositValue()}})</mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item class="listheader">Durschnitt pro Tag</mat-list-item>
                <mat-list-item>Umsatz: {{getUmsatz()}}</mat-list-item>
                <mat-list-item>Rohertrag: {{getGewinn()}}</mat-list-item>
              </mat-list>

              </mat-card-content>
            </mat-card>
          </div>


          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
          <mat-card class="dashboardcard sevendays">
            <mat-card-header>
              <mat-card-title>Verkaufszeitpunkte der letzten {{daysInputChart}} Tage
               </mat-card-title>
            </mat-card-header>
            <mat-card-content #cont1>
              <mat-progress-bar mode="indeterminate" *ngIf="!showSDChart && !noData"></mat-progress-bar>
              <!-- Verkauf pro Zeitpunkt-->
              <span *ngIf="noData" class="nodata">Keine Daten vorhanden</span>
              <ngx-charts-bar-vertical-stacked
              [view]="[cont1.offsetWidth, 400]"
              [xAxisLabel]="'Zeitpunkt'"
              [yAxisLabel]="'Anzahl'"
              [results]="sellsPerTimeArray"
              [legend]="true"
              [scheme]="'cool'"
              [xAxis]="true"
              [legendTitle]="'Datum'"
              [yAxis]="true"
              [showXAxisLabel]="true"
              [showYAxisLabel]="true"
              [animations]="true"
              style="fill: white"
              *ngIf="showsellsPerTimeChart && !noData"
              ></ngx-charts-bar-vertical-stacked>
            </mat-card-content>
          </mat-card>
        </div>

        <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
          <mat-card class="dashboardcard sellsperday">
            <mat-card-header>
              <mat-card-title>Verkäufe pro Tag</mat-card-title>
            </mat-card-header>
            <mat-card-content #cont2>
              <mat-progress-bar mode="indeterminate" *ngIf="!showSPDChart && !noData"></mat-progress-bar>
              <!--Verkäufe pro Tag-->
              <span *ngIf="noData" class="nodata">Keine Daten vorhanden</span>
              <ngx-charts-line-chart
              [view]="[cont2.offsetWidth, 400]"
              [xAxisLabel]="'Tag'"
              [yAxisLabel]="'Anzahl'"
              [results]="sellsPerDayChart"
              [legend]="true"
              [scheme]="'fire'"
              [xAxis]="true"
              [legendTitle]="'Kaufart'"
              [yAxis]="true"
              [showXAxisLabel]="true"
              [animations]="true"
              [showYAxisLabel]="true"
              [autoScale]="true"
              style="fill: white"
              *ngIf="showSPDChart && !noData"
              ></ngx-charts-line-chart>
            </mat-card-content>
          </mat-card>
        </div>

        <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
          <mat-card class="dashboardcard bestproducts">
            <mat-card-header>
              <mat-card-title>Meistverkaufte Produkte der letzten {{daysInputChart}} Tage</mat-card-title>
            </mat-card-header>
            <mat-card-content #cont3>
              <mat-progress-bar mode="indeterminate" *ngIf="!showBestProductsChart && !noData"></mat-progress-bar>
              <!--Meistverkaufteste Produkte-->
              <span *ngIf="noData" class="nodata">Keine Daten vorhanden</span>
              <ngx-charts-bar-vertical-stacked
              [view]="[cont3.offsetWidth, 400]"
              [xAxisLabel]="'Produkt'"
              [yAxisLabel]="'Anzahl'"
              [results]="bestProductsArray"
              [legend]="true"
              [scheme]="'fire'"
              [xAxis]="true"
              [legendTitle]="'Datum'"
              [yAxis]="true"
              [showXAxisLabel]="true"
              [showYAxisLabel]="true"
              [animations]="true"
              style="fill: white"
              *ngIf="showBestProductsChart && !noData"
              ></ngx-charts-bar-vertical-stacked>
            </mat-card-content>
          </mat-card>
        </div>


          </div>
        </div>
        <app-sales [currentClient]="currentClient" [loggedInUser]="loggedInUser" *ngIf="siteId === 11"></app-sales>
          <!--  <app-stock *ngIf="siteId === 12"></app-stock>
          <app-shipments *ngIf="siteId === 13"></app-shipments>
          <app-billing *ngIf="siteId === 20"></app-billing>
          <app-agreement *ngIf="siteId === 21"></app-agreement>-->
          <app-myprofile [currentClient]="currentClient" [loggedInUser]="loggedInUser" *ngIf="siteId === 43"></app-myprofile>
          <app-products [currentClient]="currentClient" [loggedInUser]="loggedInUser" *ngIf="siteId === 31"></app-products>
          <app-machines *ngIf="siteId === 32" [currentClient]="currentClient" [loggedInUser]="loggedInUser"></app-machines>
          <app-locations *ngIf="siteId === 33" [currentClient]="currentClient" [loggedInUser]="loggedInUser"></app-locations>
          <app-clients *ngIf="siteId === 40" [currentClient]="currentClient" [loggedInUser]="loggedInUser"></app-clients>
          <app-admin *ngIf="siteId === 42" [currentClient]="currentClient" [loggedInUser]="loggedInUser"></app-admin>
          <app-impressum *ngIf="impressum" (closeImpressum)="hideImpressum()"></app-impressum>
       </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
