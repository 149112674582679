import { Subscription } from 'rxjs';
import { TokenService } from './../token.service';
import { Machine, Product } from './../schemas';
import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../api.service';
import { Client, User, Location, Satellite } from '../schemas';
import { MatPaginator } from '@angular/material/paginator';

export interface DialogData {
  currentClient: Client;
  option: number;
  formdata: Location;
}

export interface DialogDataConfirm {
  text: string;
  option: number;
}

export interface DialogDataRestart {
  id: string;
}

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() currentClient: Client;
  @Input() loggedInUser: User;
  @ViewChild('locationsTable', { read: MatSort, static: true }) locSort: MatSort;
  @ViewChild('machinesTable', { read: MatSort, static: true }) macSort: MatSort;
  @ViewChild('locationsPaginator', {static: true}) locPaginator: MatPaginator;
  @ViewChild('machinesPaginator', {static: true}) macPaginator: MatPaginator;

  token: string;
  tokenSubscribtion: Subscription;
  displayedColumns: string[] = ['name', 'city', 'actions'];
  displayedMachineColumns: string[] = ['name', 'status', 'actions'];
  displayedRegiolockColumns: string[] = ['name', 'status', 'actions'];
  dataSource = new MatTableDataSource();
  machineSource = new MatTableDataSource();
  regiolockSource = new MatTableDataSource();
  machineLocations = [];
  satellites = [];
  selectedLocation: any;
  locationSelected = false;
  loadingLocations = true;
  loadingMachines = true;
  satellitesIntervall: any;

  constructor(private api: ApiService, public dialog: MatDialog, private tokenService: TokenService) { }

  ngOnInit(): void {
    this.tokenSubscribtion = this.tokenService.currentToken.subscribe(token => this.token = token);
    this.getAllLocations();

  }

  ngOnDestroy(): void {
    clearInterval(this.satellitesIntervall);
    this.tokenSubscribtion.unsubscribe();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.locSort;
    this.dataSource.paginator = this.locPaginator;
    this.machineSource.sort = this.macSort;
    this.machineSource.paginator = this.macPaginator;
  }

  newLocation = () => {
    const dialogRef = this.dialog.open(DialogLocation, {
      width: '40%',
      data: {
        currentClient: this.currentClient,
        option: 1,
        formdata: {
          name: '',
          owner: this.currentClient._id,
          street: '',
          housenumber: '',
          plz: '',
          city: '',
          country: this.currentClient.country,
          locationX: '',
          locationY: '',
          accessDescription: '',
          pictures: ''
        }
      },
      hasBackdrop: true,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 0) {
      this.loadingLocations = true;
      this.api.addLocation(result, this.token)
      .subscribe((res: any) => {
        this.getAllLocations();
        }, (err: any) => {
        });
    }
    });
  }

  updateLocation = (element: Location) => {
    const dialogRef = this.dialog.open(DialogLocation, {
      width: '40%',
      data: {
        currentClient: this.currentClient,
        option: 2,
        formdata: element
      },
      hasBackdrop: true,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 0) {
        this.loadingLocations = true;
        this.api.updateLocation(element._id, result, this.token)
        .subscribe((res: any) => {
          this.getAllLocations();
          }, (err: any) => {
          });
    }
    });
  }

getAllLocations = () => {
  this.loadingLocations = true;
  this.api.getLocationsForClient(this.currentClient._id, this.token)
  .subscribe((res: any) => {
      this.dataSource.data = res;
      this.loadingLocations = false;
    }, (err: any) => {

    });
}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}

applyFilterMachines(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.machineSource.filter = filterValue.trim().toLowerCase();
}

selectLocation = (location: Location) => {
this.selectedLocation = location;
this.locationSelected = true;
this.loadingMachines = true;
this.satellitesInterval();
}

deleteLocation = (id: string) => {
  const dialogRef = this.dialog.open(DialogConfirmLocation, {
    width: '40%',
    data: {
     text: 'Soll der Standort wirklich gelöscht werden? Alle zugewiesenen Automaten werden ebenso gelöscht!',
     option: 1
      },
      hasBackdrop: true,
      disableClose: true
  });
  dialogRef.afterClosed().subscribe(result => {
  if (result === 1) {
    this.loadingLocations = true;
    // Alle an dem Standort vorhandenen Automaten holen
    this.api.getMachinesForLocation(id, this.token)
    .subscribe((resM: any) => {
      if (resM) {
        resM.forEach((mac) => {
          this.api.deleteMachine(mac._id, this.token)
          .subscribe((res: any) => {
            // Satelliten entbinden
            const machine = res.raspkey;
            if (machine.length > 0) {
            this.api.getSatelliteWithKey(machine, this.token)
            .subscribe((resS: any) => {
              this.unbindSatellite(resS[0]);
            });
          }
            // Produkte in der Produktedatenbank entbinden
            const confrows = res.confrows;
            if (confrows) {
              confrows.forEach((shelf) => {
                if (shelf.product) {
                  // Produkt holen
                  this.api.getProduct(shelf.product, this.token)
                  .subscribe((resP: any) => {
                    if (resP) {
                      const gotProduct: Product = resP;
                      const productShelfs = gotProduct.shelfs;
                      productShelfs.forEach((prod, index) => {
                        if (prod.machine === mac._id) {
                          // Eintrag aus dem Produktearray entfernen
                          gotProduct.shelfs.splice(index, 1);
                        }
                      });
                      // Geänderten Eintrag wieder in die Datenbank werfen
                      this.api.updateProduct(gotProduct._id, gotProduct, this.token)
                      .subscribe(() => {
                      });
                    }
                  });
                }
              });
            }
            }, (err: any) => {
            });
});
      }
      }, (err: any) => {
      });
    this.api.deleteLocation(id, this.token)
    .subscribe((res: any) => {
      this.getAllLocations();
      }, (err: any) => {
      });
    }
  });
}

getSatelliteStatus = (element: Machine) => {
  if (this.satellites.find(t => t.curr_machine === element._id)) {
  const satellite = this.satellites.find(t => t.curr_machine === element._id);
  if (satellite.flag_stat1 && satellite.active) {
    const currentTime = new Date().getTime();
    const lastUpdateTime = new Date(satellite.lastUpdate).getTime();
    const diff = currentTime - lastUpdateTime;
    const difference = Math.floor(diff / 1000 % 60);
    if (difference > 20) {
      return 3;
    } else {
      return 1;
    }
  } else if (!satellite.flag_stat1 && satellite.active) {
    return 2;
  }
}
}

unbindSatellite = (element: any) => {
  const dialogRef = this.dialog.open(DialogConfirmLocation, {
    width: '40%',
    data: {
     text: 'Soll das Gerät wirklich entbunden werden?',
     option: 1
      },
      hasBackdrop: true,
      disableClose: true
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result === 1) {
      const values = {
        flag_stat1: false,
        option: 0,
        ack_option: false,
        curr_location: '',
        curr_machine: '',
        available: false,
        int_ip: '',
        ext_ip: ''
      };
      if (this.satellites.find(t => t.curr_machine === element._id)) {
        const satellite = this.satellites.find(t => t.curr_machine === element._id);
        this.api.updateSatellite(satellite._id, values, this.token)
      .subscribe((res: any) => {
        // Bindung auch beim Automaten aufheben
        if (element.curr_machine) {
          this.api.getMachine(element.curr_machine, this.token)
          .subscribe((resM: any) => {
            if (resM) {
              resM.raspkey = '';
              this.api.updateMachine(resM._id, resM, this.token)
              .subscribe(() => {
              });
            }
          });
        }
        this.getMachinesAndSatellites();
        this.dialog.open(DialogConfirmLocation, {
          width: '40%',
          data: {
           text: 'Bindung aufgehoben. Das Gerät muss für eine erneute Bindung manuell neu gestartet werden (Aus- und Einstecken).',
           option: 2
            },
            hasBackdrop: true,
            disableClose: true
        });
        }, (err: any) => {
        });
      }
      }
    });
}

restartSatellite = (element: any) => {
  const dialogRef = this.dialog.open(DialogConfirmLocation, {
    width: '40%',
    data: {
     text: 'Soll das Gerät wirklich neu gestartet werden?',
     option: 1
      },
      hasBackdrop: true,
      disableClose: true
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result === 1) {
      const values = {
        option: 1,
        ack_option: false
      };
      if (this.satellites.find(t => t.curr_machine === element._id)) {
        const satellite = this.satellites.find(t => t.curr_machine === element._id);
        this.api.updateSatellite(satellite._id, values, this.token)
      .subscribe((res: any) => {
        this.dialog.open(DialogRestartLocation, {
          width: '40%',
          data: {
           id: satellite._id
            },
            hasBackdrop: true,
            disableClose: true
        });
        }, (err: any) => {
        });
      }
      }
    });
}

satellitesInterval = () => {
  clearInterval(this.satellitesIntervall);
  this.getMachinesAndSatellites();
  this.satellitesIntervall = setInterval(() => {
    this.api.getSatellitesForLocation(this.selectedLocation._id, this.token)
    .subscribe((res: any) => {
        this.satellites = res;
        this.machineSource.data = this.machineSource.data;
      }, (err: any) => {
      });
  }, 9000);
}

getMachinesAndSatellites = () => {
  this.api.getSatellitesForLocation(this.selectedLocation._id, this.token)
  .subscribe((res: any) => {
      this.satellites = res;
      this.api.getMachinesForLocation(this.selectedLocation._id, this.token)
    .subscribe((resM: any) => {
      this.machineSource.data = resM;
      this.loadingMachines = false;
      }, (err: any) => {
      });
    }, (err: any) => {
    });
}

}

@Component({
  selector: 'dialog-location',
  templateUrl: 'dialog-location.html',
})
// tslint:disable-next-line: component-class-suffix
export class DialogLocation implements OnInit {
  newForm: FormGroup;
  loading = false;
  success = false;
  currentClient: Client;

  constructor(
    public dialogRef: MatDialogRef<DialogLocation>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private fb: FormBuilder) {}

  onSubmit(): void {
    this.dialogRef.close(this.newForm.value);
  }

  cancleDialog(): void {
    this.dialogRef.close(0);
  }

  public errorHandling = (control: string, error: string) => {
    return this.newForm.controls[control].hasError(error);
  }

  ngOnInit(): void {
    this.currentClient = this.data.currentClient;
    this.newForm = this.fb.group({
    name: [this.data.formdata.name, [Validators.required, Validators.minLength(3)]],
    owner: [this.data.formdata.owner, [Validators.required]],
    street: [this.data.formdata.street, [Validators.required]],
    housenumber: [this.data.formdata.housenumber, [Validators.required]],
    plz: [this.data.formdata.plz, [Validators.required]],
    city: [this.data.formdata.city, [Validators.required]],
    country: [this.data.formdata.country, [Validators.required]],
    locationX: [this.data.formdata.locationX, [Validators.required, coordinatesValidator]],
    locationY: [this.data.formdata.locationY, [Validators.required, coordinatesValidator]],
    accessDescription: [this.data.formdata.accessDescription],
    pictures: [this.data.formdata.pictures]
  });
}
}

export function coordinatesValidator(
  control: AbstractControl
): { [key: string]: any } | null {
  const valid = /(?<=^| )\d+\.\d+(?=$| )/.test(control.value);
  return valid
    ? null
    : { invalidLocation: { valid: false, value: control.value } };
}

@Component({
  selector: 'dialog-confirm-loc',
  templateUrl: 'dialog-confirm.html',
  styleUrls: ['./locations.component.css']
})

export class DialogConfirmLocation {


  constructor(
    public dialogRef: MatDialogRef<DialogConfirmLocation>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataConfirm) {}

   confirm(): void {
    this.dialogRef.close(1);
  }

  cancleDialog(): void {
    this.dialogRef.close(0);
  }
}

@Component({
  selector: 'dialog-restart-loc',
  templateUrl: 'dialog-restart.html',
  styleUrls: ['./locations.component.css']
})
export class DialogRestartLocation implements OnInit {

token: string;
tokenSubscribtion: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DialogRestartLocation>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataRestart,
    private api: ApiService,
    public dialog: MatDialog,
    private tokenService: TokenService) {}

  cancleDialog(): void {
    this.tokenSubscribtion.unsubscribe();
    this.dialogRef.close();
  }


  ngOnInit(): void {
    this.tokenSubscribtion = this.tokenService.currentToken.subscribe(token => this.token = token);
    let counter = 0;
    const intervall = setInterval(() => {
      this.api.getSatellite(this.data.id, this.token)
  .subscribe((res: any) => {
    if (res.ack_option) {
      this.dialogRef.close();
      const dialogRefC = this.dialog.open(DialogConfirmLocation, {
        width: '40%',
        data: {
         text: 'Neustart erfolgreich durchgeführt',
         option: 2
          },
          hasBackdrop: true,
          disableClose: true
      });
      clearInterval(intervall);
    } else if (counter > 15) {
      this.dialogRef.close();
      const dialogRefC = this.dialog.open(DialogConfirmLocation, {
        width: '40%',
        data: {
         text: 'Neustart konnte nicht durchgeführt werden',
         option: 2
          },
          hasBackdrop: true,
          disableClose: true
      });
      clearInterval(intervall);
    }
    }, (err: any) => {
      clearInterval(intervall);
    });
      counter++;
    }, 1000);

}

newRegiolock = () => {

}
}
