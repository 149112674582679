<div>
<h2 *ngIf="data.action == 1" mat-dialog-title>Neues Modell</h2>
<h2 *ngIf="data.action == 2" mat-dialog-title>Modell bearbeiten</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="newForm" (ngSubmit)="onSubmit()">
    <table width="100%">
      <tbody>
        <tr>
          <td>
    <mat-form-field [style.width.%]="80">
      <mat-label>Bezeichnung des Modells *</mat-label>
      <input matInput formControlName="name" placeholder="z.B. Orchestra H84 - 2020">
      <mat-error *ngIf="errorHandling('name', 'required') || errorHandling('name', 'minlength')">
       Mindestens 3 Zeichen erforderlich!
      </mat-error>
        </mat-form-field></td>
        <td>
          <mat-form-field>
            <mat-label>Hersteller *</mat-label>
            <mat-select formControlName="manufacturer">
              <mat-option [value]=0>---</mat-option>
              <mat-option *ngFor="let manufacturer of manufacturers" [value]="manufacturer._id" [disabled]="!manufacturer.active">
                {{manufacturer.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="errorHandling('manufacturer', 'required') || errorHandling('manufacturer', 'pattern')">
              Auswahl erforderlich!
             </mat-error>
          </mat-form-field>
        </td>
         </tr>
         <tr>
  <td>
    <mat-form-field [style.width.%]="80">
      <mat-label>Konfiguration *</mat-label>
      <mat-select formControlName="configuration">
        <mat-option [value]=0>---</mat-option>
        <mat-option *ngFor="let configuration of configurations" [value]="configuration._id" [disabled]="!configuration.active">
          {{configuration.name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="errorHandling('configuration', 'required') || errorHandling('configuration', 'pattern')">
        Auswahl erforderlich!
       </mat-error>
    </mat-form-field>
  </td>
    <td>
    <!-- Aktiv? -->
<mat-checkbox formControlName="active">Aktiv?</mat-checkbox>
</td>
</tr>
<tr>
  <td>
    <mat-form-field [style.width.%]="80">
      <mat-label>Statistik *</mat-label>
      <mat-select formControlName="evatype">
        <mat-option [value]=0>MDB</mat-option>
        <mat-option [value]=1>EVA-DTS DDCMP</mat-option>
        <mat-option [value]=2>EVA-DTS DEX/UCS</mat-option>
      </mat-select>
      <mat-error *ngIf="errorHandling('evatype', 'required')">
        Auswahl erforderlich!
       </mat-error>
    </mat-form-field>
  </td>
</tr>
      <tr>
    <td><button mat-raised-button color="primary" [disabled]="!newForm.valid" type="submit" mat-dialog-close>Speichern</button></td>
    <td><button mat-raised-button color="warn" mat-dialog-close (click)="cancleDialog()">Abbrechen</button></td>
  </tr>
  </tbody>
  </table>
  </form>
</mat-dialog-content>
</div>
