<h2 mat-dialog-title *ngIf="data.option == 1">Neuer Standort</h2>
<h2 mat-dialog-title *ngIf="data.option == 2">Standort bearbeiten</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="newForm" (ngSubmit)="onSubmit()">
    <table width="100%">
      <tbody>

        <!-- Standortname -->
        <tr>
          <td colspan="2">
    <mat-form-field [style.width.%]="100">
      <mat-label>Name des Standortes *</mat-label>
      <input matInput formControlName="name" placeholder="z.B. Gewerbepark">
      <mat-error *ngIf="errorHandling('name', 'required') || errorHandling('name', 'minlength')">
       Mindestens 3 Zeichen erforderlich!
      </mat-error>
        </mat-form-field></td>
  </tr>
   <!-- Straße -->
        <tr>
          <td>
    <mat-form-field [style.width.%]="100">
      <mat-label>Straße *</mat-label>
      <input matInput formControlName="street">
      <mat-error *ngIf="errorHandling('street', 'required')">
       Erforderlich
      </mat-error>
        </mat-form-field></td>
           <!-- Hausnummer -->
        <td>
          <mat-form-field [style.width.%]="100">
          <mat-label>Nr. *</mat-label>
          <input matInput formControlName="housenumber">
          <mat-error *ngIf="errorHandling('housenumber', 'required')">
            Erforderlich
          </mat-error>
            </mat-form-field></td>
  </tr>

   <!-- PLZ -->
   <tr>
    <td>
<mat-form-field [style.width.%]="100">
<mat-label>PLZ *</mat-label>
<input matInput formControlName="plz">
<mat-error *ngIf="errorHandling('plz', 'required')">
 Erforderlich
</mat-error>
  </mat-form-field></td>
     <!-- Ort -->
  <td>
    <mat-form-field [style.width.%]="100">
    <mat-label>Ort *</mat-label>
    <input matInput formControlName="city">
    <mat-error *ngIf="errorHandling('city', 'required')">
      Erforderlich
    </mat-error>
      </mat-form-field>
    </td>
</tr>
 <!-- Land -->
<tr>
  <td colspan="2">
<mat-form-field [style.width.%]="100">
  <mat-label>Land *</mat-label>
  <mat-select formControlName="country">
    <mat-option value="AT">Österreich</mat-option>
    <mat-option value="DE">Deutschland</mat-option>
    <mat-option value="CH">Schweiz</mat-option>
  </mat-select>
</mat-form-field>
</td>
</tr>
 <!-- Koordinaten -->
<tr>
  <td>
    <mat-form-field [style.width.%]="100">
      <mat-label>Koordinate (N) *</mat-label>
      <input matInput formControlName="locationY" placeholder="z.B.: 47.323000">
      <mat-error *ngIf="errorHandling('locationY', 'invalidLocation')">
        Falsches Format
      </mat-error>
        </mat-form-field>
</td>
<td>
  <mat-form-field [style.width.%]="100">
    <mat-label>Koordinate (E) *</mat-label>
    <input matInput formControlName="locationX" placeholder="z.B.: 12.797012">
    <mat-error *ngIf="errorHandling('locationX', 'invalidLocation')">
      Falsches Format
    </mat-error>
      </mat-form-field>
</td>
</tr>
<!-- Zufahrtsbeschreibung -->
<tr>
  <td colspan="2">
    <mat-form-field [style.width.%]="100">
      <mat-label>Zufahrtsbeschreibung</mat-label>
      <textarea matInput formControlName="accessDescription" rows="5"></textarea>
        </mat-form-field>
</td>
</tr>
<!-- Bilder -->
<tr>
  <td colspan="2">
    <mat-form-field [style.width.%]="100">
      <mat-label>Bilder (In Kürze verfügbar!)</mat-label>
      <input matInput formControlName="pictures" readonly>
        </mat-form-field>
</td>
</tr>
      <tr>
    <td><button mat-raised-button color="primary" [disabled]="!newForm.valid" type="submit" mat-dialog-close>Speichern</button></td>
    <td><button mat-raised-button color="warn" mat-dialog-close (click)="cancleDialog()">Abbrechen</button></td>
  </tr>
  </tbody>
  </table>
  </form>
</mat-dialog-content>

