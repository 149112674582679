import { PdfService } from './../pdf.service';
import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Client, User, Location } from '../schemas';
import { TokenService } from '../token.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as fs from 'file-saver';
import * as moment from 'moment';
import { Workbook } from 'exceljs';

export const CUSTOM_DATEFORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

interface Month {
  value: number;
  viewValue: string;
}


@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class SalesComponent implements OnInit {
  @Input() currentClient: Client;
  @Input() loggedInUser: User;
  token: string;
  range: FormGroup;
  losungsForm: FormGroup;
  loadingSells = false;
  pdfMode = 1;
  sellsAvailable = false;
  sellsEmpty = true;
  loadingSellExcel = false;
  createSellExcel = false;
  selectedMonth = '';
  selectedYear = '';
  currentSells = [];
  months: Month[] = [
    {value: 0, viewValue: 'Jänner'},
    {value: 1, viewValue: 'Februar'},
    {value: 2, viewValue: 'März'},
    {value: 3, viewValue: 'April'},
    {value: 4, viewValue: 'Mai'},
    {value: 5, viewValue: 'Juni'},
    {value: 6, viewValue: 'Juli'},
    {value: 7, viewValue: 'August'},
    {value: 8, viewValue: 'September'},
    {value: 9, viewValue: 'Oktober'},
    {value: 10, viewValue: 'November'},
    {value: 11, viewValue: 'Dezember'}
  ];
  formatCurrency =  new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });


  constructor(private api: ApiService, private tokenService: TokenService, private pdfService: PdfService) { }

  ngOnInit(): void {
    this.tokenService.currentToken.subscribe(token => this.token = token);
    const currentDate = new Date();
    this.range = new FormGroup({
      start: new FormControl('', Validators.required),
      end: new FormControl('', Validators.required)
    });
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth() - 1;
    if (currentDate.getMonth() === 0) {
      currentMonth = 11;
    }
    if (currentMonth === 11) {
      currentYear = currentYear - 1;
    }
    this.losungsForm = new FormGroup({
      month: new FormControl(currentMonth, Validators.required),
      year: new FormControl(currentYear, [Validators.required, Validators.pattern('^[0-9]{4}$')]),
    });
  }

  public errorHandling = (control: string, error: string) => {
    return this.losungsForm.controls[control].hasError(error);
  }

  exportAllSellsClient = () => {
    this.loadingSellExcel = true;
    this.createSellExcel = true;
    this.api.exportAllSellsClient(this.currentClient._id, this.token)
    .subscribe((res: any) => {
      const currenttime = new Date();
      const dateForTitle = moment(currenttime).format('DDMMYYYY');
      const timeForTitle = moment(currenttime).format('HHmmss');
      const title = 'verkaufsdaten_' + dateForTitle + '_' + timeForTitle + '.xlsx';
      // Daten kreieren

      const createResArray = new Promise ((resolve) => {
        const data = [];
        const resLength = res.length;
        let resLoop = 0;
        let vkNetto = 0;
        res.sort((a, b) => (a.time > b.time) ? 1 : -1)
        res.forEach((value) => {
          let deposit = "Nein";
          let depositValue = 0;
          if (value.depositValue) {
            depositValue = +value.depositValue;
          }
          if (value.deposit === true) {
            deposit = "Ja";
            vkNetto = ((value.vk - depositValue) / (value.taxrate + 100)) * 100;
          } else {
            vkNetto = ((value.vk) / (value.taxrate + 100)) * 100;
          }

          resLoop++;
          const tempData = [];
          const date = moment(value.time).format('DD.MM.YYYY');
          const time = moment(value.time).format('HH:mm:ss');
          tempData.push(date);
          tempData.push(time);
          tempData.push(value.name);
          tempData.push(value.ek);
          tempData.push(value.vk);
          tempData.push(vkNetto);
          tempData.push(value.taxrate/100);
          tempData.push(deposit);
          tempData.push(depositValue);
          tempData.push(value.machinename);
          data.push(tempData);
          if (resLoop === resLength) {
            resolve(data);
          }
        });
      });

      createResArray.then((data:any) => {

        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Verkaufsdaten');

        worksheet.columns = [
          { header: 'Datum', key: 'date', width: 20 },
          { header: 'Uhrzeit', key: 'time', width: 14 },
          { header: 'Produkt', key: 'product', width: 44 },
          { header: 'EK', key: 'ek', width: 10 },
          { header: 'VK (inkl. Pfand)', key: 'vk', width: 10 },
          { header: 'Netto (ohne Pfand)', key: 'vknetto', width: 10 },
          { header: 'Steuer', key: 'tax', width: 10 },
          { header: 'Pfandpflicht', key: 'deposit', width: 10 },
          { header: 'Pfand', key: 'depositValue', width: 20 },
          { header: 'Automat', key: 'machine', width: 20 }
        ];
        worksheet.autoFilter = 'A1:I1';

          // Add Data and Conditional Formatting
          data.forEach(d => {
            worksheet.addRow(d);
          });
          worksheet.getColumn(4).numFmt = '€ 0.00';
          worksheet.getColumn(5).numFmt = '€ 0.00';
          worksheet.getColumn(6).numFmt = '€ 0.00';
          worksheet.getColumn(7).numFmt = '0 %';
          worksheet.getColumn(9).numFmt = '€ 0.00';
          this.loadingSellExcel = false;
          this.createSellExcel = false;
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, title);
        })
      });

      }, (err: any) => {
      });
  }

  showSells = () => {
  return new Promise ((resolve) => {
  this.loadingSells = true;
  const selectedMonth = this.losungsForm.value.month;
  const selectedYear = this.losungsForm.value.year;
  this.selectedYear = selectedYear;
  this.selectedMonth = this.months.find(element => element.value === selectedMonth).viewValue;
  const startdate = new Date(selectedYear, selectedMonth);
  const enddate = new Date(selectedYear, selectedMonth + 1);
  const returnArray = [];
  let locationLength = 0;
  let locationLoop = 0;

  // Daten aus der Datenbank holen
  // Alle Standorte holen
  this.getAllLocations().then((locations: any) => {
    if (locations) {
    locationLength = locations.length;
    if (locationLength > 0) {

    locations.forEach((location: Location) => {
      let locationValue = false;
      const tempArraySellsFinish = [];
      const locationid = location._id;
      const getSellsArray = new Promise ((resolveS) => {
      // Alle Automaten an dem Standort holen
      this.getMachinesOnLocation(locationid).then((machines: any) => {
        const machinesLength = machines.length;
        let machinesLoop = 0;
        // Verkäufe pro Maschine holen
        machines.forEach((machine) => {
          this.getSellsPerMachine(machine._id, startdate, enddate).then((sells: any) => {
            // Umsatz exkl. Pfand
            let value10 = 0;
            let value20 = 0;
            let worth20 = 0;
            let worth10 = 0;
            let value10Dep = 0;
            let value20Dep = 0;
            // Umsatz inkl. Pfand
            let worth20WDep = 0;
            let worth10WDep = 0;
            // Pfand
            let worth20Dep = 0;
            let worth10Dep = 0;
            const tempArraySells = [];

            if (sells) {
              sells.forEach((prod, index) => {
                let deposit = "Nein";
                let depositValue = 0;
                if (prod.deposit === true) {
                  deposit = "Ja";
                }
                if (prod.depositValue) {
                  depositValue = +prod.depositValue;
                } else {
                  prod.depositValue = depositValue;
                }
                if (tempArraySells.length >= 1) {
                  const indexSellinArray = tempArraySells.findIndex(element => element.name === prod.name && element.vk === prod.vk && +element.depositValue === +prod.depositValue);

                  if (indexSellinArray !== -1) {
                    tempArraySells[indexSellinArray].anzahl = tempArraySells[indexSellinArray].anzahl + 1
                } else {
                  const tempObject = {
                    name: prod.name,
                    anzahl: 1,
                    vk: prod.vk,
                    taxrate: prod.taxrate,
                    depositValue: depositValue,
                    deposit: deposit
                  };
                  tempArraySells.push(tempObject);
                }
              } else {
                const tempObject = {
                  name: prod.name,
                  anzahl: 1,
                  vk: prod.vk,
                  taxrate: prod.taxrate,
                  depositValue: depositValue,
                  deposit: deposit
                };
                tempArraySells.push(tempObject);
              }
              });
            }

            if (tempArraySells) {
              tempArraySells.forEach((sell) => {
                if (sell.taxrate === 10) {
                  let addworth10 = 0;
                  if (sell.deposit === "Ja") {
                    addworth10 = (sell.anzahl * (sell.vk - sell.depositValue));
                    value10Dep = value10Dep + sell.anzahl;
                    worth10Dep = worth10Dep + sell.depositValue;
                  } else {
                    addworth10 = (sell.anzahl * sell.vk);
                  }
                  worth10WDep = worth10WDep + (sell.anzahl * sell.vk);
                  worth10 = worth10 + addworth10;
                  value10 = value10 + sell.anzahl;
                } else if (sell.taxrate === 20) {
                  let addworth20 = 0;
                  if (sell.deposit === "Ja") {
                    addworth20 = (sell.anzahl * (sell.vk - sell.depositValue));
                    value20Dep = value20Dep + sell.anzahl;
                    worth20Dep = worth20Dep + sell.depositValue;
                  } else {
                    addworth20 = (sell.anzahl * sell.vk);
                  }
                  worth20WDep = worth20WDep + (sell.anzahl * sell.vk);
                  worth20 = worth20 + addworth20;
                  value20 = value20 + sell.anzahl;
                }
              });
            }

            const tempObjectSells = {
            machine: machine.name,
             w10: value10,
             w20: value20,
             dep10: value10Dep,
             dep20: value20Dep,
             value10: worth10,
             value20: worth20,
             worth10Dep: worth10Dep,
             worth20Dep: worth20Dep,
             value10inkDep: worth10WDep,
             value20inkDep: worth20WDep,
             sells: tempArraySells
           };

           if (value10 > 0 || value20 > 0) {
            locationValue = true;
            tempArraySellsFinish.push(tempObjectSells);
           }
           machinesLoop ++;
           if (machinesLength === machinesLoop) {
            resolveS(tempArraySellsFinish);
          }
          });
        });
      });
      });
      getSellsArray.then((tempArraySells: any) => {
      const tempObjectLocation = {
        location: location.name,
        sells: tempArraySells
      };
      if (tempArraySells.length > 0) {
        returnArray.push(tempObjectLocation);
      }
      locationLoop ++;
      if (locationLoop === locationLength) {
        if (returnArray.length > 0) {
          this.sellsEmpty = false;
        } else {
          this.sellsEmpty = true;
        }
        this.currentSells = returnArray;
        this.sellsAvailable = true;
        this.loadingSells = false;
        resolve(returnArray);
      }
    });
    });
  } else {
    this.sellsAvailable = false;
    this.loadingSells = false;
  }
  }
  });
});
}

getAllLocations = () => {
  return new Promise ((resolve) => {
    this.api.getLocationsForClient(this.currentClient._id, this.token)
    .subscribe((res: any) => {
      resolve(res);
      }, (err: any) => {
      });
  });
}


getMachinesOnLocation = (location: string) => {
  return new Promise ((resolve) => {
    this.api.getMachinesForLocation(location, this.token)
    .subscribe((res: any) => {
      resolve(res);
      }, (err: any) => {
      });
  });
}

getSellsPerMachine = (machineid: string, startdate: any, enddate: any) => {
  return new Promise ((resolve) => {
    this.api.getSellsPerMachine(machineid, startdate, enddate, this.token)
    .subscribe((res: any) => {
      resolve(res);
      }, (err: any) => {
      });
  });

}

calcTax = (taxrate: number, value: number) => {
  const divider = 100 + taxrate;
  return value - ((value / divider) * 100);
}

calcSum = (taxrate: number) => {
  let returnValue = 0;
  this.currentSells.forEach((location) => {
    location.sells.forEach((sell) => {
      if (taxrate === 10) {
        returnValue = returnValue + sell.value10;
      } else if (taxrate === 20) {
        returnValue = returnValue + sell.value20;
      }
    });
  });
  return returnValue;
}

calcSumInkDep = (taxrate: number) => {
  let returnValue = 0;
  this.currentSells.forEach((location) => {
    location.sells.forEach((sell) => {
      if (taxrate === 10) {
        returnValue = returnValue + sell.value10inkDep;
      } else if (taxrate === 20) {
        returnValue = returnValue + sell.value20inkDep;
      }
    });
  });
  return returnValue;
}

calcLocationSum = (gotlocation: string) => {
  let returnValue = 0;
  this.currentSells.forEach((location) => {
    location.sells.forEach((sell) => {
      if (location === gotlocation) {
        returnValue = returnValue + sell.value10 + sell.value20;
      }
    });
  });
  return returnValue;
}

calcLocationSumInkDep = (gotlocation: string) => {
  let returnValue = 0;
  this.currentSells.forEach((location) => {
    location.sells.forEach((sell) => {
      if (location === gotlocation) {
        returnValue = returnValue + sell.value10inkDep + sell.value20inkDep;
      }
    });
  });
  return returnValue;
}

calcDep = () => {
  let returnValue = 0;
  this.currentSells.forEach((location) => {
    location.sells.forEach((sell) => {
        returnValue = returnValue + sell.dep10 + sell.dep20;
    });
  });
  return returnValue;
}

calcLocationDep = (gotlocation: string) => {
  let returnValue = 0;
  this.currentSells.forEach((location) => {
    location.sells.forEach((sell) => {
      if (location === gotlocation) {
        returnValue = returnValue + sell.dep10 + sell.dep20;
      }
    });
  });
  return returnValue;
}

calcDepWorth = () => {
  let returnValue = 0;
  this.currentSells.forEach((location) => {
    location.sells.forEach((sell) => {
        returnValue = returnValue + sell.worth10Dep + sell.worth20Dep;
    });
  });
  return returnValue;
}

calcLocationDepWorth = (gotlocation: string) => {
  let returnValue = 0;
  this.currentSells.forEach((location) => {
    location.sells.forEach((sell) => {
      if (location === gotlocation) {
        returnValue = returnValue + sell.worth10Dep + sell.worth20Dep;
      }
    });
  });
  return returnValue;
}


// Base64 converter
getBase64 = (fileB) => {
  const base64image = new Promise ((resolve) => {
    const xhr = new XMLHttpRequest();
    let res: any;
    xhr.open("GET", fileB, true);
    xhr.responseType = "blob";
    xhr.onload = function (e) {
            const reader = new FileReader();
            reader.onload = function(event) {
            res = event.target.result;
            resolve(res);
            }
            const file = this.response;
            reader.readAsDataURL(file)
    };
    xhr.send();
  });
  return base64image;
}

createPDFBeleg = () => {
 this.getBase64('/assets/logos/logo_light.png').then((blob) => {
  const currentDate = new Date();
  const printDate = currentDate.getDate() + '.' + (currentDate.getMonth() + 1) + '.' + currentDate.getFullYear();
  // Tabelle kreieren
  const bodyTableArray = [];
  let umsatz10 = 0;
  let umsatz20 = 0;
  let steuer10 = 0;
  let steuer20 = 0;
  let netto10 = 0;
  let netto20 = 0;
  let dep = 0;
  let depValue = 0;
  const emptyArray = [];
  const leerZeile = {
    text: '',
    colSpan: 7,
    border: [false, false, false, false]
  }
  emptyArray.push(leerZeile);
  for (let i = 0; i < 6; i++) {
    emptyArray.push({});
  }




  this.currentSells.forEach((sell) => {
    let locUmsatz10 = 0;
    let locUmsatz20 = 0;
    let locSteuer10 = 0;
    let locSteuer20 = 0;
    let locSumme10 = 0;
    let locSumme20 = 0;
    let locDep = 0;
    let locDepValue = 0;
  // Verkäufe nach Produktnamen sortieren


    // Zeile pro Automat erzeugen
    sell.sells.forEach((machinesell) => {
      const locationArray = [];
      let autUmsatz10 = 0;
      let autUmsatz20 = 0;
      let autSteuer10 = 0;
      let autSteuer20 = 0;
      let autSumme10 = 0;
      let autSumme20 = 0;
      let autDep = 0;
      let autDepValue = 0;

      // Header pro Standort erzeugen
      const locationTempObject = {
        text: sell.location + ' - Automat: ' + machinesell.machine,
        alignment: 'left',
        colSpan: 7,
        fontSize: 16,
        margin: [0, 0, 10, 0],
        border: [false, true, false, false],
        borderColor: ['', '#808080', '', '']
      };
      // Colspan eintragen
      locationArray.push(locationTempObject);
      for (let i = 0; i < 6; i++) {
        locationArray.push({});
      }
      const headerArray = [];
      const headerTempObject2 = {
        text: 'Produkt',
        alignment: 'left',
        bold: true,
        border: [false,false,false,false],
        unbreakable: true
      };
      const headerTempObject3 = {
        text: 'Stk',
        alignment: 'center',
        bold: true,
        border: [false,false,false,false],
        unbreakable: true
      };
      const headerTempObject4 = {
        text: 'Pfand',
        alignment: 'center',
        bold: true,
        border: [false,false,false,false],
        unbreakable: true
      };
      const headerTempObject5 = {
        text: 'Umsatz',
        alignment: 'center',
        bold: true,
        border: [false,false,false,false],
        unbreakable: true
      };

      const headerTempObject6 = {
        text: 'Steuer',
        alignment: 'center',
        bold: true,
        colSpan: 2,
        border: [false,false,false,false],
        unbreakable: true
      };

      const headerTempObject7 = {
        text: ''
      };

      const headerTempObject8 = {
        text: 'Netto',
        alignment: 'left',
        bold: true,
        border: [false,false,false,false],
        unbreakable: true
      };
      headerArray.push(headerTempObject2);
      headerArray.push(headerTempObject3);
      headerArray.push(headerTempObject4);
      headerArray.push(headerTempObject5);
      headerArray.push(headerTempObject6);
      headerArray.push(headerTempObject7);
      headerArray.push(headerTempObject8);

      bodyTableArray.push(locationArray);
      bodyTableArray.push(headerArray);
      let allSells = null;

      if (this.pdfMode === 1) {
      // Für jedes Produkt eine Zeile anlegen und absteigend nach VK sortieren
      const allSells1 = machinesell.sells.sort((a, b) => (a.vk > b.vk ? -1 : 1));
      // Danach aufsteigend nach Namen sortieren
      allSells = allSells1.sort((a, b) => (a.name < b.name ? -1 : 1));
      } else if (this.pdfMode === 2) {
      // Für jedes Produkt eine Zeile anlegen und absteigend nach Anzahl sortieren
      const allSells1 = machinesell.sells.sort((a, b) => (a.anzahl > b.anzahl ? -1 : 1));
      allSells = allSells1;
      }

      // Alle Produkte
      allSells.forEach((sell) => {
        const machineArray = [];

        // Produkt
        const machineTempObject1 = {
          text: sell.name,
          alignment: 'left',
          borderColor: ['', '#d4d4d4', '', ''],
          border: [false,true,false,false],
          unbreakable: true
        }

        // Menge
        const machineTempObject2 = {
          text: sell.anzahl,
          alignment: 'center',
          borderColor: ['', '#d4d4d4', '', ''],
          border: [false,true,false,false],
          unbreakable: true
        }

          // Pfand
        const machineTempObject3 = {
          text: this.formatCurrency.format(sell.depositValue * sell.anzahl.toFixed(2) ),
          alignment: 'center',
          borderColor: ['', '#d4d4d4', '', ''],
          border: [false,true,false,false],
          unbreakable: true
        }

      // Umsatz
      const umsatz = (sell.vk - sell.depositValue) * sell.anzahl;
      const machineTempObject4 = {
        text: this.formatCurrency.format(+umsatz.toFixed(2)),
        alignment: 'center',
        borderColor: ['', '#d4d4d4', '', ''],
        border: [false,true,false,false],
        unbreakable: true
      }

      // Steuersatz
      const machineTempObject5 = {
        text: sell.taxrate + '%',
        alignment: 'center',
        borderColor: ['', '#d4d4d4', '', ''],
        border: [true,true,false,false],
        vLineWidth: 1,
        unbreakable: true
      }

    // Steuerbetrag
    const divider = 100 + sell.taxrate;
    const nettosumme = umsatz / divider * 100;
    const steuervalue = umsatz - nettosumme;
    const machineTempObject6 = {
      text: this.formatCurrency.format(+steuervalue.toFixed(2)),
      alignment: 'center',
      borderColor: ['', '#d4d4d4', '', ''],
      border: [false,true,true,false],
      unbreakable: true
    }
    // Nettosumme
    const machineTempObject7 = {
      text: this.formatCurrency.format(+nettosumme.toFixed(2)),
      alignment: 'left',
      borderColor: ['', '#d4d4d4', '', ''],
      border: [false,true,false,false],
      unbreakable: true
    }

    // Summieren
    if (sell.taxrate === 10) {
      autUmsatz10 = autUmsatz10 + umsatz;
      autSteuer10 = autSteuer10 + steuervalue;
      autSumme10 = autSumme10 + nettosumme;
      locUmsatz10 = locUmsatz10 + umsatz;
      locSteuer10 = locSteuer10 + steuervalue;
      locSumme10 = locSumme10 + nettosumme;
      umsatz10 = umsatz10 + umsatz;
      steuer10 = steuer10 + steuervalue;
      netto10 = netto10 + nettosumme;
    } else if (sell.taxrate === 20) {
      autUmsatz20 = autUmsatz20 + umsatz;
      autSteuer20 = autSteuer20 + steuervalue;
      autSumme20 = autSumme20 + nettosumme;
      locUmsatz20 = locUmsatz20 + umsatz;
      locSteuer20 = locSteuer20 + steuervalue;
      locSumme20 = locSumme20 + nettosumme;
      umsatz20 = umsatz20 + umsatz;
      steuer20 = steuer20 + steuervalue;
      netto20 = netto20 + nettosumme;
    }

    // Pfand berechnen
    if (sell.deposit === "Ja") {
      autDep = autDep + sell.anzahl;
      locDep = locDep + sell.anzahl;
      dep = dep + sell.anzahl;
      autDepValue = autDepValue + (sell.depositValue * sell.anzahl);
      locDepValue = locDepValue + (sell.depositValue + sell.anzahl);
      depValue = depValue + (sell.depositValue * sell.anzahl);
    }

    machineArray.push(machineTempObject1);
    machineArray.push(machineTempObject2);
    machineArray.push(machineTempObject3);
    machineArray.push(machineTempObject4);
    machineArray.push(machineTempObject5);
    machineArray.push(machineTempObject6);
    machineArray.push(machineTempObject7);
    bodyTableArray.push(machineArray);
});

// Summe pro Automat
      const sumHeaderArray = [];
      const sumArray = [];
      const sumArray2 = [];
      const sumArray3 = [];
      const sumArray4 = [];
      const fillColor = '#ECECEC';
      const borderColorSum = '#a3a3a3';

      const headerTempObject30 = {
        text: 'Zusammenfassung ' + machinesell.machine,
        alignment: 'left',
        colSpan: 3,
        bold: true,
        margin: [0, 0, 10, 0],
        border: [false, true, false, true],
        borderColor: ['', '', '', borderColorSum],
        fillColor: fillColor
      };
      sumHeaderArray.push(headerTempObject30);
      for (let i = 0; i < 2; i++) {
        sumHeaderArray.push({});
      }

      const headerTempObject31 = {
        text: 'Umsatz',
        fillColor: fillColor,
        bold: true,
        unbreakable: true,
        border: [false, true, false, true],
        borderColor: ['', '', '', borderColorSum],
        alignment: 'center',
      };
      const headerTempObject32 = {
        text: 'Steuer',
        alignment: 'center',
        bold: true,
        colSpan: 2,
        border: [false, true, false, true],
        borderColor: ['', '', '', borderColorSum],
        fillColor: fillColor,
        unbreakable: true
      };
      const headerTempObject33 = {
        text: '',
      };
      const headerTempObject34 = {
        text: 'Netto',
        alignment: 'left',
        bold: true,
        border: [false, true, false, true],
        borderColor: ['', '', '', borderColorSum],
        fillColor: fillColor,
        unbreakable: true
      };

      sumHeaderArray.push(headerTempObject31);
      sumHeaderArray.push(headerTempObject32);
      sumHeaderArray.push(headerTempObject33);
      sumHeaderArray.push(headerTempObject34);
      bodyTableArray.push(sumHeaderArray);

      // Summenspalten
    const machineTempObject41 = {
      text: '',
      alignment: 'left',
      borderColor: ['', borderColorSum, '', ''],
      border: [false,false,false,false],
      fillColor: fillColor,
      colSpan: 3,
      unbreakable: true
    };
    sumArray.push(machineTempObject41);
    for (let i = 0; i < 2; i++) {
      sumArray.push({});
    }


    // Umsatz 10%
    const machineTempObject42 = {
      text: this.formatCurrency.format(+autUmsatz10.toFixed(2)),
      alignment: 'center',
      borderColor: ['', borderColorSum, '', ''],
      border: [false,false,false,false],
      fillColor: fillColor,
      unbreakable: true
    }
    // Text 10%
    const machineTempObject43 = {
      text: '10 % ',
      alignment: 'center',
      borderColor: ['', '', '', ''],
      border: [true,false,false,false],
      fillColor: fillColor,
      unbreakable: true
    };
     // Steuer 10%
     const machineTempObject44 = {
      text: this.formatCurrency.format(+autSteuer10.toFixed(2)),
      alignment: 'center',
      fillColor: fillColor,
      borderColor: ['', '', '', ''],
      border: [false,false,true,false],
      unbreakable: true
    };

    // Nettobetrag 10%
    const machineTempObject45 = {
    text: this.formatCurrency.format(+autSumme10.toFixed(2)),
    alignment: 'left',
    borderColor: ['', borderColorSum, '', ''],
    border: [false,true,false,false],
    fillColor: fillColor,
    unbreakable: true
  };
    sumArray.push(machineTempObject42);
    sumArray.push(machineTempObject43);
    sumArray.push(machineTempObject44);
    sumArray.push(machineTempObject45);

        // Summenspalten 2. Zeile
        const machineTempObject51 = {
          text: '',
          alignment: 'left',
          borderColor: ['', borderColorSum, '', ''],
          border: [false,false,false,false],
          colSpan: 3,
          fillColor: fillColor,
          unbreakable: true
        };

        sumArray2.push(machineTempObject51);
        for (let i = 0; i < 2; i++) {
          sumArray2.push({});
        }

        // Umsatz 20%
        const machineTempObject52 = {
          text: this.formatCurrency.format(+autUmsatz20.toFixed(2)),
          alignment: 'center',
          borderColor: ['', borderColorSum, '', ''],
          border: [false,false,false,false],
          fillColor: fillColor,
          unbreakable: true
        }
        // Text 20%
        const machineTempObject53 = {
          text: '20 % ',
          alignment: 'center',
          borderColor: ['', '', '', ''],
          border: [true,false,false,false],
          fillColor: fillColor,
          unbreakable: true
        };
         // Steuer 20%
         const machineTempObject54 = {
          text: this.formatCurrency.format(+autSteuer20.toFixed(2)),
          alignment: 'center',
          fillColor: fillColor,
          borderColor: ['', '', '', ''],
          border: [false,false,true,false],
          unbreakable: true
        };

        // Nettobetrag 20%
        const machineTempObject55 = {
        text: this.formatCurrency.format(+autSumme20.toFixed(2)),
        alignment: 'left',
        borderColor: ['', borderColorSum, '', ''],
        border: [false,false,false,false],
        fillColor: fillColor,
        unbreakable: true
      };

      sumArray2.push(machineTempObject52);
      sumArray2.push(machineTempObject53);
      sumArray2.push(machineTempObject54);
      sumArray2.push(machineTempObject55);

       // Summenspalten
       const machineTempObject61 = {
        text: 'SUMME ' + machinesell.machine,
        alignment: 'left',
        borderColor: ['', borderColorSum, '', ''],
        border: [false,true,false,true],
        colSpan: 3,
        bold: true,
        fillColor: fillColor,
        unbreakable: true
      };

      sumArray3.push(machineTempObject61);
      for (let i = 0; i < 2; i++) {
        sumArray3.push({});
      }
      // Umsatz
      const umsatzGesamt = autUmsatz10 + autUmsatz20;
      const machineTempObject62 = {
        text: this.formatCurrency.format(+umsatzGesamt.toFixed(2)),
        alignment: 'center',
        borderColor: ['', borderColorSum, '', ''],
        border: [false,true,false,true],
        fillColor: fillColor,
        unbreakable: true
      }
      // Leer
      const machineTempObject63 = {
        text: ' ',
        alignment: 'center',
        borderColor: ['', borderColorSum, '', ''],
        border: [false,true,false,true],
        fillColor: fillColor,
        unbreakable: true
      };
       // Steuer
       const steuerGesamt = autSteuer10 + autSteuer20;
       const machineTempObject64 = {
        text: this.formatCurrency.format(+steuerGesamt.toFixed(2)),
        alignment: 'center',
        fillColor: fillColor,
        borderColor: ['', borderColorSum, '', ''],
        border: [false,true,false,true],
        unbreakable: true
      };
      // Nettobetrag
      const nettoGesamt = autSumme10 + autSumme20;
      const machineTempObject65 = {
      text: this.formatCurrency.format(+nettoGesamt.toFixed(2)),
      alignment: 'left',
      borderColor: ['', borderColorSum, '', ''],
      border: [false,true,false,true],
      fillColor: fillColor,
      unbreakable: true
    };
    sumArray3.push(machineTempObject62);
    sumArray3.push(machineTempObject63);
    sumArray3.push(machineTempObject64);
    sumArray3.push(machineTempObject65);


    // Pfandspalten
      const machineTempObject1 = {
        text: 'Pfandflaschen',
        alignment: 'left',
        borderColor: ['', borderColorSum, '', ''],
        border: [false,false,false,false],
        fillColor: fillColor,
        unbreakable: true
      };
  
      // Anzahl Pfandflaschen
      const machineDepositObject10 = {
        text: +autDep,
        alignment: 'center',
        borderColor: ['', borderColorSum, '', ''],
        border: [false,false,false,false],
        fillColor: fillColor,
        unbreakable: true
      };

      // Pfandwert
      const machineDepositObject11 = {
      text: this.formatCurrency.format(+autDepValue.toFixed(2)),
      alignment: 'center',
      borderColor: ['', borderColorSum, '', ''],
      border: [false,false,false,false],
      fillColor: fillColor,
      unbreakable: true
    };

    // Leer
    const machineDepositObject12 = {
      text:'',
      alignment: 'center',
      borderColor: ['', borderColorSum, '', ''],
      border: [false,false,false,false],
      fillColor: fillColor,
      colSpan: 4,
      unbreakable: true
    };

    sumArray4.push(machineTempObject1);
    sumArray4.push(machineDepositObject10);
    sumArray4.push(machineDepositObject11);
    sumArray4.push(machineDepositObject12);

    bodyTableArray.push(sumArray);
    bodyTableArray.push(sumArray2);
    bodyTableArray.push(sumArray3);
    bodyTableArray.push(sumArray4);
    bodyTableArray.push(emptyArray);
});

/* Summe pro Standort
const sumHeaderArray = [];
const sumArray = [];
const sumArray2 = [];
const sumArray3 = [];
const sumArray4 = [];
const fillColor = '#dddddd';
const borderColorSum = '#a3a3a3';

const headerTempObject30 = {
  text: 'Zusammenfassung ' + sell.location,
  alignment: 'left',
  colSpan: 3,
  bold: true,
  margin: [0, 0, 10, 0],
  border: [false, true, false, true],
  borderColor: ['', '', '', borderColorSum],
  fillColor: fillColor
};
sumHeaderArray.push(headerTempObject30);
for (let i = 0; i < 2; i++) {
  sumHeaderArray.push({});
}

const headerTempObject31 = {
  text: 'Umsatz',
  fillColor: fillColor,
  bold: true,
  unbreakable: true,
  border: [false, true, false, true],
  borderColor: ['', '', '', borderColorSum],
  alignment: 'center',
};
const headerTempObject32 = {
  text: 'Steuer',
  alignment: 'center',
  bold: true,
  colSpan: 2,
  border: [false, true, false, true],
  borderColor: ['', '', '', borderColorSum],
  fillColor: fillColor,
  unbreakable: true
};
const headerTempObject33 = {
  text: '',
};
const headerTempObject34 = {
  text: 'Netto',
  alignment: 'left',
  bold: true,
  border: [false, true, false, true],
  borderColor: ['', '', '', borderColorSum],
  fillColor: fillColor,
  unbreakable: true
};

sumHeaderArray.push(headerTempObject31);
sumHeaderArray.push(headerTempObject32);
sumHeaderArray.push(headerTempObject33);
sumHeaderArray.push(headerTempObject34);
bodyTableArray.push(sumHeaderArray);

// Summenspalten
const machineTempObject41 = {
  text: '',
  alignment: 'left',
  borderColor: ['', borderColorSum, '', ''],
  border: [false,false,false,false],
  fillColor: fillColor,
  colSpan: 3,
  unbreakable: true
};
sumArray.push(machineTempObject41);
for (let i = 0; i < 2; i++) {
  sumArray.push({});
}

// Umsatz 10%
const machineTempObject42 = {
text: this.formatCurrency.format(+locUmsatz10.toFixed(2)),
alignment: 'center',
borderColor: ['', borderColorSum, '', ''],
border: [false,false,false,false],
fillColor: fillColor,
unbreakable: true
}
// Text 10%
const machineTempObject43 = {
text: '10 % ',
alignment: 'center',
borderColor: ['', '', '', ''],
border: [true,false,false,false],
fillColor: fillColor,
unbreakable: true
};
// Steuer 10%
const machineTempObject44 = {
text: this.formatCurrency.format(+locSteuer10.toFixed(2)),
alignment: 'center',
fillColor: fillColor,
borderColor: ['', '', '', ''],
border: [false,false,true,false],
unbreakable: true
};

// Nettobetrag 10%
const machineTempObject45 = {
text: this.formatCurrency.format(+locSumme10.toFixed(2)),
alignment: 'left',
borderColor: ['', borderColorSum, '', ''],
border: [false,true,false,false],
fillColor: fillColor,
unbreakable: true
};
sumArray.push(machineTempObject42);
sumArray.push(machineTempObject43);
sumArray.push(machineTempObject44);
sumArray.push(machineTempObject45);

  // Summenspalten
  const machineTempObject51 = {
    text: '',
    alignment: 'left',
    borderColor: ['', borderColorSum, '', ''],
    border: [false,false,false,false],
    colSpan: 3,
    fillColor: fillColor,
    unbreakable: true
  };

  sumArray2.push(machineTempObject51);
  for (let i = 0; i < 2; i++) {
    sumArray2.push({});
  }

  // Umsatz 20%
  const machineTempObject52 = {
    text: this.formatCurrency.format(+locUmsatz20.toFixed(2)),
    alignment: 'center',
    borderColor: ['', borderColorSum, '', ''],
    border: [false,false,false,false],
    fillColor: fillColor,
    unbreakable: true
  }
  // Text 20%
  const machineTempObject53 = {
    text: '20 % ',
    alignment: 'center',
    borderColor: ['', '', '', ''],
    border: [true,false,false,false],
    fillColor: fillColor,
    unbreakable: true
  };
   // Steuer 20%
   const machineTempObject54 = {
    text: this.formatCurrency.format(+locSteuer20.toFixed(2)),
    alignment: 'center',
    fillColor: fillColor,
    borderColor: ['', '', '', ''],
    border: [false,false,true,false],
    unbreakable: true
  };

  // Nettobetrag 20%
  const machineTempObject55 = {
  text: this.formatCurrency.format(+locSumme20.toFixed(2)),
  alignment: 'left',
  borderColor: ['', borderColorSum, '', ''],
  border: [false,false,false,false],
  fillColor: fillColor,
  unbreakable: true
};

sumArray2.push(machineTempObject52);
sumArray2.push(machineTempObject53);
sumArray2.push(machineTempObject54);
sumArray2.push(machineTempObject55);

 // Summenspalten
 const machineTempObject61 = {
  text: 'SUMME ' + sell.location,
  alignment: 'left',
  borderColor: ['', borderColorSum, '', ''],
  border: [false,true,false,true],
  colSpan: 3,
  bold: true,
  fillColor: fillColor,
  unbreakable: true
};

sumArray3.push(machineTempObject61);
for (let i = 0; i < 2; i++) {
  sumArray3.push({});
}
// Umsatz
const umsatzGesamt = locUmsatz10 + locUmsatz20;
const machineTempObject62 = {
  text: this.formatCurrency.format(+umsatzGesamt.toFixed(2)),
  alignment: 'center',
  borderColor: ['', borderColorSum, '', ''],
  border: [false,true,false,true],
  fillColor: fillColor,
  unbreakable: true
}
// Leer
const machineTempObject63 = {
  text: ' ',
  alignment: 'center',
  borderColor: ['', borderColorSum, '', ''],
  border: [false,true,false,true],
  fillColor: fillColor,
  unbreakable: true
};
 // Steuer
 const steuerGesamt = locSteuer10 + locSteuer20;
 const machineTempObject64 = {
  text: this.formatCurrency.format(+steuerGesamt.toFixed(2)),
  alignment: 'center',
  fillColor: fillColor,
  borderColor: ['', borderColorSum, '', ''],
  border: [false,true,false,true],
  unbreakable: true
};
// Nettobetrag
const nettoGesamt = locSumme10 + locSumme20;
const machineTempObject65 = {
text: this.formatCurrency.format(+nettoGesamt.toFixed(2)),
alignment: 'left',
borderColor: ['', borderColorSum, '', ''],
border: [false,true,false,true],
fillColor: fillColor,
unbreakable: true
};

sumArray3.push(machineTempObject62);
sumArray3.push(machineTempObject63);
sumArray3.push(machineTempObject64);
sumArray3.push(machineTempObject65);

// Pfandspalten
const machineTempObject1 = {
  text: 'Pfandflaschen',
  alignment: 'left',
  borderColor: ['', borderColorSum, '', ''],
  border: [false,false,false,false],
  fillColor: fillColor,
  unbreakable: true
};

// Anzahl Pfandflaschen
const machineDepositObject10 = {
  text: +locDep,
  alignment: 'center',
  borderColor: ['', borderColorSum, '', ''],
  border: [false,false,false,false],
  fillColor: fillColor,
  unbreakable: true
};

// Pfandwert
const machineDepositObject11 = {
text: this.formatCurrency.format(+locDepValue.toFixed(2)),
alignment: 'center',
borderColor: ['', borderColorSum, '', ''],
border: [false,false,false,false],
fillColor: fillColor,
unbreakable: true
};

// Leer
const machineDepositObject12 = {
text:'',
alignment: 'center',
borderColor: ['', borderColorSum, '', ''],
border: [false,false,false,false],
fillColor: fillColor,
colSpan: 4,
unbreakable: true
};

sumArray4.push(machineTempObject1);
sumArray4.push(machineDepositObject10);
sumArray4.push(machineDepositObject11);
sumArray4.push(machineDepositObject12);

bodyTableArray.push(sumArray);
bodyTableArray.push(sumArray2);
bodyTableArray.push(sumArray3);
bodyTableArray.push(sumArray4);
bodyTableArray.push(emptyArray);
*/
});

      // Summentabelle für alle Positionen erzeugen
        const sumHeaderArray = [];
        const sumArray = [];
        const sumArray2 = [];
        const sumArray3 = [];
        const sumArray4 = [];
        const fillColor = '#D9FECF';
        const borderColorSum = '#B8D6AF';

        const headerTempObject30 = {
          text: 'Gesamt',
          alignment: 'left',
          colSpan: 3,
          bold: true,
          margin: [0, 0, 10, 0],
          border: [false, true, false, true],
          borderColor: ['', '', '', borderColorSum],
          fillColor: fillColor
        };
        sumHeaderArray.push(headerTempObject30);
        for (let i = 0; i < 2; i++) {
          sumHeaderArray.push({});
        }

        const headerTempObject31 = {
          text: 'Umsatz',
          fillColor: fillColor,
          bold: true,
          unbreakable: true,
          border: [false, true, false, true],
          borderColor: ['', '', '', borderColorSum],
          alignment: 'center',
        };
        const headerTempObject32 = {
          text: 'Steuer',
          alignment: 'center',
          bold: true,
          colSpan: 2,
          border: [false, true, false, true],
          borderColor: ['', '', '', borderColorSum],
          fillColor: fillColor,
          unbreakable: true
        };
        const headerTempObject33 = {
          text: '',
        };
        const headerTempObject34 = {
          text: 'Netto',
          alignment: 'left',
          bold: true,
          border: [false, true, false, true],
          borderColor: ['', '', '', borderColorSum],
          fillColor: fillColor,
          unbreakable: true
        };

        sumHeaderArray.push(headerTempObject31);
        sumHeaderArray.push(headerTempObject32);
        sumHeaderArray.push(headerTempObject33);
        sumHeaderArray.push(headerTempObject34);
        bodyTableArray.push(sumHeaderArray);

        // Summenspalten
        const machineTempObject41 = {
          text: '',
          alignment: 'left',
          borderColor: ['', borderColorSum, '', ''],
          border: [false,false,false,false],
          fillColor: fillColor,
          colSpan: 3,
          unbreakable: true
        };
        sumArray.push(machineTempObject41);
        for (let i = 0; i < 2; i++) {
          sumArray.push({});
        }
    

      // Umsatz 10%
      const machineTempObject42 = {
        text: this.formatCurrency.format(+umsatz10.toFixed(2)),
        alignment: 'center',
        borderColor: ['', borderColorSum, '', ''],
        border: [false,false,false,false],
        fillColor: fillColor,
        unbreakable: true
      }
      // Text 10%
      const machineTempObject43 = {
        text: '10 % ',
        alignment: 'center',
        borderColor: ['', '', '', ''],
        border: [true,false,false,false],
        fillColor: fillColor,
        unbreakable: true
      };
       // Steuer 10%
       const machineTempObject44 = {
        text: this.formatCurrency.format(+steuer10.toFixed(2)),
        alignment: 'center',
        fillColor: fillColor,
        borderColor: ['', '', '', ''],
        border: [false,false,true,false],
        unbreakable: true
      };

      // Nettobetrag 10%
      const machineTempObject45 = {
      text: this.formatCurrency.format(+netto10.toFixed(2)),
      alignment: 'left',
      borderColor: ['', borderColorSum, '', ''],
      border: [false,true,false,false],
      fillColor: fillColor,
      unbreakable: true
    };
      sumArray.push(machineTempObject42);
      sumArray.push(machineTempObject43);
      sumArray.push(machineTempObject44);
      sumArray.push(machineTempObject45);

          // Summenspalten
          const machineTempObject51 = {
            text: '',
            alignment: 'left',
            borderColor: ['', borderColorSum, '', ''],
            border: [false,false,false,false],
            colSpan: 3,
            fillColor: fillColor,
            unbreakable: true
          };

          sumArray2.push(machineTempObject51);
          for (let i = 0; i < 2; i++) {
            sumArray2.push({});
          }

          // Umsatz 20%
          const machineTempObject52 = {
            text: this.formatCurrency.format(+umsatz20.toFixed(2)),
            alignment: 'center',
            borderColor: ['', borderColorSum, '', ''],
            border: [false,false,false,false],
            fillColor: fillColor,
            unbreakable: true
          }
          // Text 20%
          const machineTempObject53 = {
            text: '20 % ',
            alignment: 'center',
            borderColor: ['', '', '', ''],
            border: [true,false,false,false],
            fillColor: fillColor,
            unbreakable: true
          };
           // Steuer 20%
           const machineTempObject54 = {
            text: this.formatCurrency.format(+steuer20.toFixed(2)),
            alignment: 'center',
            fillColor: fillColor,
            borderColor: ['', '', '', ''],
            border: [false,false,true,false],
            unbreakable: true
          };

          // Nettobetrag 20%
          const machineTempObject55 = {
          text: this.formatCurrency.format(+netto20.toFixed(2)),
          alignment: 'left',
          borderColor: ['', borderColorSum, '', ''],
          border: [false,false,false,false],
          fillColor: fillColor,
          unbreakable: true
        };

        sumArray2.push(machineTempObject52);
        sumArray2.push(machineTempObject53);
        sumArray2.push(machineTempObject54);
        sumArray2.push(machineTempObject55);

         // Summenspalten
         const machineTempObject61 = {
          text: 'SUMME Gesamt',
          alignment: 'left',
          borderColor: ['', borderColorSum, '', ''],
          border: [false,true,false,true],
          colSpan: 3,
          bold: true,
          fillColor: fillColor,
          unbreakable: true
        };

        sumArray3.push(machineTempObject61);
        for (let i = 0; i < 2; i++) {
          sumArray3.push({});
        }
        // Umsatz
        const umsatzGesamt = umsatz10 + umsatz20;
        const machineTempObject62 = {
          text: this.formatCurrency.format(+umsatzGesamt.toFixed(2)),
          alignment: 'center',
          borderColor: ['', borderColorSum, '', ''],
          border: [false,true,false,true],
          fillColor: fillColor,
          unbreakable: true
        }
        // Leer
        const machineTempObject63 = {
          text: ' ',
          alignment: 'center',
          borderColor: ['', borderColorSum, '', ''],
          border: [false,true,false,true],
          fillColor: fillColor,
          unbreakable: true
        };
         // Steuer
         const steuerGesamt = steuer10 + steuer20;
         const machineTempObject64 = {
          text: this.formatCurrency.format(+steuerGesamt.toFixed(2)),
          alignment: 'center',
          fillColor: fillColor,
          borderColor: ['', borderColorSum, '', ''],
          border: [false,true,false,true],
          unbreakable: true
        };
        // Nettobetrag
        const nettoGesamt = netto10 + netto20;
        const machineTempObject65 = {
        text: this.formatCurrency.format(+nettoGesamt.toFixed(2)),
        alignment: 'left',
        borderColor: ['', borderColorSum, '', ''],
        border: [false,true,false,true],
        fillColor: fillColor,
        unbreakable: true
      };
      sumArray3.push(machineTempObject62);
      sumArray3.push(machineTempObject63);
      sumArray3.push(machineTempObject64);
      sumArray3.push(machineTempObject65);

            // Pfandspalten
      const machineTempObject1 = {
        text: 'Pfandflaschen',
        alignment: 'left',
        borderColor: ['', borderColorSum, '', ''],
        border: [false,false,false,false],
        fillColor: fillColor,
        unbreakable: true
      };

      // Anzahl Pfandflaschen
      const machineDepositObject10 = {
        text: +dep,
        alignment: 'center',
        borderColor: ['', borderColorSum, '', ''],
        border: [false,false,false,false],
        fillColor: fillColor,
        unbreakable: true
      };

      // Pfandwert
      const machineDepositObject11 = {
      text: this.formatCurrency.format(+depValue.toFixed(2)),
      alignment: 'center',
      borderColor: ['', borderColorSum, '', ''],
      border: [false,false,false,false],
      fillColor: fillColor,
      unbreakable: true
      };

      // Leer
      const machineDepositObject12 = {
      text:'',
      alignment: 'center',
      borderColor: ['', borderColorSum, '', ''],
      border: [false,false,false,false],
      fillColor: fillColor,
      colSpan: 4,
      unbreakable: true
      };

      sumArray4.push(machineTempObject1);
      sumArray4.push(machineDepositObject10);
      sumArray4.push(machineDepositObject11);
      sumArray4.push(machineDepositObject12);
      
      bodyTableArray.push(sumArray);
      bodyTableArray.push(sumArray2);
      bodyTableArray.push(sumArray3);
      bodyTableArray.push(sumArray4);
  const emptySells = [
    [{
      text: 'Im ' + this.selectedMonth + ' ' + this.selectedYear + ' wurden keine Verkäufe verzeichnet',
      colSpan: 4,
      fontSize: 14,
      border: [false, false, false, false]
    }]
];

const getTableVariable = () => {
  if (!this.sellsEmpty) {
    return bodyTableArray;
  }
  return emptySells;
}

  const content = [
  {
    image: blob,
    fit: [500, 30]
  },
  {
    text: this.currentClient.name,
    fontSize: 12,
    alignment: 'left'
  },
  {
    text: this.currentClient.street + ' ' + this.currentClient.housenumber,
    fontSize: 12,
    alignment: 'left'
  },
  {
    text: this.currentClient.plz + ' ' + this.currentClient.city,
    fontSize: 12,
    alignment: 'left'
  },
  {
    text: 'Erstelldatum: ' + printDate,
    fontSize: 12,
    alignment: 'right',
    margin: [0, 30, 0, 0]
  },
  {
    text: 'Zählwerksauslesung ' + this.selectedMonth + ' ' + this.selectedYear,
    fontSize: 18,
    alignment: 'left',
    bold: true,
    margin: [0, 50, 0, 0]
  },
  {
    table: {
      widths: ['*', '5%', '15%', '15%', '8%', '15%', '15%'],
      body: getTableVariable()
    },
    margin: [0, 30, 0, 0],
    layout: {
      hLineWidth: function (i, node) {
        return (i === 0 || i === node.table.body.length) ? 1 : 1;
      },
      vLineWidth: function (i, node) {
        return (i === 0 || i === node.table.widths.length) ? 0.5 : 0.5;
      }
    }
  }
];

const footer = (currentPage, pageCount) => {
return {
    table: {
      widths: ['*', '20%'],
      body: [
        [
          {
            text: 'connect.regio.team | Beleg erzeugt von ' + this.loggedInUser.name + ' | Zählwerksauslesung ' + this.selectedMonth + ' ' + this.selectedYear,
            fillColor: '#8fff67',
            margin: [20, 0, 0, 0],
            fontSize: 10
          },
          {
            text: 'Seite ' + currentPage + ' von ' + pageCount,
            fillColor: '#8fff67',
            alignment: 'right' ,
            margin: [0, 0, 20, 0],
            fontSize: 10
        }
        ]
      ]
    },
    layout: 'noBorders'
  }
};


  const def = {
    info: {
      title: 'Zählwerksauslesung ' + this.selectedMonth + ' ' + this.selectedYear,
      author: 'RegioAdmin by Regio.Team'
    },
    footer: footer,
    content: content,
    pageBreakBefore(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
      return currentNode.pageNumbers.length > 1 && currentNode.unbreakable;
    }
  };
  const filename = 'auslesung' + this.selectedMonth + this.selectedYear + '.pdf';
  this.pdfService.generatePdf(def, filename);
});
}
}
