<html>
<div class="container" fxLayout="row wrap" fxLayoutGap="16px grid">
  <div fxFlex="60%" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-card class="card">
      <mat-card-content>
        <mat-progress-bar mode="indeterminate" *ngIf="loadingData"></mat-progress-bar>
        <div>
          <form [formGroup]="userForm" (ngSubmit)="saveData()">
            <table width="100%">
              <tbody>

                <!-- Benutzername -->
                <tr>
                  <td colspan="2">
              <p class="maillabel">{{userName}}</p>
            </td>
          </tr>

                <tr>
                  <td class="maillabel">Passwortänderung</td>
                </tr>
                  <tr>
                    <td>
                      <mat-form-field [style.width.%]="100">
                        <mat-label>Aktuelles Passwort *</mat-label>
                        <input type="password" matInput formControlName="currentpassword">
                        <mat-error *ngIf="currentpwfalse()">
                         Erforderlich
                        </mat-error>
                          </mat-form-field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <mat-form-field [style.width.%]="100">
                        <mat-label>Neues Passwort *</mat-label>
                        <input type="password" matInput formControlName="newpassword">
                        <mat-error *ngIf="errorHandling('newpassword', 'minlength')">
                          Mindestens 6 Zeichen!
                         </mat-error>
                          </mat-form-field>
                    </td>
                    </tr>
                    <tr>
                       <td>
                      <mat-form-field [style.width.%]="100">
                        <mat-label>Passwort bestätigen *</mat-label>
                        <input type="password" matInput formControlName="passwordconfirmation">
                        <mat-error *ngIf="errorHandling('passwordconfirmation', 'minlength')">
                          Mindestens 6 Zeichen!
                         </mat-error>
                          </mat-form-field>
                    </td>
                  </tr>
                  <tr>
                    <td>Für die Passwortänderung müssen alle 3 Felder ausgefüllt werden</td>
                  </tr>

              <tr>
            <td><button mat-raised-button color="primary" [disabled]="!userForm.valid" type="submit">Speichern</button></td>
          </tr>
          </tbody>
          </table>
          </form>



      </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

</html>

