import { Subscription } from 'rxjs';

import { Component, Input, OnInit, ViewChild, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../api.service';
import { Client, User, Product} from '../schemas';
import { TokenService } from '../token.service';
import { parseSelectorToR3Selector } from '@angular/compiler/src/core';


export interface DialogData {
  locations: any;
  machines: any;
  suppliers: any;
  option: number;
  formdata: Product;
}

export interface DialogDataConfirm {
  text: string;
  option: number;
}

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() currentClient: Client;
  @Input() loggedInUser: User;
  @ViewChild('productsTable', { read: MatSort, static: true }) proSort: MatSort;
  @ViewChild('prodTable', { read: MatSort, static: true }) prodSort: MatSort;
  @ViewChild('productsPaginator', {static: true}) proPaginator: MatPaginator;
  @ViewChild('prodPaginator', {static: true}) prodPaginator: MatPaginator;
  token: string;
  tokenSubscribtion: Subscription;
  displayedColumns: string[] = ['name', 'active', 'deposit','actions'];
  displayedProdColumns: string[] = ['location', 'machine', 'shelf', 'stock', 'actions'];
  dataSource = new MatTableDataSource();
  dataSourceProd = new MatTableDataSource();
  loadingProducts = false;
  locations = [];
  machines = [];
  suppliers = [];
  productSelected = false;
  selectedProduct: Product;
  loadingProductInfo = false;
  productsForInfoTable = [];
  dataDate: Date;
  oldProduct: Product;

  constructor(private api: ApiService, public dialog: MatDialog, private tokenService: TokenService) { }

  ngOnInit(): void {
    this.tokenSubscribtion = this.tokenService.currentToken.subscribe(token => {
      this.token = token;
    });
    this.getMyLocations();
    this.getMyMachines();
    this.getAllProducts();
    this.getSuppliers();
  }

  ngOnDestroy(): void {
    this.tokenSubscribtion.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.proSort;
    this.dataSource.paginator = this.proPaginator;
    this.dataSourceProd.sort = this.prodSort;
    this.dataSourceProd.paginator = this.prodPaginator;
  }

  applyFilter = (event: Event) => {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterProd = (event: Event) => {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceProd.filter = filterValue.trim().toLowerCase();
  }

  newProduct = () => {
    const dialogRef = this.dialog.open(DialogProduct, {
      width: '60%',
      data: {
        locations: this.locations,
        machines: this.machines,
        suppliers: this.suppliers,
        option: 1,
        formdata: {
          _id: '1',
          name: '',
          description: '',
          pictures: '',
          deposit: false,
          depositValue: 0,
          supplier: this.currentClient._id,
          client: this.currentClient._id,
          ek: null,
          vk: null,
          taxrate: 20,
          active: true,
          shelfs: ''
        }
      },
      hasBackdrop: true,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 0) {
        // Produkt hinzufügen
        const ek = result.ek;
        const vk = result.vk;
        result.ek = ek.replace(' ', '').replace(',', '.');
        result.vk = vk.replace(' ', '').replace(',', '.');
        this.api.addProduct(result, this.token)
        .subscribe((res: any) => {
          this.addProdToSupplier(res._id, res);
          // Alle Produkte in die Automaten eintragen
          if (res.shelfs.length !== undefined && res.shelfs[0].location !== '') {
            this.updateMachinesWithProducts(res, res._id);
          }
          this.getAllProducts();
          }, (err: any) => {
          });
    }
    });
  }

  addProdToSupplier = (id: string, product: Product) => {

    // Zulieferer eintragen
    this.api.getClient(product.supplier, this.token)
    .subscribe((resC: any) => {
      const supArray = resC.supplierarray;
      if (supArray.length < 1 || supArray.length === undefined) {
        const newArray = [];
        newArray.push(id);
        resC.supplierarray = newArray;
      } else {
        const arrayIndex = supArray.find(element => element === id);
        if (arrayIndex === undefined) {
          // Neuen Eintrag verfassen
          supArray.push(id);
          resC.supplierarray = supArray;
        }
      }
      this.api.updateClient(resC._id, resC, this.token)
      .subscribe(() => {
        }, (err: any) => {
        });

    });
  }

  updateMachinesWithProducts = (res: Product, id: string) => {
    let updateShelfs = null;
    if (res.shelfs.length > 0) {
      updateShelfs = res.shelfs;
    } else {
      updateShelfs = this.oldProduct.shelfs;
    }

    updateShelfs.forEach((article) => {
      const machineId = article.machine;
      if (machineId !== '') {
        this.api.getMachine(machineId, this.token)
        .subscribe((machine: any) => {
          if (article.shelf) {
            machine.confrows.forEach((value, index) => {
                const articleIndex = article.shelf.findIndex(element => element === value.shelf);
                if (articleIndex >= 0) {
                  if (machine.confrows[index].product !== id) {
                    machine.confrows[index].product = id;
                    machine.confrows[index].stock = 0;
                  } else if (machine.confrows[index].product === id) {
                  } else {
                    machine.confrows[index].product = '';
                    machine.confrows[index].stock = 0;
                  }
                } else {
                  if (machine.confrows[index].product === id) {
                    machine.confrows[index].product = '';
                    machine.confrows[index].stock = 0;
                  }
                }
            });
        }
          // Produkte für den Automaten wieder in die Datenbank eintragen
          this.api.updateMachine(machine._id, machine, this.token)
          .subscribe(() => {
            }, (err: any) => {
            });
          }, (err: any) => {
          });
      }

    });
    this.getMyMachines();
    this.getAllProducts();
  }

  getSuppliers = () => {
    this.api.getSuppliers(this.token)
    .subscribe((res: any) => {
       this.suppliers = res;
      }, (err: any) => {
      });
  }

  getMyLocations = () => {
    return new Promise<void>((resolve) => {
    this.api.getLocationsForClient(this.currentClient._id, this.token)
    .subscribe((res: any) => {
        this.locations = res;
        resolve();
      }, (err: any) => {
      });
    });
  }

  getMyMachines = () => {
    return new Promise<void> ((resolve) => {
      this.api.getMachinesForClient(this.currentClient._id, this.token)
      .subscribe((res: any) => {
          this.machines = res;
          resolve();
        }, (err: any) => {
        });
    });
  }

  getAllProducts = () => {
    this.loadingProducts = true;
    this.api.getProducts(this.currentClient._id, this.token)
    .subscribe((res: any) => {
        this.dataSource.data = res;
        this.loadingProducts = false;
      }, (err: any) => {
      });
  }

  changeActiveState = (evt: any, element: Product) => {
    element.active = evt.checked;
    this.api.updateProduct(element._id, element, this.token)
    .subscribe(() => {
    this.getAllProducts();
    this.getMyMachines();
      }, (err: any) => {
      });
  }

  changeDepositState = (evt: any, element: Product) => {
    element.deposit = evt.checked;
    this.api.updateProduct(element._id, element, this.token)
    .subscribe((result: any) => {
    this.getAllProducts();
    this.getMyMachines();
      }, (err: any) => {
      });
  }

  deleteProduct = (product: Product) => {
    this.productSelected = false;
    this.selectedProduct = null;
    this.oldProduct = product;
    const dialogRef = this.dialog.open(DialogConfirmProduct, {
      width: '40%',
      data: {
       text: 'Soll das Produkt wirklich gelöscht werden? Der Stand in den Automaten wird zurückgesetzt und das Warenfach wieder freigegeben!',
       option: 1
        },
        hasBackdrop: true,
        disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    if (result === 1) {
      this.loadingProducts = true;
      this.api.deleteProduct(this.oldProduct._id, this.token)
      .subscribe((res: any) => {
        // Löschen der Produktzuweisungen in den Automaten
        if (this.oldProduct.shelfs) {
          this.oldProduct.shelfs.forEach((shelf) => {
            //Machinendaten holen
            this.api.getMachine(shelf.machine, this.token)
            .subscribe((machine: any) => {
              if (shelf.shelf) {
                shelf.shelf.forEach((articleShelf) => {
                machine.confrows.forEach((value, index) => {
                  if (value.shelf === articleShelf) {
                   machine.confrows[index].product = '';
                   machine.confrows[index].stock = 0;
                  }
                });
              });
            }
              // Produkte für den Automaten wieder in die Datenbank eintragen
              this.api.updateMachine(machine._id, machine, this.token)
              .subscribe(() => {
                }, (err: any) => {
                });
              }, (err: any) => {
              });
          });
        }
        this.getMyMachines();
        this.getAllProducts();
        }, (err: any) => {
        });
      }
    });
  }

  updateProduct = (product: Product) => {
    this.productSelected = false;
    this.selectedProduct = null;
    this.oldProduct = product;
    const dialogRef = this.dialog.open(DialogProduct, {
      width: '60%',
      data: {
        locations: this.locations,
        machines: this.machines,
        suppliers: this.suppliers,
        option: 2,
        formdata: product
      },
      hasBackdrop: true,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 0) {
        // Produkt hinzufügen
        this.loadingProducts = true;
        const ek = result.ek;
        const vk = result.vk;
        result.ek = ek.toString().replace(' ', '').replace(',', '.');
        result.vk = vk.toString().replace(' ', '').replace(',', '.');
        this.api.updateProduct(product._id, result, this.token)
        .subscribe((res: any) => {
          this.addProdToSupplier(product._id, result);
          this.updateMachinesWithProducts(result, product._id);
          }, (err: any) => {
          });
    }
    });
  }

  selectProduct = (element: Product) => {
    this.productSelected = true;
    this.selectedProduct = element;
    this.getProductsForInfoTable().then((res) => {
      this.loadingProductInfo = false;
      this.dataDate = new Date();
      if (res) {
      this.dataSourceProd.data = this.productsForInfoTable;
      } else {
        this.dataSourceProd.data = [];
      }
    });
  }

  refreshDataTable = () => {
    this.getProductsForInfoTable().then((res) => {
      this.dataDate = new Date();
      this.loadingProductInfo = false;
      if (res){
      this.dataSourceProd.data = this.productsForInfoTable;
         } else {
        this.dataSourceProd.data = [];
      }
    });
  }

  getProductsForInfoTable = () => {
    this.productsForInfoTable = [];
    this.loadingProductInfo = true;
    const selectedShelfs = this.selectedProduct.shelfs;
    return new Promise ((resolve) => {
      const shelfsLength = selectedShelfs.length;
      if (shelfsLength > 0) {
      selectedShelfs.forEach((el, index1) => {
      const locationV = el.location;
      const machineV = el.machine;
      // Machinendaten holen
      this.api.getMachine(machineV, this.token)
      .subscribe((machineData: any) => {
        if (machineData) {
        const machineRows = machineData.confrows;
        const shelfLength = el.shelf.length;
        // Pro Produktfach die Daten aus den Maschinendaten holen
        el.shelf.forEach((ele, index2) => {
          machineRows.forEach((elem) => {
            if (elem.shelf === ele) {
              this.productsForInfoTable.push({
                location: locationV,
                machine: machineV,
                shelf: ele,
                stock: elem.stock
              });
            }
            if ((index1 === shelfsLength - 1) && (index2 === shelfLength - 1)) {
              // Durchlauf erfolgreich
              resolve(true);
            }
          });
        });
      }
      });
    });
  } else {
    resolve(false);
  }
  });
  }


  getLocationName = (id: string) => {
    return this.locations.find(t => t._id === id).name;
  }

  getMachineName = (id: string) => {
    return this.machines.find(t => t._id === id).name;
  }

  getTotalStock = () => {
    return this.productsForInfoTable.map(t => t.stock).reduce((acc, value) => acc + value, 0);
  }
}


@Component({
  selector: 'dialog-product',
  templateUrl: 'dialog-product.html',
  styleUrls: ['./products.component.css']
})
// tslint:disable-next-line: component-class-suffix
export class DialogProduct implements OnInit, AfterViewInit {
  newForm: FormGroup;
  loading = false;
  success = false;
  currentClient: Client;
  shelfs: FormArray;
  configTable: FormGroup;
  locations = [];
  machines = [];
  suppliers = [];

  constructor(
    public dialogRef: MatDialogRef<DialogProduct>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private fb: FormBuilder) {}

  onSubmit(): void {
    this.dialogRef.close(this.newForm.value);
  }

  cancleDialog(): void {
    this.dialogRef.close(0);
  }

  public errorHandling = (control: string, error: string) => {
    return this.newForm.controls[control].hasError(error);
  }

  getMachines = (location: string) => {
    const returnArray = [];
    if (location) {
    this.machines.forEach((machine) => {
      if (machine.location === location && machine.active) {
        returnArray.push(machine);
      }
    });
  }
    return returnArray;
  }

  getShelfs = (machine: string) => {
    const returnArray = [];
    if (machine) {
    this.machines.forEach((machineV) => {
      if (machineV._id === machine) {
        machineV.confrows.forEach((row) => {
          if (row.active) {
            returnArray.push(row);
          }
        });

      }
    });
  }
    return returnArray;
  }

  ngAfterViewInit(): void {
    if (this.data.option === 1) {
    this.shelfs = this.newForm.get('shelfs') as FormArray;
    }
  }

  disableMachineField = (i: any) => {
    const machineValue = this.newForm.get('shelfs')['controls'][i].controls.machine.value;
    if (machineValue.length > 1) {
      return true;
    } else {
      return false;
    }
  }

  disableLocationsField = (i: any) => {
    const locationValue = this.newForm.get('shelfs')['controls'][i].controls.location.value;
    if (locationValue.length > 1) {
      return true;
    } else {
      return false;
    }
  }

  machineSelected = (i: number) => {
    const machine = this.newForm.get('shelfs')['controls'][i].controls.machine.value;
    if (machine.length > 0) {
      return true;
    }
    return false;
  }

  changedValues = (indexSelect: number, evt: any) => {

    // Zuerst alte Reservierungen entfernen
    this.machines.forEach((value, index) => {
        const confrows = this.machines[index].confrows;
        confrows.forEach((valueC, indexC) => {
            if (valueC.product === this.data.formdata._id) {
              this.machines[index].confrows[indexC].product = '';
            }
            });
      });
    const machine = this.newForm.get('shelfs')['controls'];
    machine.forEach((valueM, indexM) => {
     const machineId = valueM.controls.machine.value;
     this.machines.forEach((value, index) => {
      if (value._id === machineId) {
        const confrows = this.machines[index].confrows;
        confrows.forEach((valueC, indexC) => {
            // Neue Reservierung eintragen
            if (machine[indexM].controls.shelf.value.length > 0) {
              machine[indexM].controls.shelf.value.forEach((valueE) => {
                if (valueC.shelf === valueE) {
                  this.machines[index].confrows[indexC].product = this.data.formdata._id;
                }
              });
            }
            });
      }
      });
    });
  }

  addProductId(): FormGroup {
    return this.fb.group({
      location: '',
      machine: '',
      shelf: ''
    });
  }

  addProduct = () => {
    this.shelfs.push(this.addProductId());
  }

  deleteEntry = (id: number) => {
    this.shelfs.removeAt(id);
  }

  addAllProducts = () => {
    this.shelfs = this.newForm.get('shelfs') as FormArray;
    this.data.formdata.shelfs.forEach((product) => {
      this.shelfs.push(
        this.fb.group({
          location: product.location,
          machine: product.machine,
          shelf: this.fb.control(product.shelf)
        })
      );
      });
  }


  ngOnInit(): void {
    this.locations = this.data.locations;
    this.machines = this.data.machines;
    this.suppliers = this.data.suppliers;
    if (this.data.option === 1) {
    this.newForm = this.fb.group({
    name: [this.data.formdata.name, [Validators.required, Validators.minLength(3)]],
    description: [this.data.formdata.description],
    deposit: [this.data.formdata.deposit],
    pictures: [this.data.formdata.pictures],
    supplier: [this.data.formdata.supplier, [Validators.required]],
    client: [this.data.formdata.client, [Validators.required]],
    ek: [this.data.formdata.ek, [Validators.required, amountValidator]],
    vk: [this.data.formdata.vk, [Validators.required, amountValidator]],
    taxrate: [this.data.formdata.taxrate, Validators.required],
    active: [this.data.formdata.active],
    shelfs: this.fb.array([ this.addProductId() ])
  });
} else {
  this.newForm = this.fb.group({
    name: [this.data.formdata.name, [Validators.required, Validators.minLength(3)]],
    description: [this.data.formdata.description],
    deposit: [this.data.formdata.deposit],
    pictures: [this.data.formdata.pictures],
    supplier: [this.data.formdata.supplier, [Validators.required]],
    client: [this.data.formdata.client, [Validators.required]],
    ek: [this.data.formdata.ek.toString().replace('.', ','), [Validators.required, amountValidator]],
    vk: [this.data.formdata.vk.toString().replace('.', ','), [Validators.required, amountValidator]],
    taxrate: [this.data.formdata.taxrate, Validators.required],
    active: [this.data.formdata.active],
    shelfs: this.fb.array([])
});
  this.addAllProducts();
}
}
}


export function amountValidator(
  control: AbstractControl
): { [key: string]: any } | null {
  const valid = /^[0-9]+(?:[.,][0-9]{1,2})?$/.test(control.value);
  return valid
    ? null
    : { invalidLocation: { valid: false, value: control.value } };
}

@Component({
  selector: 'dialog-confirm-prod',
  templateUrl: 'dialog-confirm.html',
  styleUrls: ['./products.component.css']
})

export class DialogConfirmProduct implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DialogConfirmProduct>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataConfirm) {}

   confirm(): void {
    this.dialogRef.close(1);
  }

  ngOnInit(): void {
    if (this.data.option === 3) {
    this.dialogRef.close(1);
    }
  }

  cancleDialog(): void {
    this.dialogRef.close(0);
  }
}
