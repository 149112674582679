<html>
<div class="container" fxLayout="row wrap" fxLayoutGap="16px grid">
  <div fxFlex="30%" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-card class="clientscard">
      <mat-card-header>
        <mat-card-title>Alle Kunden</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-progress-bar mode="indeterminate" *ngIf="loadingClients"></mat-progress-bar>
        <div>
          <div class="filterField">
            <mat-form-field style="width: 100%">
              <mat-label>Filter</mat-label>
              <input matInput (keyup)="applyFilter($event)" #input>
            </mat-form-field>
          </div>
        <!--<table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8 clientstable" #clientsTable="matSort">


        </table>-->
        <mat-paginator #clientsPaginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="newClient()">Neuer Kunde</button>
      </mat-card-actions>
    </mat-card>
  </div>
  <div fxFlex="40%" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-card class="clientscard">
      <mat-card-header>
        <mat-card-title>Kundendaten</mat-card-title>
      </mat-card-header>
      <mat-card-content>


      </mat-card-content>
      <mat-card-actions>
      </mat-card-actions>
    </mat-card>
  </div>

  <div fxFlex="30%" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-card class="clientscard">
      <mat-card-header>
        <mat-card-title>Benutzer</mat-card-title>
      </mat-card-header>

      <mat-card-content>

      </mat-card-content>
      <mat-card-actions>

      </mat-card-actions>
    </mat-card>
  </div>
</div>

</html>
