import { Location, RegioLock, Satellite } from './../schemas';
import { Component, Input, OnInit, ViewChild, AfterViewInit, EventEmitter, Inject, AfterContentInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../api.service';
import { Client, User, RegioLockCode } from '../schemas';
import { TokenService } from '../token.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common'
import { DateAdapter } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';

export interface DialogData {
  option: number;
  formdata: RegioLock;
}

export interface DialogDataCode {
  option: number;
  formdata: RegioLockCode;
  locationid: string;
}

export interface DialogDataProtocol {
  locationid: string;
  option: number;
  tableid: string;
  internalid: string;
  satelliteid: string;
}

export interface DialogDataTime {
  option: number;
  formdata: RegioLock;
}

export interface DialogDataConfirm {
  text: string;
  option: number;
}

interface Days {
  value: number;
  viewValue: string;
}

export interface DialogDataCodeNewtime {
  option: number;
  formdata: any;
  locationid: string;
  arrayid: number;
}

export interface DialogDataRestart {
  id: string;
}

@Component({
  selector: 'app-regiolock',
  templateUrl: './regiolock.component.html',
  styleUrls: ['./regiolock.component.css']
})
export class RegiolockComponent implements OnInit, OnDestroy {
  @Input() currentClient: Client;
  @Input() loggedInUser: User;
  @Input() selectedLocation: Location;

  token: string;
  displayedRegiolockColumns: string[] = ['name', 'status', 'actions'];
  regiolockSource = new MatTableDataSource();
  loadRegiolocks = false;
  satelliteStatus = 1;
  tokenSubscribtion: any;
  displayedCodetableColumns: string[] = ['name', 'aktiv', 'actions'];
  codetablesource = new MatTableDataSource();
  loadCodeTables = false;
  displayedTimetableColumns: string[] = ['name', 'aktiv', 'actions'];
  timetablesource = new MatTableDataSource();
  loadTimeTables = false;
  satellitesIntervall: any;
  satellites = []


  constructor(private api: ApiService, public dialog: MatDialog, private tokenService: TokenService) { }

  ngOnInit(): void {
    this.tokenSubscribtion = this.tokenService.currentToken.subscribe(token => this.token = token);
    this.getDevices();
    this.getCodetables(true);
  }

  ngOnDestroy(): void {
    clearInterval(this.satellitesIntervall);
    this.tokenSubscribtion.unsubscribe();
  }


  newRegiolock = () => {
    const dialogRef = this.dialog.open(DialogRegiolock, {
      width: '40%',
      data: {
        option: 1,
        formdata: {
          name: '',
          raspkey: '',
          satelliteid: '',
          conf_r1: 0,
          conf_r2: 0,
          conf_r3: 0,
          conf_r4: 0,
          tbl_r1: '0',
          tbl_r2: '0',
          tbl_r3: '0',
          tbl_r4: '0',
          to_r1: 0,
          to_r2: 0,
          to_r3: 0,
          to_r4: 0,
          location: this.selectedLocation._id,
          active: true
        }
      },
      hasBackdrop: true,
      disableClose: true
    });


  dialogRef.afterClosed().subscribe(result => {
    if (result !== 0) {
    this.loadRegiolocks = true;
    const formValue = result.formdata;
    const raspkey = result.raspkey;
    formValue.raspkey = raspkey;

    this.api.addRegiolock(formValue, this.token)
      .subscribe((res: any) => {
          this.getDevices();
        }, (err: any) => {
        });
      }
    });
  }

  editDevice = (element: RegioLock) => {
    const dialogRef = this.dialog.open(DialogRegiolock, {
      width: '40%',
      data: {
        option: 2,
        formdata: element
      },
      hasBackdrop: true,
      disableClose: true
    });


  dialogRef.afterClosed().subscribe(result => {
    if (result !== 0) {
      const formData = result.formdata;
      this.api.updateRegiolock(element._id, formData, this.token)
      .subscribe((res: any) => {
          this.getDevices();
        }, (err: any) => {
        });
    }
    });
  }


  newCodetable = () => {
    const dialogRef = this.dialog.open(DialogCodetable, {
      width: '90%',
      data: {
        option: 1,
        formdata: {
          name: '',
          codes: [],
          location: this.selectedLocation._id,
          active: true
        },
        locationid: this.selectedLocation._id
      },
      hasBackdrop: true,
      disableClose: true
    });


  dialogRef.afterClosed().subscribe(result => {
    if (result !== 0) {
    this.loadCodeTables = true;


    this.api.addRegiolockCode(result, this.token).subscribe((res: any) => {
      this.getCodetables(true);
        }, (err: any) => {
        });
      }
    });
  }


  newTimetable = () => {
    const dialogRef = this.dialog.open(DialogTimetable, {
      width: '40%',
      data: {
        option: 1,
        formdata: {
          name: '',
          raspkey: '',
          satelliteid: '',
          location: this.selectedLocation._id,
          active: true
        }
      },
      hasBackdrop: true,
      disableClose: true
    });


  dialogRef.afterClosed().subscribe(result => {
    if (result !== 0) {
    this.loadRegiolocks = true;
    const formValue = result.formdata;
    const raspkey = result.raspkey;
    formValue.raspkey = raspkey;

    this.api.addRegiolock(formValue, this.token)
      .subscribe((res: any) => {
          this.getDevices();
        }, (err: any) => {
        });
      }
    });
  }

  getDevices = () => {
    this.loadRegiolocks = true;
      this.api.getRegiolocksForLocation(this.selectedLocation._id, this.token)
      .subscribe((res: any) => {
        if (res) {
          this.regiolockSource.data=res;
          this.satellitesInterval();
        }
          this.loadRegiolocks = false;
        }, (err: any) => {
          this.loadRegiolocks = false;
        });
  }

  deleteDevice = (id: string) => {
    const dialogRef = this.dialog.open(DialogConfirmLock, {
      width: '40%',
      data: {
       text: 'Soll das Gerät wirklich entbunden werden?',
       option: 1
        },
        hasBackdrop: true,
        disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
      this.loadRegiolocks = true;
      this.api.deleteRegiolockDevice(id, this.token)
      .subscribe((res: any) => {
          this.getDevices();
        }, (err: any) => {
          this.getDevices();
        });
      }
    });
  }

  deleteTable = (id: string) => {
    const dialogRef = this.dialog.open(DialogConfirmLock, {
      width: '40%',
      data: {
       text: 'Soll die Tabelle wirklich gelöscht werden?',
       option: 1
        },
        hasBackdrop: true,
        disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.loadCodeTables = true;
      this.api.deleteCodetable(id, this.selectedLocation._id, this.token)
      .subscribe((res: any) => {
        if (res) {
          this.getCodetables(false);
          this.getDevices();
        }
        }, (err: any) => {
          this.getCodetables(false);
          this.getDevices();
        });
      }
    });
  }

  getCodetables = (load: boolean) => {
    if (load) {
      this.loadCodeTables = true;
    }

    this.api.getCodetablesForLocation(this.selectedLocation._id, this.token).subscribe((codetables) => {
      if (codetables) {
        this.codetablesource.data = codetables;
      }
  this.loadCodeTables = false;
      });
    }

    editCodeTable = (element: RegioLockCode) => {

    const dialogRef = this.dialog.open(DialogCodetable, {
      width: '90%',
      data: {
        option: 2,
        formdata: element,
        locationid: this.selectedLocation._id
      },
      hasBackdrop: true,
      disableClose: true
    });


  dialogRef.afterClosed().subscribe(result => {
    if (result !== 0) {
      this.loadCodeTables = true;
    this.api.updateCodetable(element._id, result, this.selectedLocation._id, this.token).subscribe(() => {
      this.getCodetables(false);
        });
      } else {
        this.getCodetables(false);
      }
    });
    }

    changeActiveStateCode = (event: any, element: RegioLockCode) => {
      element.active = event.checked;
      this.api.updateCodetable(element._id, element, this.selectedLocation._id, this.token).subscribe(() => {
        this.getCodetables(false);
          });
    }


openAccessProtocol = () => {
    this.dialog.open(DialogProtocolRegiolock, {
        width: '60%',
        data: {
            locationid: this.selectedLocation._id,
            option: 1
        },
        hasBackdrop: true,
        disableClose: true
      });
    }

    openSatelliteProtocol = (element: RegioLock) => {
      this.dialog.open(DialogProtocolRegiolock, {
          width: '60%',
          data: {
              satelliteid: element.satelliteid,
              option: 3
          },
          hasBackdrop: true,
          disableClose: true
        });
      }

    restartSatellite = (element: any) => {
      const dialogRef = this.dialog.open(DialogConfirmLock, {
        width: '40%',
        data: {
         text: 'Soll das Gerät wirklich neu gestartet werden?',
         option: 1
          },
          hasBackdrop: true,
          disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 1) {
          const values = {
            option: 1,
            ack_option: false
          };

          if (element) {
            this.api.updateSatellite(element.satelliteid, values, this.token)
          .subscribe((res: any) => {
            this.dialog.open(DialogRestartLock, {
              width: '40%',
              data: {
               id: element.satelliteid
                },
                hasBackdrop: true,
                disableClose: true
            });
            }, (err: any) => {
            });
          }
          }
        });
    }


    getSatelliteStatus = (element: RegioLock) => {
      if (this.satellites.find(t => t._id === element.satelliteid)) {
        const satellite = this.satellites.find(t => t._id === element.satelliteid);
      if (satellite.flag_stat1 && satellite.active) {
        const currentTime = new Date().getTime();
        const lastUpdateTime = new Date(satellite.lastUpdate).getTime();
        const diff = currentTime - lastUpdateTime;
        const difference = Math.floor(diff / 1000 % 60);
        if (difference > 21) {
          return 3;
        } else {
          return 1;
        }
      } else if (!satellite.flag_stat1 && satellite.active) {
        return 2;
      }
    } else {
      return 2;
    }
    }


    satellitesInterval = () => {
      clearInterval(this.satellitesIntervall);
      this.satellitesIntervall = setInterval(() => {
        this.api.getSatellitesForLocation(this.selectedLocation._id, this.token)
        .subscribe((res: any) => {
            this.satellites = res;
          }, (err: any) => {
          });
      }, 9000);
    }
}


@Component({
  selector: 'dialog-regiolock',
  templateUrl: 'dialog-regiolock.html',
  styleUrls: ['./regiolock.component.css']
})
export class DialogRegiolock implements OnInit {
  getValues = new EventEmitter();
  newForm: FormGroup;
  loading = false;
  success = false;
  checked = true;
  machineLocations: any;
  machineManufacturers: any;
  machineModels: any;
  modelDisabled = true;
  falseRaspKey = false;
  raspKeyBounded = false;
  loadRaspKey = false;
  raspKeyValid = false;
  raspKeyUngeeignet = false;
  showCheckButton = false;
  raspKeyChecked = false;
  showCheckHint = false;
  raspKey: string;
  token: string;
  tokenSubscribtion: Subscription;
  codetables: RegioLockCode[];
  conftypes = [
    {value: 0, viewValue: "Keine"},
    {value: 1, viewValue: "Codetabelle"}
  ];


  constructor(
    public dialogRef: MatDialogRef<DialogRegiolock>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private api: ApiService,
    private tokenService: TokenService) {}

   onSubmit(): void {
     if (this.raspKeyValid) {
     const returnValue = {
       formdata: this.newForm.value,
       raspkey: this.raspKey
     };
     this.tokenSubscribtion.unsubscribe();
     this.dialogRef.close(returnValue);
    }
  }

  cancleDialog(): void {
    this.tokenSubscribtion.unsubscribe();
    this.dialogRef.close(0);
  }

  public errorHandling = (control: string, error: string) => {
    return this.newForm.controls[control].hasError(error);
  }


  checkRaspKey = () => {
    const key = this.newForm.get('raspkey').value;
    this.raspKeyValid = false;
    this.showCheckButton = false;
    if (key) {
      this.loadRaspKey = true;
      this.api.checkRaspKeyRegiolock(key, this.token)
      .subscribe((res: any) => {
      this.showCheckHint = false;
      if (res === 0) {
        // Fehler: Key nicht gefunden
        this.raspKeyChecked = true;
        this.falseRaspKey = true;
        this.raspKeyValid = false;
        this.raspKeyBounded = false;
        this.raspKeyUngeeignet = false;
        this.loadRaspKey = false;
        this.showCheckButton = true;
      } else if (res === 1) {
        // Fehler: Bereits gebunden
        this.raspKeyChecked = true;
        this.falseRaspKey = false;
        this.raspKeyBounded = true;
        this.raspKeyValid = false;
        this.loadRaspKey = false;
        this.raspKeyUngeeignet = false;
        this.showCheckButton = true;
      }
        else if (res === 2) {
          // Fehler: Ungeeignet
          this.raspKeyChecked = true;
          this.falseRaspKey = false;
          this.raspKeyBounded = false;
          this.raspKeyValid = false;
          this.raspKeyUngeeignet = true;
          this.loadRaspKey = false;
          this.showCheckButton = true;
        }
        else if (res === 3) {
        // Erfolgreich
        this.raspKeyChecked = true;
        this.falseRaspKey = false;
        this.raspKeyBounded = false;
        this.raspKeyValid = true;
        this.loadRaspKey = false;
        this.raspKeyUngeeignet = false;
        this.showCheckButton = false;
        this.raspKey = key;
        this.newForm.controls.raspkey.disable();
      }
    }, (err: any) => {
    });
    } else {
      this.raspKeyValid = false;
      this.falseRaspKey = false;
      this.raspKeyChecked = false;
      this.raspKeyBounded = false;
      this.showCheckButton = false;
      this.newForm.get('raspkey').reset();
      this.newForm.updateValueAndValidity();
    }

  }

  raspKeyTouched = () => {
    const key = this.newForm.get('raspkey').value;
    if (key) {
      this.showCheckButton = true;
      this.showCheckHint = true;
    } else {
      this.raspKeyValid = false;
      this.falseRaspKey = false;
      this.raspKeyChecked = false;
      this.raspKeyBounded = false;
      this.showCheckButton = false;
      this.showCheckHint = false;
      this.newForm.get('raspkey').reset();
      this.newForm.updateValueAndValidity();
    }
  }

  falseKeyValidator = () => {
    return (): { [key: string]: boolean | null} => {
      if (this.falseRaspKey) {
        return { falseKey: true };
      } else {
        return null;
      }
    };
  }

  bindedValidator = () => {
    return (): { [key: string]: boolean | null} => {
      if (this.raspKeyBounded) {
        return { binded: true };
      } else {
        return null;
      }
    };
  }

  regiolockValidator = () => {
    return (): { [key: string]: boolean | null} => {
      if (this.raspKeyUngeeignet) {
        return { ungeeignet: true };
      } else {
        return null;
      }
    };
  }

deleteRaspKey = () => {
  this.raspKeyValid = false;
  this.falseRaspKey = false;
  this.raspKeyChecked = false;
  this.raspKeyBounded = false;
  this.showCheckButton = false;
  this.newForm.controls.raspkey.enable();
  this.newForm.get('raspkey').reset();
  this.newForm.updateValueAndValidity();
}

  ngOnInit(): void {
    this.tokenSubscribtion = this.tokenService.currentToken.subscribe(token => this.token = token);
    this.getCodeTables();
    if (this.data.option === 2) {
      if (this.data.formdata.raspkey.length > 0){
        this.raspKeyValid = true;
        this.raspKeyChecked = true;
        this.raspKey = this.data.formdata.raspkey;
      }

    }
    this.newForm = this.fb.group({
    name: [this.data.formdata.name, [Validators.required, Validators.minLength(3)]],
    location: [this.data.formdata.location],
    satelliteid: [this.data.formdata.satelliteid],
    conf_r1: [this.data.formdata.conf_r1],
    conf_r2: [this.data.formdata.conf_r2],
    conf_r3: [this.data.formdata.conf_r3],
    conf_r4: [this.data.formdata.conf_r4],
    tbl_r1: [this.data.formdata.tbl_r1],
    tbl_r2: [this.data.formdata.tbl_r2],
    tbl_r3: [this.data.formdata.tbl_r3],
    tbl_r4: [this.data.formdata.tbl_r4],
    to_r1: [this.data.formdata.to_r1],
    to_r2: [this.data.formdata.to_r2],
    to_r3: [this.data.formdata.to_r3],
    to_r4: [this.data.formdata.to_r4],
    raspkey: [this.data.formdata.raspkey, [this.falseKeyValidator(), this.bindedValidator(), this.regiolockValidator()]],
    active: [this.data.formdata.active]
  });
}

getCodeTables = () => {
  this.api.getActiveCodetablesForLocation(this.data.formdata.location, this.token).subscribe((codetables) => {
    this.codetables = codetables;
  });
}

changeFunction = (timeout:string, relais: string, event: any) => {
  if (event.value === 0) {
    this.newForm.patchValue({[relais]: '0'});
    this.newForm.patchValue({[timeout]: 0});
  } else if (event.value === 1) {
    this.newForm.patchValue({[timeout]: 5});
  }
}

getFunctionFieldValue = (relais: string) => {
  if (this.newForm.controls[relais].value === 0 || this.newForm.controls[relais].value === null) {
    return true;
  } else {
    return false;
  }

}

}

@Component({
  selector: 'dialog-confirm-lock',
  templateUrl: './dialog-confirm.html',
  styleUrls: ['./regiolock.component.css']
})

export class DialogConfirmLock implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DialogConfirmLock>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataConfirm) {}

   confirm(): void {
    this.dialogRef.close(1);
  }

  ngOnInit(): void {
    if (this.data.option === 3) {
    this.dialogRef.close(1);
    }
  }


  cancleDialog(): void {
    this.dialogRef.close(0);
  }
}

@Component({
  selector: 'dialog-codetable',
  templateUrl: 'dialog-codetable.html',
  styleUrls: ['./regiolock.component.css']
})
export class DialogCodetable implements OnInit {
  getValues = new EventEmitter();
  loading = false;
  success = false;
  checked = true;
  token: string;
  tokenSubscribtion: Subscription;
  newForm: FormGroup;
  days: Days[] = [
    {value: 0, viewValue: "Montag"},
    {value: 0, viewValue: "Dienstag"},
    {value: 0, viewValue: "Mittwoch"},
    {value: 0, viewValue: "Donnerstag"},
    {value: 0, viewValue: "Freitag"},
    {value: 0, viewValue: "Samstag"},
    {value: 0, viewValue: "Sonntag"}
  ];


  constructor(
    public dialogRef: MatDialogRef<DialogCodetable>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCode,
    private fb: FormBuilder,
    private api: ApiService,
    private tokenService: TokenService,
    public dialog: MatDialog,
    public datepipe: DatePipe
    ) {}

   onSubmit(): void {
     this.tokenSubscribtion.unsubscribe();
     this.dialogRef.close(this.newForm.value);
    }

  cancleDialog(): void {
    this.tokenSubscribtion.unsubscribe();
    this.dialogRef.close(0);
  }

  public errorHandling = (control: string, error: string) => {
    return this.newForm.controls[control].hasError(error);
  }

  ngOnInit(): void {
    this.tokenSubscribtion = this.tokenService.currentToken.subscribe(token => this.token = token);
    this.createViewValues();

}

createViewValues = () => {
  this.newForm = this.fb.group({
    name: this.fb.control(this.data.formdata.name, [Validators.required, Validators.minLength(3)]),
    location: this.fb.control(this.data.locationid),
    active: this.fb.control(true),
    codes: this.fb.array([])
  });
this.data.formdata.codes.forEach((element) => {

  const returnObject = this.fb.control({
    code: element.code,
    bezeichnung: element.bezeichnung,
    internalId: element.internalId,
    start: element.start,
    end: element.end,
    activetimes: element.activetimes,
    active: element.active
  });
  const codes = this.newForm.get('codes') as FormArray;
  codes.push(returnObject);
});
}

addCode = () => {
  const dialogRef = this.dialog.open(DialogCodetableEdit, {
    width: '40%',
    data: {
      option: 1,
      formdata: {
        code: 0,
        internalId: new Date().getTime(),
        bezeichnung: '',
        start: '',
        end: '',
        activetimes: [],
        active: true
      },
      locationid: this.data.locationid,
    },
    hasBackdrop: true,
    disableClose: true
  });

dialogRef.afterClosed().subscribe(result => {
  if (result !== 0) {
    this.data.formdata.codes.push(result.fv);
    this.createViewValues();
  }
  });
}

editCode = (i) => {
  const dialogRef = this.dialog.open(DialogCodetableEdit, {

    width: '40%',
    data: {
      option: 2,
      formdata: this.data.formdata.codes[i],
      locationid: this.data.locationid,
      arrayid: i
    },
    hasBackdrop: true,
    disableClose: true
  });

dialogRef.afterClosed().subscribe(result => {
  if (result !== 0) {
    const formvalue = result.fv;
    const arrayid = result.arrayid;
    this.data.formdata.codes[arrayid] = formvalue;
    this.createViewValues();
  }
  });
}

getDateValue = (timestamp: string) => {
  if (timestamp) {
    return this.datepipe.transform(timestamp, 'dd.MM.yyyy');
  } else {
    return "Immer";
  }
}

getTimesString = (times: any) => {
  if (times) {
  if (times.length > 0) {
    return "siehe Details";
  } else {
    return "Immer";
  }
  }
}


deleteEntry = (i) => {
  const dialogRef = this.dialog.open(DialogConfirmLock, {
    width: '40%',
    data: {
     text: 'Soll der Code wirklich gelöscht werden?',
     option: 1
      },
      hasBackdrop: true,
      disableClose: true
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result === 1) {
      const codes = this.newForm.get('codes') as FormArray;
      codes.removeAt(i);
    }
  });
}

changeActiveStateCode = (event: any, i: number) => {
  const codes = (this.newForm.get('codes') as FormArray).at(i) as FormGroup;
  codes.value.active = event.checked;
}

accessProtocol = (element: any) => {
  const internalid = element.value.internalId;
  const locationid = this.data.locationid;
  const tableid = this.data.formdata._id;
  this.dialog.open(DialogProtocolRegiolock, {
    width: '60%',
    data: {
        locationid: locationid,
        tableid: tableid,
        internalid: internalid,
        option: 2
    },
    hasBackdrop: true,
    disableClose: true
  });


}
}


@Component({
  selector: 'dialog-timetable',
  templateUrl: 'dialog-timetable.html',
  styleUrls: ['./regiolock.component.css']
})
export class DialogTimetable implements OnInit {
  getValues = new EventEmitter();
  newForm: FormGroup;
  range: FormGroup;
  loading = false;
  success = false;
  checked = true;
  token: string;
  tokenSubscribtion: Subscription;


  constructor(
    public dialogRef: MatDialogRef<DialogTimetable>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataTime,
    private fb: FormBuilder,
    private api: ApiService,
    private tokenService: TokenService) {}

   onSubmit(): void {
     this.tokenSubscribtion.unsubscribe();
     this.dialogRef.close();
    }

  cancleDialog(): void {
    this.tokenSubscribtion.unsubscribe();
    this.dialogRef.close(0);
  }

  public errorHandling = (control: string, error: string) => {
    return this.newForm.controls[control].hasError(error);
  }

  ngOnInit(): void {
    this.tokenSubscribtion = this.tokenService.currentToken.subscribe(token => this.token = token);
    this.newForm = this.fb.group({
    name: [this.data.formdata.name, [Validators.required, Validators.minLength(3)]],
    location: [this.data.formdata.location],
    satelliteid: [this.data.formdata.satelliteid],
    active: [this.data.formdata.active]
  });
  this.range = new FormGroup({
    start: new FormControl('', Validators.required),
    end: new FormControl('', Validators.required)
  });
}
}


@Component({
  selector: 'dialog-codetable-edit',
  templateUrl: 'dialog-codetable-edit.html',
  styleUrls: ['./regiolock.component.css']
})
export class DialogCodetableEdit implements OnInit, AfterContentInit {
  getValues = new EventEmitter();
  loading = false;
  loadCode = false;
  success = false;
  checked = true;
  codeCreated = false;
  token: string;
  tokenSubscribtion: Subscription;
  newForm: FormGroup;
  days: Days[] = [
    {value: 1, viewValue: "Montag"},
    {value: 2, viewValue: "Dienstag"},
    {value: 3, viewValue: "Mittwoch"},
    {value: 4, viewValue: "Donnerstag"},
    {value: 5, viewValue: "Freitag"},
    {value: 6, viewValue: "Samstag"},
    {value: 7, viewValue: "Sonntag"}
  ];


  constructor(
    public dialogRef: MatDialogRef<DialogCodetableEdit>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCodeNewtime,
    private fb: FormBuilder,
    private api: ApiService,
    private tokenService: TokenService,
    public dialog: MatDialog,
    private dateAdapter: DateAdapter<Date>) {
      this.dateAdapter.setLocale('de-DE');
    }


   onSubmit(): void {
     if (this.codeCreated || this.data.option === 2) {
    this.tokenSubscribtion.unsubscribe();
     this.dialogRef.close({fv: this.newForm.value, arrayid: this.data.arrayid});
     }
    }

  cancleDialog(): void {
    this.tokenSubscribtion.unsubscribe();
    this.dialogRef.close(0);
  }

  public errorHandling = (control: string, error: string) => {
    return this.newForm.controls[control].hasError(error);
  }

  public errorHandlingTime = (i: number, control: string, error: string) => {
    return this.newForm.get('activetimes')['controls'][i].get(control).hasError(error);
  }


  ngAfterContentInit(): void {
    if (this.data.option === 1) {
      this.newForm.controls['code'].setValue(0);
      this.calculateNewCode();
    }
}

calculateNewCode = () => {
        // Neuen Code anfordern
        this.loadCode = true;
        this.codeCreated = false;
        this.api.calculateCode(this.data.locationid, this.token).subscribe((returnedCode) => {

          if (returnedCode === 0 || returnedCode === null) {
            this.cancleDialog();
            this.dialog.open(DialogConfirmLock, {
              width: '40%',
              data: {
               text: 'Es konnte kein neuer Code generiert werden',
               option: 1
                },
                hasBackdrop: true,
                disableClose: true
            });

          } else {
            this.newForm.controls['code'].setValue(returnedCode);
            this.codeCreated = true;
          }
          this.loadCode = false;
        });
}

  ngOnInit(): void {
  this.tokenSubscribtion = this.tokenService.currentToken.subscribe(token => this.token = token);
  this.newForm = this.fb.group({
    code: this.fb.control(this.data.formdata.code),
    internalId: this.data.formdata.internalId,
    bezeichnung: this.fb.control(this.data.formdata.bezeichnung, [Validators.required, Validators.minLength(3)]),
    start: this.fb.control(this.data.formdata.start),
    end: this.fb.control(this.data.formdata.end),
    activetimes: this.fb.array([]),
    active:  this.fb.control(this.data.formdata.active)
  });
  const times = this.newForm.get('activetimes') as FormArray;
   if (this.data.option === 2) {
    this.data.formdata.activetimes.forEach((time) => {
      const group = this.fb.group({
        day: this.fb.control(time.day),
        start: this.fb.control(time.start, Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)),
        end: this.fb.control(time.end, Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/))
      });
      times.push(group);
    });
  }
}


addTimeId(): FormGroup {
  return this.fb.group({
    day: this.fb.control([]),
    start: this.fb.control('', Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)),
    end: this.fb.control('', Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/))
  });
}

addTime = () => {
  const times = this.newForm.get('activetimes') as FormArray;
  times.push(this.addTimeId());
}

deleteTime = (i: number) => {
  const dialogRef = this.dialog.open(DialogConfirmLock, {
    width: '40%',
    data: {
     text: 'Soll der Eintrag wirklich gelöscht werden?',
     option: 1
      },
      hasBackdrop: true,
      disableClose: true
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result === 1) {
      const times = this.newForm.get('activetimes') as FormArray;
      times.removeAt(i);
    }
  });

}
}

@Component({
  selector: 'dialog-protocol-regiolock',
  templateUrl: 'dialog-protocol.html',
  styleUrls: ['./regiolock.component.css']
})

export class DialogProtocolRegiolock implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('protocollTable', { read: MatSort, static: false }) protSort: MatSort;
  @ViewChild('protocollPaginator', {static: false}) protPaginator: MatPaginator;
  @ViewChild('protocollTable2', { read: MatSort, static: false }) protSort2: MatSort;
  @ViewChild('protocollPaginator2', {static: false}) protPaginator2: MatPaginator;

  displayedColumnsProt: string[] = ['time', 'type', 'message', 'usedcode', 'bezeichnung'];
  displayedColumnsProt2: string[] = ['time', 'message'];
  protdataSource = new MatTableDataSource();
  loadingProtocoll = false;
  initialLoading = true;
  protocollIntervall: any;
  token: string;
  tokenSubscribtion: Subscription;


  constructor(
    public dialogRef: MatDialogRef<DialogProtocolRegiolock>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataProtocol,
    private api: ApiService,
    private tokenService: TokenService
    ) {}

   confirm(): void {
    this.dialogRef.close(0);
  }

  ngOnDestroy(): void {
    clearInterval(this.protocollIntervall);
    this.tokenSubscribtion.unsubscribe();
  }

  ngOnInit(): void {
    this.tokenSubscribtion = this.tokenService.currentToken.subscribe(token => this.token = token);
      this.getAccessProtocoll();

    }

  ngAfterViewInit(): void {
    this.protdataSource.sort = this.protSort;
    this.protdataSource.paginator = this.protPaginator;
  }

  applyFilterProtocoll = (event: Event) => {
    const filterValue = (event.target as HTMLInputElement).value;
    this.protdataSource.filter = filterValue.trim().toLowerCase();
  }

  getAccessProtocoll = () => {
    this.loadingProtocoll = true;
    if (this.data.option === 1) {

    this.api.getAccessProtocolForLocation(this.data.locationid, this.token)
    .subscribe((res: any) => {
      this.loadingProtocoll = false;
      this.initialLoading = false;
      this.protdataSource.data = res;
      }, (err: any) => {
      });
    } else if (this.data.option === 2) {

      this.api.getAccessProtocolForCode(this.data.locationid, this.data.tableid, this.data.internalid, this.token)
      .subscribe((res: any) => {
        this.loadingProtocoll = false;
        this.initialLoading = false;
        this.protdataSource.data = res;
        }, (err: any) => {
        });

    } else if (this.data.option === 3) {
      this.api.getRegiolockProtocoll(this.data.satelliteid, this.token)
      .subscribe((res: any) => {
        this.loadingProtocoll = false;
        this.initialLoading = false;
        this.protdataSource.data = res;
        }, (err: any) => {
        });
    }
    this.startStatusIntervall();
  }


  startStatusIntervall = () => {
    clearInterval(this.protocollIntervall);
    this.protocollIntervall = setInterval(() => {
      this.getAccessProtocoll();
      }, 10000);
  }


  cancleDialog(): void {
    this.dialogRef.close(0);
  }
}


@Component({
  selector: 'dialog-restart-lock',
  templateUrl: 'dialog-restart.html',
  styleUrls: ['./regiolock.component.css']
})
export class DialogRestartLock implements OnInit {

token: string;
tokenSubscribtion: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DialogRestartLock>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataRestart,
    private api: ApiService,
    public dialog: MatDialog,
    private tokenService: TokenService) {}

  cancleDialog(): void {
    this.tokenSubscribtion.unsubscribe();
    this.dialogRef.close();
  }


  ngOnInit(): void {
    this.tokenSubscribtion = this.tokenService.currentToken.subscribe(token => this.token = token);
    let counter = 0;
    const intervall = setInterval(() => {
      this.api.getSatellite(this.data.id, this.token)
  .subscribe((res: any) => {
    if (res.ack_option) {
      this.dialogRef.close();
      this.dialog.open(DialogConfirmLock, {
        width: '40%',
        data: {
         text: 'Neustart erfolgreich durchgeführt',
         option: 2
          },
          hasBackdrop: true,
          disableClose: true
      });
      clearInterval(intervall);
    } else if (counter > 15) {
      this.dialogRef.close();
      this.dialog.open(DialogConfirmLock, {
        width: '40%',
        data: {
         text: 'Neustart konnte nicht durchgeführt werden',
         option: 2
          },
          hasBackdrop: true,
          disableClose: true
      });
      clearInterval(intervall);
    }
    }, (err: any) => {
      clearInterval(intervall);
    });
      counter++;
    }, 1000);

}
}
