<div>
<h2 *ngIf="data.action == 1" mat-dialog-title>Neue Konfiguration</h2>
<h2 *ngIf="data.action == 2" mat-dialog-title>Konfiguration bearbeiten</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="newForm" (ngSubmit)="onSubmit()">
    <table width="100%">
      <tbody>

        <tr>
          <td>
    <mat-form-field [style.width.%]="90">
      <mat-label>Bezeichnung der Konfiguration *</mat-label>
      <input matInput formControlName="name" placeholder="z.B. Standard">
      <mat-error *ngIf="errorHandling('name', 'required') || errorHandling('name', 'minlength')">
       Mindestens 3 Zeichen erforderlich!
      </mat-error>
        </mat-form-field></td>
        <td>
                  <!-- Aktiv? -->
          <mat-checkbox formControlName="active">Aktiv?</mat-checkbox>
          </td>
  </tr>
  <tr>
    <td>
    <table class="configTable">
      <tr>
        <th>ID</th>
        <th>Fach</th>
        <th>Löschen</th>
      </tr>
    </table>

    <table class="configTable" formArrayName="productIdObject" *ngFor="let product of newForm.get('productIdObject')['controls']; let i = index;">
      <tr  [formGroupName]="i">
        <td class="configTableLeft"><input matInput formControlName="id" class="configTableInput"></td>
        <td class="configTableRight"><input matInput formControlName="shelf" class="configTableInput"></td>
        <td class="configTableRight"><mat-icon class="actionDelete" (click)="deleteEntry(i)">delete</mat-icon></td>
      </tr>
    </table>
    <a mat-mini-fab color="primary" (click)="addProduct()"><mat-icon>plus_one</mat-icon></a>
  </td>
<td> </td>
  </tr>

      <tr>
    <td colspan="2"><button mat-raised-button color="primary" [disabled]="!newForm.valid" type="submit" mat-dialog-close class="dialogButton">Speichern</button><button mat-raised-button class="dialogButton" color="warn" mat-dialog-close (click)="cancleDialog()">Abbrechen</button></td>
  </tr>
  </tbody>
  </table>
  </form>
</mat-dialog-content>
</div>
