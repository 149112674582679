import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../api.service';
import { Client, User } from '../schemas';
import { TokenService } from '../token.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  @Input() currentClient: Client;
  @Input() loggedInUser: User;
  @ViewChild('clientsTable', { read: MatSort, static: true }) proSort: MatSort;
  @ViewChild('clientsPaginator', {static: true}) proPaginator: MatPaginator;
  token: string;
  displayedColumns: string[] = ['name', 'location', 'active', 'actions'];
  dataSource = new MatTableDataSource();
  loadingClients = false;

  constructor(private api: ApiService, public dialog: MatDialog, private tokenService: TokenService) { }

  ngOnInit(): void {
    this.tokenService.currentToken.subscribe(token => this.token = token);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.proSort;
    this.dataSource.paginator = this.proPaginator;
  }

  applyFilter = (event: Event) => {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  newClient = () => {

  }

}
