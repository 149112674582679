import { Subscription } from 'rxjs';
import { TokenService } from './../token.service';
import { MachineType, Manufacturer, MachineConfig, Satellite } from './../schemas';
import { Component, Input, OnInit, ViewChild, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../api.service';
import { Client, User } from '../schemas';
import { MatPaginator } from '@angular/material/paginator';

export interface DialogData {
  action: number;
  formdata: Manufacturer;
}

export interface DialogDataModels {
  action: number;
  formdata: MachineType;
  manufacturers: any;
  evatype: number;
  configurations: any;
}

export interface DialogDataConfig {
  action: number;
  formdata: MachineConfig;
}

export interface DialogDataConfirm {
  text: string;
  option: number;
}

export interface DialogDataSatellite {
  action: number;
  formdata: Satellite;
}

export interface DialogDataRestart {
  id: string;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() currentClient: Client;
  @Input() loggedInUser: User;
  @ViewChild('manufTable', { read: MatSort, static: true }) manuSort: MatSort;
  @ViewChild('typesTable', { read: MatSort, static: true }) typesSort: MatSort;
  @ViewChild('configTable', { read: MatSort, static: true }) configSort: MatSort;
  @ViewChild('satelliteTable', { read: MatSort, static: true }) satSort: MatSort;
  @ViewChild('manuPaginator', {static: true}) manuPaginator: MatPaginator;
  @ViewChild('typePaginator', {static: true}) typePaginator: MatPaginator;
  @ViewChild('configPaginator', {static: true}) configPaginator: MatPaginator;
  @ViewChild('satPaginator', {static: true}) satPaginator: MatPaginator;

  token: string;
  tokenSubscribtion: Subscription;
  displayedManuColumns: string[] = ['name', 'active', 'actions'];
  displayedModelColumns: string[] = ['name', 'manufacturer', 'config', 'active', 'actions'];
  displayedConfigColumns: string[] = ['name', 'active', 'actions'];
  displayedConfigObjectColumns: string[] = [];
  displayedSatellitesColumns: string[] = ['raspkey', 'internalid', 'active', 'regiolock', 'status', 'actions'];
  manufSource = new MatTableDataSource();
  modelSource = new MatTableDataSource();
  configSource = new MatTableDataSource();
  satelliteSource = new MatTableDataSource();
  satellitesIntervall: any;
  loadingManufacturers = false;
  loadingModels = false;
  loadingConfig = false;
  loadingSatellites = false;
  manufacturers = [];
  configurations = [];

  constructor(private api: ApiService, public dialog: MatDialog, private tokenService: TokenService) { }

  ngOnInit(): void {
    this.tokenSubscribtion = this.tokenService.currentToken.subscribe(token => this.token = token);
    this.getModels();
    this.getSatellites();
    this.satellitesInterval();
  }

  ngOnDestroy(): void {
    clearInterval(this.satellitesIntervall);
    this.tokenSubscribtion.unsubscribe();
  }

  // tslint:disable-next-line: typedef
  ngAfterViewInit() {
    this.manufSource.sort = this.manuSort;
    this.manufSource.paginator = this.manuPaginator;
    this.configSource.sort = this.configSort;
    this.configSource.paginator = this.configPaginator;
    this.modelSource.sort = this.typesSort;
    this.modelSource.paginator = this.typePaginator;
    this.satelliteSource.sort = this.satSort;
    this.satelliteSource.paginator = this.satPaginator;
  }

applyFilter = (event: Event) => {
  const filterValue = (event.target as HTMLInputElement).value;
  this.manufSource.filter = filterValue.trim().toLowerCase();
  if (this.manufSource.paginator) {
    this.manufSource.paginator.firstPage();
  }
}

applyTypeFilter = (event: Event) => {
  const filterValue = (event.target as HTMLInputElement).value;
  this.modelSource.filter = filterValue.trim().toLowerCase();
}

applyConfigFilter = (event: Event) => {
  const filterValue = (event.target as HTMLInputElement).value;
  this.configSource.filter = filterValue.trim().toLowerCase();
}

applySatelliteFilter = (event: Event) => {
  const filterValue = (event.target as HTMLInputElement).value;
  this.satelliteSource.filter = filterValue.trim().toLowerCase();
}


newManufacturer = () => {
  const dialogRef = this.dialog.open(DialogManufacturer, {
    width: '40%',
    data: {
      action: 1,
      formdata: {
        name: '',
        active: true
      }
    },
    hasBackdrop: true,
    disableClose: true
  });

  dialogRef.afterClosed().subscribe(result => {
  if (result !== 0) {
    this.api.addManufacturer(result, this.token)
    .subscribe((res: any) => {
        this.getManufacturers();
      }, (err: any) => {
      });
  }
  });
}

updateManufacturer = (man: Manufacturer) => {
  const dialogRef = this.dialog.open(DialogManufacturer, {
    width: '40%',
    data: {
      action: 2,
      formdata: man
    },
    hasBackdrop: true,
    disableClose: true
  });

  dialogRef.afterClosed().subscribe(result => {
  if (result !== 0) {
    this.loadingManufacturers = true;
    this.api.updateManufacturers(man._id, result, this.token)
  .subscribe((res: any) => {
    this.getManufacturers();
    }, (err: any) => {
    });
  }
  });
}

updateMachineType = (type: MachineType) => {
  const dialogRef = this.dialog.open(DialogMachinetypes, {
    width: '40%',
    data: {
      action: 2,
      formdata: type,
      manufacturers: this.manufacturers,
      configurations: this.configurations
    },
    hasBackdrop: true,
    disableClose: true
  });

  dialogRef.afterClosed().subscribe(result => {
  if (result !== 0) {
    this.loadingModels = true;
    this.api.updateMachineType(type._id, result, this.token)
  .subscribe((res: any) => {
    this.getModels();
    }, (err: any) => {
    });
  }
  });
}

updateMachineConfig = (config: MachineConfig) => {
  const dialogRef = this.dialog.open(DialogConfiguration, {
    width: '40%',
    data: {
      action: 2,
      formdata: config
    },
    hasBackdrop: true,
    disableClose: true
  });

  dialogRef.afterClosed().subscribe(result => {
  if (result !== 0) {
    this.loadingModels = true;
    this.api.updateMachineConfig(config._id, result, this.token)
  .subscribe((res: any) => {
    this.getModels();
    }, (err: any) => {
    });
  }
  });
}

updateSatellite = (sat: Satellite) => {
  const dialogRef = this.dialog.open(DialogSatellite, {
    width: '40%',
    data: {
      action: 2,
      formdata: sat
    },
    hasBackdrop: true,
    disableClose: true
  });

  dialogRef.afterClosed().subscribe(result => {
  if (result !== 0) {
    this.loadingSatellites = true;
    this.api.updateSatellite(sat._id, result, this.token)
  .subscribe((res: any) => {
    this.getSatellites();
    }, (err: any) => {
    });
  }
  });
}

deleteManufacturer = (id: string) => {
  const dialogRef = this.dialog.open(DialogConfirm, {
    width: '40%',
    data: {
     text: 'Soll der Hersteller wirklich gelöscht werden?',
     option: 1
      },
      hasBackdrop: true,
      disableClose: true
  });

  dialogRef.afterClosed().subscribe(result => {
  if (result === 1) {
    this.api.deleteManufacturer(id, this.token)
    .subscribe((res: any) => {
      this.getModels();
      }, (err: any) => {
      });
    }
  });
}

deleteMachineType = (id: string) => {
  const dialogRef = this.dialog.open(DialogConfirm, {
    width: '40%',
    data: {
     text: 'Soll das Modell wirklich gelöscht werden?',
     option: 1
      },
      hasBackdrop: true,
      disableClose: true
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result === 1) {
      this.api.deleteMachineType(id, this.token)
      .subscribe((res: any) => {
        this.getModels();
        }, (err: any) => {
        });
      }
    });
}

deleteMachineConfig = (id: string) => {
  const dialogRef = this.dialog.open(DialogConfirm, {
    width: '40%',
    data: {
     text: 'Soll die Konfiguration wirklich gelöscht werden?',
     option: 1
      },
      hasBackdrop: true,
      disableClose: true
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result === 1) {
      this.api.deleteMachineConfig(id, this.token)
      .subscribe((res: any) => {
        this.getModels();
        }, (err: any) => {
        });
      }
    });
}

deleteSatellite = (id: string) => {
  const dialogRef = this.dialog.open(DialogConfirm, {
    width: '40%',
    data: {
     text: 'Soll das RegioConnect / RegioLock Gerät wirklich gelöscht werden?',
     option: 1
      },
      hasBackdrop: true,
      disableClose: true
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result === 1) {
      this.api.deleteSatellite(id, this.token)
      .subscribe((res: any) => {
        this.getSatellites();
        }, (err: any) => {
        });
      }
    });
}

changeActiveState = (evt: any, element: Manufacturer) => {
  element.active = evt.checked;
  this.api.updateManufacturers(element._id, element, this.token)
  .subscribe((res: any) => {
    this.getManufacturers();
    }, (err: any) => {
    });
}

changeActiveStateTypes = (evt: any, element: MachineType) => {
  element.active = evt.checked;
  this.api.updateMachineType(element._id, element, this.token)
  .subscribe((res: any) => {
    this.getModels();
    }, (err: any) => {
    });
}

changeActiveStateConfig = (evt: any, element: MachineConfig) => {
  element.active = evt.checked;
  this.api.updateMachineConfig(element._id, element, this.token)
  .subscribe((res: any) => {
    this.getConfigs();
    }, (err: any) => {
    });
}

changeActiveStateSatellite = (evt: any, element: Satellite) => {
  element.active = evt.checked;
  this.api.updateSatellite(element._id, element, this.token)
  .subscribe((res: any) => {
    this.getSatellites();
    }, (err: any) => {
    });
}


newMachineType = () => {
  const dialogRef = this.dialog.open(DialogMachinetypes, {
    width: '40%',
    data: {
      action: 1,
      formdata: {
        name: '',
        manufacturer: 0,
        configuration: 0,
        active: true
      },
      manufacturers: this.manufacturers,
      configurations: this.configurations
    },
    hasBackdrop: true,
    disableClose: true
  });

  dialogRef.afterClosed().subscribe(result => {
  if (result !== 0) {
    this.api.addMachineType(result, this.token)
    .subscribe((res: any) => {
        this.getModels();
      }, (err: any) => {
      });
  }
  });
}

newConfig = () => {
  const dialogRef = this.dialog.open(DialogConfiguration, {
    width: '40%',
    data: {
      action: 1,
      formdata: {
        name: '',
        manufacturer: 0,
        configuration: 0,
        active: true
      }
    },
    hasBackdrop: true,
    disableClose: true
  });

  dialogRef.afterClosed().subscribe(result => {
  if (result !== 0) {
    this.api.addMachineConfig(result, this.token)
    .subscribe((res: any) => {
        this.getModels();
      }, (err: any) => {
      });
  }
  });
}

newSatellite = () => {
  const dialogRef = this.dialog.open(DialogSatellite, {
    width: '40%',
    data: {
      action: 1,
      formdata: {
        raspkey: '',
        wlan_mac: '',
        eth_mac: '',
        flag_stat1: false,
        option: 0,
        mode: 0,
        active: true,
        available: false,
        regiolock: false
      }
    },
    hasBackdrop: true,
    disableClose: true
  });

  dialogRef.afterClosed().subscribe(result => {
  if (result !== 0) {
    this.api.addSatellite(result, this.token)
    .subscribe((res: any) => {
        this.getSatellites();
      }, (err: any) => {
      });
  }
  });
}

getManufacturers = () => {
  return new Promise<void>((resolve) => {
    this.loadingManufacturers = true;
    this.api.getManufacturers(this.token)
    .subscribe((res: any) => {
        this.manufSource.data = res;
        this.manufacturers = res;
        this.loadingManufacturers = false;
        resolve();
      }, (err: any) => {
      });
  });
}

getModels = () => {
  this.getManufacturers().then(() => {
    this.getConfigs().then(() => {
  this.loadingModels = true;
  this.api.getMachineTypes(this.token)
  .subscribe((res: any) => {
      this.modelSource.data = res;
      this.loadingModels = false;
    }, (err: any) => {
    });
  });
});
}

getConfigs = () => {
  return new Promise<void>((resolve) => {
  this.loadingConfig = true;
  this.api.getConfigs(this.token)
  .subscribe((res: any) => {
      this.configSource.data = res;
      this.configurations = res;
      this.loadingConfig = false;
      resolve();
    }, (err: any) => {
    });
  });
}

getSatellites = () => {
  this.loadingSatellites = true;
  this.api.getSatellites(this.token)
  .subscribe((res: any) => {
      this.satelliteSource.data = res;
      this.loadingSatellites = false;
    }, (err: any) => {
    });
}

satellitesInterval = () => {
  this.api.getSatellites(this.token)
  .subscribe((res: any) => {
      this.satelliteSource.data = res;
      this.startSatellitesInterval();
    }, (err: any) => {
    });

}

startSatellitesInterval = () => {
  clearInterval(this.satellitesIntervall);
  this.satellitesIntervall = setInterval(() => {
    this.api.getSatellites(this.token)
    .subscribe((res: any) => {
        this.satelliteSource.data = res;
      }, (err: any) => {
      });
    }, 9000);
}


getManufacturerName = (id: string) => {
  return this.manufacturers.find(t => t._id === id).name;
}
getConfigName = (id: string) => {
  return this.configurations.find(t => t._id === id).name;
}

getSatelliteStatus = (element: Satellite) => {
  if (element.flag_stat1 && element.active) {
    const currentTime = new Date().getTime();
    const lastUpdateTime = new Date(element.tsStatus).getTime();
    const diff = currentTime - lastUpdateTime;
    const difference = Math.floor(diff / 1000 % 60);
    if (difference > 20) {
      return 3;
    } else {
      return 1;
    }
  } else if (!element.flag_stat1 && element.active) {
    return 2;
  }
}

unbindSatellite = (element: Satellite) => {
  const dialogRef = this.dialog.open(DialogConfirm, {
    width: '40%',
    data: {
     text: 'Soll das Gerät wirklich entbunden werden?',
     option: 1
      },
      hasBackdrop: true,
      disableClose: true
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result === 1) {
      const values = {
        flag_stat1: false,
        option: 0,
        ack_option: false,
        curr_location: '',
        curr_machine: '',
        available: false,
        int_ip: '',
        ext_ip: ''
      };
      this.api.updateSatellite(element._id, values, this.token)
      .subscribe((res: any) => {
        // Bindung auch beim Automaten aufheben
        if (element.curr_machine) {
          this.api.getMachine(element.curr_machine, this.token)
          .subscribe((resM: any) => {
            if (resM) {
              resM.raspkey = '';
              this.api.updateMachine(resM._id, resM, this.token)
              .subscribe(() => {
              });
            }
          });
        }
        this.getSatellites();
        this.dialog.open(DialogConfirm, {
          width: '40%',
          data: {
           text: 'Bindung aufgehoben. Das Gerät muss für eine erneute Bindung manuell neu gestartet werden (Aus- und Einstecken).',
           option: 2
            },
            hasBackdrop: true,
            disableClose: true
        });
        }, (err: any) => {
        });

      }
    });
}

restartSatellite = (element: Satellite) => {
  const dialogRef = this.dialog.open(DialogConfirm, {
    width: '40%',
    data: {
     text: 'Soll das Gerät wirklich neu gestartet werden?',
     option: 1
      },
      hasBackdrop: true,
      disableClose: true
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result === 1) {
      const values = {
        option: 1,
        ack_option: false
      };
      this.api.updateSatellite(element._id, values, this.token)
      .subscribe((res: any) => {
        this.dialog.open(DialogRestart, {
          width: '40%',
          data: {
           id: element._id
            },
            hasBackdrop: true,
            disableClose: true
        });
        }, (err: any) => {
        });

      }
    });
}
}

@Component({
  selector: 'dialog-manufacturer',
  templateUrl: 'dialog-manufacturer.html',
})
export class DialogManufacturer implements OnInit {
  newForm: FormGroup;
  loading = false;
  success = false;

  constructor(
    public dialogRef: MatDialogRef<DialogManufacturer>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private fb: FormBuilder) {}

   onSubmit(): void {
    this.dialogRef.close(this.newForm.value);
  }

  cancleDialog(): void {
    this.dialogRef.close(0);
  }

  public errorHandling = (control: string, error: string) => {
    return this.newForm.controls[control].hasError(error);
  }


  ngOnInit(): void {
    this.newForm = this.fb.group({
    name: [this.data.formdata.name, [Validators.required, Validators.minLength(3)]],
    active: [this.data.formdata.active]
  });
}
}

@Component({
  selector: 'dialog-machinetypes',
  templateUrl: 'dialog-machinetypes.html',
})
export class DialogMachinetypes implements OnInit {
  newForm: FormGroup;
  loading = false;
  success = false;
  manufacturers: any;
  configurations: any;

  constructor(
    public dialogRef: MatDialogRef<DialogMachinetypes>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataModels, private fb: FormBuilder) {}

   onSubmit(): void {
    this.dialogRef.close(this.newForm.value);
  }

  cancleDialog(): void {
    this.dialogRef.close(0);
  }

  public errorHandling = (control: string, error: string) => {
    return this.newForm.controls[control].hasError(error);
  }


  ngOnInit(): void {
    this.manufacturers = this.data.manufacturers;
    this.configurations = this.data.configurations;
    this.newForm = this.fb.group({
    name: [this.data.formdata.name, [Validators.required, Validators.minLength(3)]],
    manufacturer: [this.data.formdata.manufacturer, [Validators.required, Validators.pattern('^.{2,}')]],
    evatype: [this.data.formdata.evatype, [Validators.required]],
    configuration: [this.data.formdata.configuration, [Validators.required, Validators.pattern('^.{2,}')]],
    active: [this.data.formdata.active]
  });
}
}

@Component({
  selector: 'dialog-configurations',
  templateUrl: 'dialog-configuration.html',
  styleUrls: ['./admin.component.css']
})
export class DialogConfiguration implements OnInit {
  newForm: FormGroup;
  loading = false;
  success = false;
  displayedConfigObjectColumns = [];
  configTable: FormGroup;
  productIdObject: FormArray;
  dataSource = new MatTableDataSource();

  constructor(
    public dialogRef: MatDialogRef<DialogConfiguration>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataConfig, private fb: FormBuilder) {}

   onSubmit(): void {
    this.dialogRef.close(this.newForm.value);
  }

  cancleDialog(): void {
    this.dialogRef.close(0);
  }

  public errorHandling = (control: string, error: string) => {
    return this.newForm.controls[control].hasError(error);
  }

  addProductId(): FormGroup {
    return this.fb.group({
      id: '',
      shelf: ''
    });
  }

  addAllProducts = () => {
    this.productIdObject = this.newForm.get('productIdObject') as FormArray;
    this.data.formdata.productIdObject.forEach((configObject) => {
      this.productIdObject.push(
        this.fb.group({
          id: configObject.id,
          shelf: configObject.shelf
        })
      );
    });
  }

  addProduct = () => {
    this.productIdObject = this.newForm.get('productIdObject') as FormArray;
    this.productIdObject.push(this.addProductId());
  }

  deleteEntry = (id: number) => {
    this.productIdObject.removeAt(id);
  }

  ngOnInit(): void {
    if (this.data.action === 1) {
    this.newForm = this.fb.group({
    name: [this.data.formdata.name, [Validators.required, Validators.minLength(3)]],
    active: [this.data.formdata.active],
    productIdObject: this.fb.array([ this.addProductId() ])
  });
} else {
  this.newForm = this.fb.group({
    name: [this.data.formdata.name, [Validators.required, Validators.minLength(3)]],
    active: [this.data.formdata.active],
    productIdObject: this.fb.array([])
});
  this.addAllProducts();
}
}
}


@Component({
  selector: 'dialog-confirm',
  templateUrl: 'dialog-confirm.html',
  styleUrls: ['./admin.component.css']
})
export class DialogConfirm implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DialogConfirm>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataConfirm, private fb: FormBuilder) {}

   confirm(): void {
    this.dialogRef.close(1);
  }

  cancleDialog(): void {
    this.dialogRef.close(0);
  }


  ngOnInit(): void {

}
}

@Component({
  selector: 'dialog-satellite',
  templateUrl: 'dialog-satellite.html',
  styleUrls: ['./admin.component.css']
})
export class DialogSatellite implements OnInit {
  newForm: FormGroup;
  loading = false;
  success = false;
  dataSource = new MatTableDataSource();

  constructor(
    public dialogRef: MatDialogRef<DialogSatellite>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataSatellite, private fb: FormBuilder) {}

   onSubmit(): void {
    this.dialogRef.close(this.newForm.value);
  }

  cancleDialog(): void {
    this.dialogRef.close(0);
  }

  public errorHandling = (control: string, error: string) => {
    return this.newForm.controls[control].hasError(error);
  }

  createKey = () => {
    const key = Math.random().toString(36).substr(2, 6);
    this.newForm.patchValue({
      raspkey: key
   });

  }


  ngOnInit(): void {
    this.newForm = this.fb.group({
      raspkey: [this.data.formdata.raspkey, [Validators.required]],
      wlan_mac: [this.data.formdata.wlan_mac, [Validators.required, Validators.pattern('^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})')]],
      eth_mac: [this.data.formdata.eth_mac, [Validators.required, Validators.pattern('^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})')]],
      flag_stat1: [this.data.formdata.flag_stat1],
      option: [this.data.formdata.option],
      active: [this.data.formdata.active],
      mode: [this.data.formdata.mode],
      available: [this.data.formdata.available],
      regiolock: [this.data.formdata.regiolock]
    });
}
}

@Component({
  selector: 'dialog-restart',
  templateUrl: 'dialog-restart.html',
  styleUrls: ['./admin.component.css']
})
export class DialogRestart implements OnInit {

  token: string;
  tokenSubscribtion: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DialogRestart>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataRestart,
    private api: ApiService,
    public dialog: MatDialog,
    private tokenService: TokenService) {}

  cancleDialog(): void {
    this.tokenSubscribtion.unsubscribe();
    this.dialogRef.close();
  }


  ngOnInit(): void {
    this.tokenSubscribtion = this.tokenService.currentToken.subscribe(token => this.token = token);
    let counter = 0;
    const intervall = setInterval(() => {
      this.api.getSatellite(this.data.id, this.token)
  .subscribe((res: any) => {
    if (res.ack_option) {
      this.dialogRef.close();
      const dialogRefC = this.dialog.open(DialogConfirm, {
        width: '40%',
        data: {
         text: 'Neustart erfolgreich durchgeführt',
         option: 2
          },
          hasBackdrop: true,
          disableClose: true
      });
      clearInterval(intervall);
    } else if (counter > 15) {
      this.dialogRef.close();
      const dialogRefC = this.dialog.open(DialogConfirm, {
        width: '40%',
        data: {
         text: 'Neustart konnte nicht durchgeführt werden',
         option: 2
          },
          hasBackdrop: true,
          disableClose: true
      });
      clearInterval(intervall);
    }
    }, (err: any) => {
      clearInterval(intervall);
    });
      counter++;
    }, 1000);

}
}
