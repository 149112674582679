<h2 mat-dialog-title *ngIf="data.option == 1">Neues Produkt</h2>
<h2 mat-dialog-title *ngIf="data.option == 2">Produkt bearbeiten</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="newForm" (ngSubmit)="onSubmit()">
    <table width="100%">
      <tbody>

        <!-- Produktname -->
        <tr>
          <td colspan="3">
    <mat-form-field [style.width.%]="80">
      <mat-label>Name des Produkts *</mat-label>
      <input matInput formControlName="name">
      <mat-error *ngIf="errorHandling('name', 'required') || errorHandling('name', 'minlength')">
       Mindestens 3 Zeichen erforderlich!
      </mat-error>
        </mat-form-field></td>
  </tr>
  <tr>
    <!-- Beschreibung -->
    <td colspan="3">
      <mat-form-field [style.width.%]="80">
        <mat-label>Beschreibung</mat-label>
        <textarea matInput formControlName="description" rows="3"></textarea>
          </mat-form-field></td>
</tr>
        <!-- Zulieferer -->
        <tr>
          <td>
            <mat-form-field [style.width.%]="80">
              <mat-label>Lieferant *</mat-label>
              <mat-select formControlName="supplier">
                <mat-option *ngFor="let supplier of suppliers" [value]="supplier._id">
                  {{supplier.name}}
                </mat-option>
              </mat-select>
              <mat-hint>Wenn Lieferant Zugriff auf Verkaufszahlen haben soll</mat-hint>
              <mat-error *ngIf="errorHandling('supplier', 'required')">
                Erforderlich!
               </mat-error>
            </mat-form-field>
          </td>
          <td>
            <mat-checkbox formControlName="active">Aktiv?</mat-checkbox>
          </td>
          <td>
            <mat-checkbox formControlName="deposit">Pfand?</mat-checkbox>
          </td>
  </tr>
<tr>
  <!-- Preise -->
  <td colspan="3">
    <mat-form-field [style.width.%]="30" class="priceField">
      <mat-label>Einkaufspreis *</mat-label>
      <span matPrefix>€ &nbsp;</span>
      <input matInput formControlName="ek" placeholder="z.B. 3,50">
      <mat-error *ngIf="errorHandling('ek', 'required') || errorHandling('ek', 'pattern')">
       Falsches Format
      </mat-error>
        </mat-form-field>
        <mat-form-field [style.width.%]="30" class="priceField">
          <mat-label>Verkaufspreis *</mat-label>
          <span matPrefix>€ &nbsp;</span>
          <input matInput formControlName="vk" placeholder="z.B. 3,50">
          <mat-error *ngIf="errorHandling('vk', 'required') || errorHandling('vk', 'pattern')">
           Falsches Format
          </mat-error>
            </mat-form-field>
            <mat-form-field [style.width.%]="30">
              <mat-label>Steuersatz *</mat-label>
              <mat-select formControlName="taxrate">
                <mat-option [value]="10">10%</mat-option>
                <mat-option [value]="20">20%</mat-option>
              </mat-select>
              <mat-error *ngIf="errorHandling('taxrate', 'required')">
                Erforderlich!
               </mat-error>
            </mat-form-field>

      </td>
</tr>
<!-- Bilder
<tr>
  <td colspan="3">
    <mat-form-field [style.width.%]="80">
      <mat-label>Bilder (In Kürze verfügbar!)</mat-label>
      <input matInput formControlName="pictures" readonly>
        </mat-form-field>
</td>
</tr>
 Auswahl des Warenfachs
<tr class="headConfigTable">
  <td colspan="2">An Automaten zuweisen</td>
</tr>
<tr>
  <td colspan="2">
    <table class="configTable">
      <tr>
        <th>Standort >></th>
        <th>Automat >></th>
        <th>Fach</th>
        <th class="configTableThRight">Löschen</th>
      </tr>
    </table>
    <table class="configTable" formArrayName="shelfs" *ngFor="let product of newForm.get('shelfs')['controls']; let i = index;">
      <tr  [formGroupName]="i">
        <td class="configTableLeft">
          <mat-form-field [style.width.%]="80">
            <mat-select formControlName="location" class="configTableInput" [disabled]="disableLocationsField(i)">
              <mat-option [value]=0>---</mat-option>
              <mat-option *ngFor="let location of locations" [value]="location._id">
                {{location.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td class="configTableLeft">
          <mat-form-field *ngIf="newForm.get('shelfs')['controls'][i].controls.location.value.length > 0" [style.width.%]="80">
            <mat-select formControlName="machine" class="configTableInput" [disabled]="disableMachineField(i)">
              <mat-option *ngFor="let machine of getMachines(newForm.get('shelfs')['controls'][i].controls.location.value)" [value]="machine._id">
               {{machine.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td class="configTableLeft">
          <mat-form-field *ngIf="newForm.get('shelfs')['controls'][i].controls.machine.value.length > 1" [style.width.%]="80">
            <mat-select formControlName="shelf" class="configTableInput" multiple (ngModelChange)="changedValues(i, $event)">
              <mat-option *ngFor="let shelfR of getShelfs(newForm.get('shelfs')['controls'][i].controls.machine.value)" [value]="shelfR.shelf" [disabled]="!shelfR.active" [ngClass]="{'reserveSelected':shelfR.product.length > 0}">
                {{shelfR.shelf}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>

        <td class="configTableRight"><mat-icon class="actionDelete" (click)="deleteEntry(i)">delete</mat-icon></td>
      </tr>
    </table>
    <a mat-mini-fab color="primary" (click)="addProduct()" class="addRowButton"><mat-icon>plus_one</mat-icon></a>
</td>
</tr> -->

      <tr>
    <td><button mat-raised-button color="primary" [disabled]="!newForm.valid" type="submit" mat-dialog-close>Speichern</button></td>
    <td><button mat-raised-button color="warn" mat-dialog-close (click)="cancleDialog()">Abbrechen</button></td>
  </tr>
  </tbody>
  </table>
  </form>
</mat-dialog-content>

