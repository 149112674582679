<div>
  <div id="loading" *ngIf="initialLoading">
    <mat-spinner></mat-spinner>
  </div>
<h2 mat-dialog-title *ngIf="data.option==1">Statusprotokoll</h2>
<h2 mat-dialog-title *ngIf="data.option==2">Verkaufsprotokoll</h2>
<mat-dialog-content class="mat-typography">

  <mat-progress-bar mode="indeterminate" *ngIf="loadingProtocoll"></mat-progress-bar>
  <div *ngIf="data.option==1">
    <div class="filterField">
      <mat-form-field style="width: 100%">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilterProtocoll($event)" #input>
      </mat-form-field>
    </div>
  <table mat-table matSort [dataSource]="protdataSource" class="mat-elevation-z8 machinetable" #protocollTable="matSort">
      <!-- Time Column -->
      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Zeitpunkt </th>
        <td mat-cell *matCellDef="let element"> {{element.time | date:'dd.MM.yyyy HH:mm:ss'}} </td>
      </ng-container>
            <!-- Typ Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Typ </th>
              <td mat-cell *matCellDef="let element"> {{element.statustype}} </td>
            </ng-container>
          <!-- Gerät Column -->
          <ng-container matColumnDef="device">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Gerät </th>
            <td mat-cell *matCellDef="let element"> {{element.device}} </td>
          </ng-container>
      <!-- Gerät Column -->
      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nachricht </th>
        <td mat-cell *matCellDef="let element"> {{element.message}} </td>
      </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsProt"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsProt;"></tr>

  </table>
  <mat-paginator #protocollPaginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>

<div *ngIf="data.option==2">
  <div class="filterField">
    <mat-form-field style="width: 100%">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilterSell($event)" #input>
    </mat-form-field>
  </div>
<table mat-table matSort [dataSource]="selldataSource" class="mat-elevation-z8 machinetable" #sellTable="matSort">
    <!-- Time Column -->
    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Zeitpunkt </th>
      <td mat-cell *matCellDef="let element"> {{element.time | date:'dd.MM.yyyy HH:mm:ss'}} </td>
    </ng-container>
          <!-- Produkt Column -->
          <ng-container matColumnDef="product">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Produkt </th>
            <td mat-cell *matCellDef="let element"> {{getProductName(element.productId)}} </td>
          </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumnsSel"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnsSel;"></tr>

</table>
<mat-paginator #sellPaginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>


<p><button mat-raised-button color="primary" mat-dialog-close class="dialogButton" (click)="confirm()">Schließen</button></p>
</mat-dialog-content>
</div>
