import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-impressum',
  template: '',
  styleUrls: ['./impressum.component.css']
})
export class ImpressumComponent implements OnInit {
  @Output() closeImpressum = new EventEmitter<boolean>();


  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    const dialogRef = this.dialog.open(DialogImpressum, {
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.closeImpressum.emit(true);
    });
  }
}

@Component({
  selector: 'dialog-impressum',
  templateUrl: 'dialog-impressum.html',
})
export class DialogImpressum {}
