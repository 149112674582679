<h2 mat-dialog-title>Impressum</h2>

<mat-dialog-content class="mat-typography">
<p>Regio.Team GmbH</p>
<p><b>Unternehmensgegenstand: </b><br>Handelsgewerbe mit Ausnahme der reglementierten Handelsgewerbe</p>
<p>
  <b>UID: </b>ATU7608658<br>
  <b>FN: </b>543050d<br>
  <b>Firmenbuchgericht: </b>Landesgericht Salzburg
</p>
<p>
  <b>Firmensitz:</b><br>
  5700 Zell am See
  Seespitzstraße 8
</p>
<p>
  <b>Kontaktdaten:</b><br>
  Mail: office@regio.team<br>
  Tel.: 0660 372 89 32
</p>
<p>Mitglied der WKÖ, WKS, Sparte Handel</p>
<p><b>Berufsrecht: </b>Gewerbeordnung: www.ris.bka.gv.at</p>
<p><b>Aufsichtsbehörde / Gewerbebehörde: </b>Bezirkshauptmannschaft Zell am See</p>
<p><b>Online-Streitbeilegung</b><br>Verbraucher haben die Möglichkeit, Beschwerden an die Online-Streitbeilegungsplattform der EU zu richten: http://ec.europa.eu/odr.<br>
  Sie können allfällige Beschwerde auch an die oben angegebene E-Mail-Adresse richten.</p>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>OK</button>
</mat-dialog-actions>
