<div>
<h2 *ngIf="data.action == 1" mat-dialog-title>Neues Gerät</h2>
<h2 *ngIf="data.action == 2" mat-dialog-title>Gerät bearbeiten</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="newForm" (ngSubmit)="onSubmit()">
    <table width="100%">
      <tbody>
        <tr>
          <td>
    <mat-form-field>
      <mat-label>Key</mat-label>
      <input matInput formControlName="raspkey">
      <mat-error *ngIf="errorHandling('raspkey', 'required')">
       Feld muss ausgefüllt werden!
      </mat-error>
        </mat-form-field></td>
        <td>
            <a mat-stroked-button color="primary" (click)="createKey()"><mat-icon>refresh</mat-icon>Schlüssel generieren</a>
            </td>
          </tr>
          <tr>
            <td>
      <mat-form-field>
        <mat-label>WLAN Mac</mat-label>
        <input matInput formControlName="wlan_mac" placeholder="00:00:00:00:00:00">
        <mat-error *ngIf="errorHandling('wlan_mac', 'required')">
         Feld muss ausgefüllt werden!
        </mat-error>
        <mat-error *ngIf="errorHandling('wlan_mac', 'pattern')">
          Keine gültige MAC Adresse!
         </mat-error>
          </mat-form-field></td>
          <td> <mat-form-field>
            <mat-label>LAN Mac</mat-label>
            <input matInput formControlName="eth_mac" placeholder="00:00:00:00:00:00">
            <mat-error *ngIf="errorHandling('eth_mac', 'required')">
             Feld muss ausgefüllt werden!
            </mat-error>
            <mat-error *ngIf="errorHandling('eth_mac', 'pattern')">
              Keine gültige MAC Adresse!
             </mat-error>
              </mat-form-field></td>
            </tr>
            <tr>
                <td>
                  <!-- Aktiv? -->
          <mat-checkbox formControlName="active">Aktiv?</mat-checkbox>
          </td>
          <td>
            <!-- Regiolock? -->
    <mat-checkbox formControlName="regiolock">Als RegioLock verwendbar?</mat-checkbox>
    </td>
  </tr>

      <tr>
    <td><button mat-raised-button color="primary" [disabled]="!newForm.valid" type="submit" mat-dialog-close>Speichern</button></td>
    <td><button mat-raised-button color="warn" mat-dialog-close (click)="cancleDialog()">Abbrechen</button></td>
  </tr>
  </tbody>
  </table>
  </form>
</mat-dialog-content>
</div>
