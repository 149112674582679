import { TokenService } from './../token.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { User } from '../schemas';
import { CookieService} from 'ngx-cookie-service';
import * as bcrypt from 'bcryptjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  progressBar = false;
  loginStatus1 = true;
  loginStatus2 = false;
  loginStatus3 = false;
  loginStatus4 = false;
  loggedinUser: User;
  token = '';
  loginPage = true;
  showLoginForm = true;
  impressum = false;
  testclient: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private cookieService: CookieService,
    private tokenService: TokenService
    ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      pw: ['', Validators.required],
      mail: ['', [Validators.required, Validators.email]]
  });
    this.validate();
    const salt = bcrypt.genSaltSync(10);
    const pass = bcrypt.hashSync('Test', salt);
    this.testclient = this.formBuilder.group({
      _id: [null],
      name: ['Alexander Hollaus'],
      mail: ['alex@regio.team'],
      pw: [pass],
      customer: ['5fdcac7588672b8b467d2790'],
      servicelevel: 10,
      modules: ['0'],
      active: true,
      created: [new Date()],
      lastupdate: [new Date()]
    });
    //this.addTestClient();
  }

  addTestClient = () => {
    this.api.addUser(this.testclient.value, this.token)
    .subscribe((res: any) => {
      }, (err: any) => {
      });
  }
  login = () => {
    const mailValue = this.loginForm.value.mail;
    const pwValue = this.loginForm.value.pw;
    this.loginStatus1 = false;
    this.loginStatus2 = false;
    this.loginStatus3 = false;
    this.loginStatus4 = false;
    this.progressBar = true;
    this.api.auth(mailValue, pwValue)
    .subscribe((res: any) => {

      this.progressBar = false;
      if (res) {
        if (res.user.active) {
          this.token = res.access_token;
          this.cookieService.set('token', this.token);
          this.redirectToDashboard(res.user);
        } else {
          this.loginStatus3 = true;
        }
      }
          }, (err: any) => {
        this.progressBar = false;
        const errorstatus = err.status;
        if (errorstatus === 401) {
          this.loginStatus3 = true;
        }
      });
  }

  validate = () => {
    const tokenFromSession = this.cookieService.get('token');
    if (tokenFromSession.length > 0) {
      this.showLoginForm = false;
      this.progressBar = true;
      this.loginStatus1 = false;
      this.api.verify(tokenFromSession)
      .subscribe((res: any) => {
        const userID = res.id;
        const userName = res.username;
        this.getUser(userID, userName, tokenFromSession);
        this.token = tokenFromSession;
        }, (err: any) => {
          // Try to refresh
          this.api.refreshLogin(tokenFromSession)
          .subscribe((resRef: any) => {
            if (resRef.access_token) {
              this.token = resRef.access_token;
              this.cookieService.set('token', resRef.access_token);
              this.getUser(resRef.userid, resRef.username, resRef.access_token);
            } else {
              this.cookieService.set('token', '');
              this.showLoginForm = true;
              this.progressBar = false;
              this.loginStatus4 = true;
            }
                }, (errR: any) => {
                  this.cookieService.set('token', '');
                  this.showLoginForm = true;
                  this.progressBar = false;
                  this.loginStatus4 = true;
            });
        });
    }

  }

  getUser = (userID: string, userName: string, tokenFromSession: string) => {
    this.api.getUser(userID, tokenFromSession)
    .subscribe((resUser: User) => {
      const userActive = resUser.active;
      const userNamefromDB = resUser.mail;
      if (userActive && (userName === userNamefromDB)) {
        this.redirectToDashboard(resUser);
        this.token = tokenFromSession;
      } else {
        this.showLoginForm = true;
        this.progressBar = false;
        this.loginStatus4 = true;
      }
          }, (err: any) => {
            this.showLoginForm = true;
            this.progressBar = false;
            this.loginStatus4 = true;
      });
  }

  redirectToDashboard = (user: User) => {
    this.tokenService.changeToken(this.token);
    this.impressum = false;
    this.loggedinUser = user;
    this.loginStatus2 = true;
    this.loginPage = false;
  }

  openImpressum = () => {
    this.impressum = true;
  };

  receiveImpressumClose() {
    this.impressum = false;
  }

}
