<h2 mat-dialog-title *ngIf="data.option == 1">Neuer Code</h2>
<h2 mat-dialog-title *ngIf="data.option == 2">Code bearbeiten</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="newForm" (ngSubmit)="onSubmit()">
    <table width="100%">
      <tbody>
        <tr>
          <td>
            <span class="code">{{newForm.controls.code.value}}#</span><span class="code" *ngIf="loadCode"><mat-progress-spinner color="primary" mode="indeterminate" diameter=20></mat-progress-spinner></span>
            <a *ngIf="!loadCode" mat-stroked-button color="primary" class="refreshbutton" (click)="calculateNewCode()"><mat-icon>refresh</mat-icon></a>
          <td>
    <mat-form-field [style.width.%]="60">
      <mat-label>Bezeichnung *</mat-label>
      <input matInput formControlName="bezeichnung" placeholder="z.B.Lieferant 1">
      <mat-error *ngIf="errorHandling('bezeichnung', 'required') || errorHandling('bezeichnung', 'minlength')">
       Mindestens 3 Zeichen erforderlich!
      </mat-error>
        </mat-form-field></td>
        </tr>
          <tr>
          <td>
            <mat-label>Gültig ab</mat-label>
            <mat-form-field class="datepicker">
              <input matInput [matDatepicker]="picker" formControlName="start">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </td>

          <td>
            <mat-label>Gültig bis</mat-label>
            <mat-form-field  class="datepicker">
              <input matInput [matDatepicker]="picker1" formControlName="end">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <mat-label>Aktivzeiten</mat-label>

            <table width="100%" class="codetable">
              <thead>
        <th class="codetablethleft">Tage</th>
        <th class="codetablethright">Ab</th>
        <th class="codetablethright">Bis</th>
        <th class="codetablethright">Aktionen</th>
              </thead>
              <tbody formArrayName="activetimes" class="codeTable" *ngFor="let time of newForm.get('activetimes')['controls']; let i = index;">
                <tr [formArrayName]="i">
                  <td class="codetabletdleft" width="50%">
                    <mat-form-field>
                      <mat-select formControlName="day" multiple>
                        <mat-option *ngFor="let tage of days" [value]="tage.value">{{tage.viewValue}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td class="codetabletdright" width="20%">
                    <mat-form-field [style.width.%]="90">
                      <mat-label></mat-label>
                      <input matInput formControlName="start" placeholder="00:00">
                      <mat-error *ngIf="errorHandlingTime(i, 'start', 'pattern')">
                       Falsches Format!
                       </mat-error>
                        </mat-form-field>
                  </td>
                  <td class="codetabletdright" width="20%">
                    <mat-form-field [style.width.%]="90">
                      <mat-label></mat-label>
                      <input matInput formControlName="end" placeholder="00:00">
                      <mat-error *ngIf="errorHandlingTime(i, 'end', 'pattern')">
                        Falsches Format!
                        </mat-error>
                        </mat-form-field>
                  </td>
                  <td class="codetabletdright"><mat-icon class="actionDelete" (click)="deleteTime(i)" width="10%">delete</mat-icon></td>
                </tr>
        </tbody>
            </table>
            <a mat-mini-fab color="primary" (click)="addTime()" class="addCodeButton"><mat-icon>plus_one</mat-icon></a>

          </td>
        </tr>
        <tr>
          <td colspan="2">
            <mat-checkbox formControlName="active">Aktiv?</mat-checkbox>
          </td>
        </tr>

  <tr class="popupbutton">
    <td><button mat-raised-button color="primary" [disabled]="!newForm.valid || (data.option === 1 && !codeCreated)" type="submit" mat-dialog-close class="button">Speichern</button>
    <button mat-raised-button color="warn" mat-dialog-close (click)="cancleDialog()" class="button">Abbrechen</button></td>
</tr>
  </tbody>
  </table>
  </form>
</mat-dialog-content>

