<div>
<h2 *ngIf="data.action == 1" mat-dialog-title>Neuer Hersteller</h2>
<h2 *ngIf="data.action == 2" mat-dialog-title>Hersteller bearbeiten</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="newForm" (ngSubmit)="onSubmit()">
    <table width="100%">
      <tbody>


        <tr>
          <td>
    <mat-form-field [style.width.%]="90">
      <mat-label>Name des Herstellers *</mat-label>
      <input matInput formControlName="name" placeholder="z.B. Sielaff">
      <mat-error *ngIf="errorHandling('name', 'required') || errorHandling('name', 'minlength')">
       Mindestens 3 Zeichen erforderlich!
      </mat-error>
        </mat-form-field></td>
        <td>
                  <!-- Aktiv? -->
          <mat-checkbox formControlName="active">Aktiv?</mat-checkbox>
          </td>
  </tr>

      <tr>
    <td><button mat-raised-button color="primary" [disabled]="!newForm.valid" type="submit" mat-dialog-close>Speichern</button></td>
    <td><button mat-raised-button color="warn" mat-dialog-close (click)="cancleDialog()">Abbrechen</button></td>
  </tr>
  </tbody>
  </table>
  </form>
</mat-dialog-content>
</div>
