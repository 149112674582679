<html>
<div class="container" fxLayout="row wrap" fxLayoutGap="16px grid">
  <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-card class="card">
      <mat-card-header>
        <mat-card-title>Zählwerksauslesung</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div>
          <div class="selectDates">
            <form [formGroup]="losungsForm" (ngSubmit)="showSells()">
            <mat-form-field class="formField">
              <mat-label>Monat</mat-label>
              <mat-select formControlName="month">
                <mat-option *ngFor="let month of months" [value]="month.value">
                  {{month.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="formField">
              <mat-label>Jahr</mat-label>
              <input type="number" matInput formControlName="year">
              <mat-error *ngIf="errorHandling('year', 'required') || errorHandling('year', 'pattern')">
                Keine gültige Jahreszahl
                </mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary" [disabled]="!losungsForm.valid" type="submit" class="getSellsButton">Daten holen</button>
          </form>
          </div>
            <mat-progress-bar mode="indeterminate" *ngIf="loadingSells"></mat-progress-bar>
            <div class="sellsTableDiv" *ngIf="sellsAvailable && !sellsEmpty">
              <table class="sellsTable">
                <tbody *ngFor="let location of currentSells">
                  <tr>
                    <th colspan="5" class="sellsTableLocation">{{location.location}}</th>
                  </tr>
                  <tr *ngFor="let sell of location.sells">
                    <td class="machineTable"></td>
                    <td colspan="2" class="machineTable machineHeader">{{sell.machine}}</td>
                    <td class="machineTable">
                      <table class="valueTable">
                        <thead>
                          <tr>
                            <th>Umsatz (inkl. Pfand)</th>
                            <th>Umsatz (exkl. Pfand)</th>
                            <th>Steuersatz</th>
                            <th>Steuer</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{sell.value10inkDep | currency: 'EUR'}}</td>
                            <td>{{sell.value10 | currency: 'EUR'}}</td>
                            <td>10%</td>
                            <td>{{calcTax(10, sell.value10) | currency: 'EUR'}}</td>
                          </tr>
                          <tr>
                            <td>{{sell.value20inkDep | currency: 'EUR'}}</td>
                            <td>{{sell.value20 | currency: 'EUR'}}</td>
                            <td>20%</td>
                            <td>{{calcTax(20, sell.value20) | currency: 'EUR'}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5" class="lineDivider"></td>
                  </tr>
                </tbody>
                <tr class="sumLine">
                  <th colspan="5" class="sellsTableLocation">Gesamt</th>
                </tr>
                <tr class="sumLine">
                  <td class="machineTable"></td>
                  <td colspan="2" class="machineTable machineHeader"></td>
                  <td class="machineTable">
                    <table class="valueTable">
                      <thead>
                        <tr>
                          <th>Betrag (inkl. Pfand)</th>
                          <th>Betrag (exkl. Pfand)</th>
                          <th>Steuersatz</th>
                          <th>Steuer</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{calcSumInkDep(10) | currency: 'EUR'}}</td>
                          <td>{{calcSum(10) | currency: 'EUR'}}</td>
                          <td>10%</td>
                          <td>{{calcTax(10, calcSum(10)) | currency: 'EUR'}}</td>
                        </tr>
                        <tr>
                          <td>{{calcSumInkDep(20) | currency: 'EUR'}}</td>
                          <td>{{calcSum(20) | currency: 'EUR'}}</td>
                          <td>20%</td>
                          <td>{{calcTax(20, calcSum(20)) | currency: 'EUR'}}</td>
                        </tr>
                        <tr>
                          <td colspan="4">Anzahl Pfandflaschen: {{calcDep()}} ({{calcDepWorth() | currency: 'EUR'}})</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>       
                </tr>
              </table>
             </div>

            <div class="sellsTableDiv" *ngIf="sellsAvailable && sellsEmpty">
              Keine Verkäufe verzeichnet
              </div>
              <button *ngIf="sellsAvailable" mat-raised-button class="pdfButton" color="primary" (click)="createPDFBeleg()">PDF erstellen</button>
              <mat-form-field class="sortSelector" *ngIf="sellsAvailable">
                <mat-label>Sortierung</mat-label>
                <mat-select [(ngModel)]="pdfMode">
                  <mat-option [value]="1">Name aufsteigend</mat-option>
                  <mat-option [value]="2">Anzahl aufsteigend</mat-option>
                </mat-select>
              </mat-form-field>
          </div>

      </mat-card-content>
      <mat-card-actions>

      </mat-card-actions>
    </mat-card>
  </div>
  <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-card class="card">
      <mat-card-header>
        <mat-card-title></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-card-title>Verkaufsübersicht</mat-card-title>
        <div class="sellsExcelProgressBar"  *ngIf="loadingSellExcel"><mat-progress-bar mode="indeterminate"></mat-progress-bar></div>
        <!--<mat-form-field [style.width.%]="50">
          <mat-label>Zeitraum wählen</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start">
            <input matEndDate formControlName="end" placeholder="Ende">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>-->
        <button mat-raised-button color="primary" type="submit" class="getSellsButton" (click)="exportAllSellsClient()" *ngIf="!createSellExcel">Alle Verkäufe exportieren</button>
      </mat-card-content>
      <mat-card-actions>
      </mat-card-actions>
    </mat-card>
  </div>

</div>

</html>

