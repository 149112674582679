<html>
<div class="container" fxLayout="row wrap" fxLayoutGap="16px grid">
  <div fxFlex="30%" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-card class="locationcard">
      <mat-card-header>
        <mat-card-title>Meine Standorte</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-progress-bar mode="indeterminate" *ngIf="loadingLocations"></mat-progress-bar>
        <div>
        <div class="filterField">
        <mat-form-field style="width: 100%">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" #input>
        </mat-form-field>
      </div>
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 locationstable" #locationsTable="matSort">
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>


           <!-- City Column -->
           <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ort </th>
            <td mat-cell *matCellDef="let element"> {{element.city}} </td>
          </ng-container>

                     <!-- Actions Column -->
           <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Aktionen </th>
            <td mat-cell *matCellDef="let element"><mat-icon class="locationDelete" (click)="deleteLocation(element._id)">delete</mat-icon><mat-icon class="locationsAction" (click)="updateLocation(element)">edit</mat-icon><mat-icon class="locationsAction" (click)="selectLocation(element)">arrow_forward</mat-icon></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <mat-paginator #locationsPaginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="newLocation()">Neuer Standort</button>
      </mat-card-actions>
    </mat-card>
  </div>
  <div fxFlex="30%" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-card class="locationcard">
      <mat-card-header>
        <mat-card-title>Daten des Standorts</mat-card-title>
      </mat-card-header>
      <mat-card-content>
<div *ngIf="!locationSelected" class="noLocationSelected">Bitte zuerst Standort wählen (Symbol <mat-icon>arrow_forward</mat-icon>)</div>
<div *ngIf="locationSelected">
  <div>
    <table class="locationsDataTable">
      <tr>
        <td class="locationsDataTableth">Name:</td>
        <td class="locationsDataTabletd">{{selectedLocation.name}}</td>
      </tr>
      <tr>
        <td class="locationsDataTableth">Straße und Hausnummer:</td>
        <td class="locationsDataTabletd">{{selectedLocation.street}} / {{selectedLocation.housenumber}}</td>
      </tr>
      <tr>
        <td class="locationsDataTableth">PLZ und Ort:</td>
        <td class="locationsDataTabletd">{{selectedLocation.country}}-{{selectedLocation.plz}} {{selectedLocation.city}}</td>
      </tr>
      <tr>
        <td class="locationsDataTableth">Koordinaten X/Y:</td>
        <td class="locationsDataTabletd">{{selectedLocation.locationX}} / {{selectedLocation.locationY}}</td>
      </tr>
      <tr>
        <td class="locationsDataTableth">Zufahrtsbeschreibung:</td>
        <td class="locationsDataTabletd">{{selectedLocation.accessDescription}}</td>
      </tr>
      <tr>
        <td class="locationsDataTableth">Bilder:</td>
        <td class="locationsDataTabletd">{{selectedLocation.pictures}}</td>
      </tr>
    </table>
  </div>

<div class="regiolockdiv">
  <app-regiolock [currentClient]="currentClient" [loggedInUser]="loggedInUser" [selectedLocation]="selectedLocation"></app-regiolock>
</div>
</div>
      </mat-card-content>
      <mat-card-actions>

      </mat-card-actions>
    </mat-card>
  </div>

  <div fxFlex="40%" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-card class="locationcard">
      <mat-card-header>
        <mat-card-title>Aktive Automaten am Standort</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div *ngIf="!locationSelected" class="noLocationSelected">Bitte zuerst Standort wählen (Symbol <mat-icon>arrow_forward</mat-icon>)</div>
        <div *ngIf="locationSelected">
        <mat-progress-bar mode="indeterminate" *ngIf="loadingMachines"></mat-progress-bar>
        <div class="filterField">
        <mat-form-field style="width: 100%">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilterMachines($event)" #input>
        </mat-form-field>
      </div>
        <table mat-table [dataSource]="machineSource" matSort class="mat-elevation-z8 locationstable" #machinesTable="matSort">
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Status </th>
            <td mat-cell *matCellDef="let element">
              <div *ngIf="getSatelliteStatus(element)==1" class="status_indicator status_green"></div>
              <div *ngIf="getSatelliteStatus(element)==2" class="status_indicator status_grey"></div>
              <div *ngIf="getSatelliteStatus(element)==3" class="status_indicator status_red"></div>
            </td>
          </ng-container>

            <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Aktionen </th>
           <td mat-cell *matCellDef="let element"><mat-icon class="actionEdit" *ngIf="element.raspkey.length > 0" (click)="unbindSatellite(element)">desktop_access_disabled</mat-icon><mat-icon *ngIf="getSatelliteStatus(element)==1" class="actionEdit" (click)="restartSatellite(element)">refresh</mat-icon></td>
           </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedMachineColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedMachineColumns;"></tr>

        </table>
        <mat-paginator #machinesPaginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      </mat-card-content>
      <mat-card-actions>

      </mat-card-actions>
    </mat-card>
  </div>
</div>

</html>
