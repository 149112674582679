import { Observable } from 'rxjs';
import { TokenService } from './../token.service';
import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, Input, AfterViewInit, resolveForwardRef } from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { ApiService } from '../api.service';
import { Client, User } from '../schemas';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MAT_DATE_LOCALE_PROVIDER } from '@angular/material/core';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers:[DatePipe]
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('snav') sidenav: MatSidenav;
  @Input() loggedInUser: User;
  @Input() token: string;

  siteIcon = 'dashboard';
  siteTitle = 'Dashboard';
  siteId = 10;
  locationsForm: FormGroup;
  selectClient =  new FormControl();
  clients: Client;
  accessLevel = 0;
  serviceLevel = 0;
  userAccessLevel = 0;
  currentClient: Client;
  userIsActive = false;
  tokenRefreshInterval: any;
  sevenDaysChart = [];
  showSDChart = false;
  sellsPerDayChart = [];
  showSPDChart = false;
  productSells = [];
  productSellsArray = [];
  bestProductsArray = [];
  showBestProductsChart = false;
  impressum = false;
  daysInput = 7;
  daysInputChart = 7;
  sellsFromChartArray: any;
  showRevenueChart = false;
  revenueArray = [];
  depositCount = 0;
  depositValue = 0;
  sellsPerTimeArray = [];
  showsellsPerTimeChart = false;
  noData = false;
  umsatzGesamt = 0;
  gewinnGesamt = 0;
  productsArray = [];
  sellsRefLines = [{'name': 'Obere Grenze', 'value':500  }, {'name': 'Untere Grenze', 'value':35}]

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private api: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private cookieService: CookieService,
    private tokenService: TokenService,
    private route: ActivatedRoute,
    public datepipe: DatePipe
    ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this.mobileQueryListener);
  }


  mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }

  ngOnInit(): void {
    this.refreshToken();
    this.getActiveClients();
    this.validateUser();
    this.getUserStartClient();
     }

  formatCurrency =  new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });

  logout = () => {
    this.api.logout(this.token).subscribe((answer: any) => {
      if (answer.logout === true) {
        this.cookieService.set('token', '');
        this.tokenService.changeToken('');
        clearInterval(this.tokenRefreshInterval);
        this.router.navigate(['']);
        window.location.reload();
      }
    });
  }

  changeSite = (siteid: number, sitetitle: string, siteIcon: string) => {
    this.siteId = siteid;
    this.siteTitle = sitetitle;
    this.siteIcon = siteIcon;
    if (this.siteId === 10) {
      this.updateDashboard();
    }
    this.sidenav.toggle();
    this.validateUser();
  }

  updateDashboard = () => {
    this.getSevendaysChart();
  }

  getActiveClients = () => {
    this.api.getActiveClients(this.token)
    .subscribe((res: any) => {
        this.clients = res;
      }, (err: any) => {

      });
  }


  changeClient = (value) => {
    this.api.getClient(value, this.token)
    .subscribe((res: any) => {
      this.currentClient = res;
      this.accessLevel = this.currentClient.accesslevel;
      this.changeSite(10, 'Dashboard', 'dashboard');
      }, (err: any) => {
      });
  }

  validateUser = () => {
    this.api.getUser(this.loggedInUser._id, this.token)
    .subscribe((resUser: User) => {
      const userActive = resUser.active;
      this.userIsActive = resUser.active;
      if (!userActive) {
        this.cookieService.set('token', '');
        clearInterval(this.tokenRefreshInterval);
        this.router.navigate(['']);
        window.location.reload();
      } else {
        this.serviceLevel = this.loggedInUser.servicelevel;
      }
          }, (err: any) => {
            this.cookieService.set('token', '');
            clearInterval(this.tokenRefreshInterval);
            this.router.navigate(['']);
            window.location.reload();
      });
  }

  getUserStartClient = () => {
    this.api.getClient(this.loggedInUser.customer, this.token)
    .subscribe((res: Client) => {
      this.currentClient = res;
      this.selectClient.setValue(res._id);
      this.accessLevel = this.currentClient.accesslevel;
      this.userAccessLevel = this.currentClient.accesslevel;
      this.updateDashboard();
          });

  }

  refreshToken = () => {
    this.api.refresh(this.loggedInUser._id, this.token)
    .subscribe((res: any) => {
      if (res.access_token) {
        this.token  = res.access_token;
        this.cookieService.set('token', res.access_token);
        this.tokenService.changeToken(res.access_token);
        this.startTokenRefresh();
      } else {
       this.cookieService.set('token', '');
       this.tokenService.changeToken('');
       this.router.navigate(['']);
       window.location.reload();
      }
          }, (err: any) => {
      });
  }

  startTokenRefresh = () => {
    clearInterval(this.tokenRefreshInterval);
    this.tokenRefreshInterval = setInterval(() => {
      this.refreshToken();
    }, 840000);
  }

  getProductSellsClient = () => {
    return new Promise ((resolve) => {
      const sellsArray = [];
      const currentDate = new Date();
      const newdate = new Date(currentDate.getTime() - (1000*60*60*24*32));
        // Alle Maschinen auslesen
        this.api.getMachinesForClient(this.currentClient._id,this.token)
        .subscribe((machines: any) => {
        if (machines.length > 0) {
          const machinesLength = machines.length;
          let machinesLoop = 0;
          // Für jede Maschine die Verkäufe der letzten 31 Tage holen
          machines.forEach((mach) => {
            const machineid = mach._id;
            this.api.getSellsPerMachine(machineid, newdate.toString(), currentDate.toString(), this.token)
            .subscribe((resProd: any) => {
              // Check, ob das Produkt im Array bereits vorhanden ist
              if (resProd) {
                const sellsLength = resProd.length;
                let sellsLoop = 0;
                if (resProd.length === 0) {
                  machinesLoop++;
                }
                resProd.forEach((product) => {
                  sellsLoop++;
                  if (product.name.length > 1) {
                  const productindex = sellsArray.findIndex(element => element.name === product.name);
                  if (productindex !== -1) {
                    // Bereits vorhanden
                    sellsArray[productindex].sells.push(product);
                  } else {
                    // Nicht vorhanden, neu anlegen
                    const tempArray = [];
                    tempArray.push(product);
                    const tempObject = {
                      name: product.name,
                      sells: tempArray
                    }
                    sellsArray.push(tempObject);
                  }
                }
                if (sellsLength === sellsLoop) {
                  machinesLoop++;
                }

                if (machinesLength === machinesLoop && sellsLength === sellsLoop) {
                  resolve(sellsArray);
                }
                });
               }
              }, (err: any) => {
              });

          });
        } else {
          this.noData = true;
        }
        });
    });
  }

  getProductSellsSupplier = () => {
    return new Promise ((resolve) => {
      const sellsArray = [];
      // Alle Produkte des Zulieferers holen
      this.api.getClient(this.currentClient._id, this.token)
      .subscribe((res: any) => {
       const pLength = res.supplierarray.length;
       res.supplierarray.forEach((product) => {
          this.api.getSellsSupplier(product, this.currentClient._id, this.token)
          .subscribe((sellsR: any) => {
            let prodName = '';
            if (sellsR.length > 0) {
              prodName = sellsR[0].name;
            }
            const tempObject = {
              name: prodName,
              sells: sellsR
            };
            sellsArray.push(tempObject);
            if (sellsArray.length === pLength) {
              resolve(sellsArray);
            }
          });
      });
    });
    });
  }

    getSevendaysChart = () => {
    this.sevenDaysChart = [];
    this.showSDChart = false;
    this.showBestProductsChart = false;
    this.bestProductsArray = [];
    this.sellsPerDayChart = [];
    this.showSPDChart = false;
    this.productSellsArray = [];
    this.showRevenueChart = false;
    this.revenueArray = [];
    this.depositCount = 0;
    this.depositValue = 0;
    this.sellsPerTimeArray = [];
    this.showsellsPerTimeChart = false;
    if (this.currentClient.clienttype >= 5) {
      if (this.currentClient.clienttype === 5) {
      this.getProductSellsSupplier().then((sellsS: any) => {
        this.addToSellsArray(sellsS).then((sellsR: any) => {
          this.productsArray = sellsR;
              this.productsArray = sellsR;
              this.createChartArray();
          });
        });
          } else {
            this.getProductSellsClient().then((sellsT: any) => {
              this.productsArray = sellsT;
                this.createChartArray();
            });
          }
    }
  }

  addToSellsArray = (array: any) => {
    return new Promise ((resolve) => {
      const arrayLength = array.length;
      let loop = 0;
      array.forEach((product) => {
        loop = loop + 1;
        this.productSellsArray.push(product);
        if (loop === arrayLength) {
          resolve(this.productSellsArray);
        }
      });
    });
  }


   createChartArray = () => {
     // Check, ob die Tage richtig ausgewählt wurden
    if (this.daysInput === null) {
      this.daysInput = 7;
    } else if (this.daysInput > 31) {
      this.daysInput = 31;
    }

    const sellsR = this.productsArray;
    this.daysInputChart = this.daysInput;
    this.productSellsArray = [];
    // Alle Produkte holen
    const returnArray = [];
    const dateArray = [];
    const timeArray = [];
    let timeArrayCreated = false;
    const currentDate = new Date()
    let i = 0;
    while (i < this.daysInput) {
      const temptage = this.daysInput - i -1;
      const newdate = new Date(currentDate.getTime() - (1000*60*60*24*temptage));
      const newDateforArray = new Date(formatDate(newdate, 'yyyy-MM-ddT00:00:00', 'en-US'));
      dateArray.push(newDateforArray)
      i++;
    }
    const sellArrayLength = sellsR.length;
    let sellArrayLoop = 0;
    new Promise ((resolve) => {
    sellsR.forEach((sell) => {
      sellArrayLoop++;
      if (sell.name !== '') {
        const seriesArray = [];
        let sellsSum = 0
        const dateArrayLength = dateArray.length;
        let dateArrayLoop = 0;
        dateArray.forEach((date) => {
          dateArrayLoop++;
      const startDateProd = date;
      const endDateProd = new Date(date.getTime() + (1000*60*60*24*1));
        // tslint:disable-next-line: max-line-length
        const prodsells = sell.sells.filter(element => new Date(element.time) >= startDateProd && new Date(element.time) < endDateProd);
        sellsSum = sellsSum + prodsells.length;
          const tempObject = {
            'name': formatDate(date, 'dd.MM.yyyy', 'en-US'),
            'value': prodsells.length
          };
          seriesArray.push(tempObject);
          if (dateArrayLength === dateArrayLoop) {
            const tempObjectArray = {
              'name': sell.name,
              'series': seriesArray
            };
            if (sellsSum > 0) {
              returnArray.push(tempObjectArray);
            }
            if (dateArrayLength === dateArrayLoop && sellArrayLength === sellArrayLoop) {
              this.sevenDaysChart = returnArray;
              this.showSDChart = true;
              resolve(returnArray);
            }
          }
        });
    }
    });
  });

    const chartsPromise = new Promise((resolve) => {
    const arraySells = [];
    const tempArrayWK = [];
    const tempArraynWK = [];
    const tempArraySum = [];
    const tempArrayBP = []
    const ArrayRev = [];
    // Anzahl der Verkäufe pro Tag
    const dateArrayLength = dateArray.length;
    let dateArrayLoop = 0;
    dateArray.forEach((date) => {
    dateArrayLoop++;
    const tempArrayRev = [];
    const startDateProd = date;
    const endDateProd = new Date(date.getTime() + (1000*60*60*24*1));
    let wkSum = 0;
    let nonWkSum = 0;
    const sellsLength = sellsR.length;
    let sellsLoop = 0;
    sellsR.forEach((sell) => {
    sellsLoop++;
    if (sell.name !== '') {
    const prodsells = sell.sells.filter(element => new Date(element.time) >= startDateProd && new Date(element.time) < endDateProd && element.wk === 0);
    nonWkSum = nonWkSum + prodsells.length;
    const prodsellsWk = sell.sells.filter(element => new Date(element.time) >= startDateProd && new Date(element.time) < endDateProd && element.wk === 1);
    wkSum = wkSum + prodsellsWk.length;
    const allSells = sell.sells.filter(element => new Date(element.time) >= startDateProd && new Date(element.time) < endDateProd);
    // Verkäufe pro Tageszeit
    let j = 0;
    while (j < 24) {
      const jString = j.toString();
      const jFormat = ( '0' + jString ).substr( -2 );
      // TimeArray erstellen
      if (!timeArrayCreated) {
        const tempObjectTime = {
          "anzahl": []
        }
        timeArray.push(tempObjectTime);
        if (j === 23) {
          timeArrayCreated = true;
        }
      }
      const format = 'yyyy-MM-ddT' + jFormat + ':59:59';
      const formatBefore = 'yyyy-MM-ddT' + jFormat + ':00:00';
      const sellPerTime = new Date(formatDate(date, format, 'en-US'));
      const sellPerTimeBefore = new Date(formatDate(date, formatBefore, 'en-US'));
      const allSellsinTime = sell.sells.filter(element => new Date(element.time) >= sellPerTimeBefore && new Date(element.time) <= sellPerTime);
      const verkaufsAnzahl = allSellsinTime.length;
      if (verkaufsAnzahl > 0) {
        const tempObjecttoPush = {
          "name": sell.name,
          "sells": verkaufsAnzahl
        }
        timeArray[j].anzahl.push(tempObjecttoPush);
      }
      j++;
    }

    const tempObjectBP = {
      name: sell.name,
      date: new Date (startDateProd),
      sells: prodsells.length + prodsellsWk.length
    };

    const tempObjectRev = {
      name: sell.name,
      sells: allSells
    };

    tempArrayBP.push(tempObjectBP);
    tempArrayRev.push(tempObjectRev);
    }

    if (sellsLength === sellsLoop) {
      const tempObjectWK = {
        'name': formatDate(date, 'dd.MM.yyyy', 'en-US'),
        'value': wkSum
      };
      tempArrayWK.push(tempObjectWK);
      const tempObjectnWK = {
        'name': formatDate(date, 'dd.MM.yyyy', 'en-US'),
        'value': nonWkSum
      };
      tempArraynWK.push(tempObjectnWK);
      const tempObjectnSum = {
        'name': formatDate(date, 'dd.MM.yyyy', 'en-US'),
        'value': nonWkSum + wkSum
      };
      tempArraySum.push(tempObjectnSum);
      const tempObjectArrayRev = {
        date: new Date (startDateProd),
        sells: tempArrayRev
      };
      ArrayRev.push(tempObjectArrayRev);
    }
    if (sellsLength === sellsLoop && dateArrayLength === dateArrayLoop) {
      const tempObjectArrayWK = {
        'name': 'Warenkorb',
        'series': tempArrayWK
      };
      const tempObjectArraynWK = {
        'name': 'Einzel',
        'series': tempArraynWK
      };
      const tempObjectArray = {
        'name': 'Gesamt',
        'series': tempArraySum
      };
      arraySells.push(tempObjectArray);
      arraySells.push(tempObjectArraynWK);
      arraySells.push(tempObjectArrayWK);
      resolve([arraySells, timeArray, tempArrayBP, ArrayRev]);
    }
    });
    });
    });
      chartsPromise.then((result) => {
        this.sellsPerDayChart = result[0];
        this.showSPDChart = true;
        this.sellsPerTime(result[1]);
        this.bestProducts(result[2]);
        this.createRevenues(result[3]);
      });


  }

  bestProducts = (sellsR) => {
    const produkteanzahl = 8;
    const tempBPArray = [];
    // Zusammenfassen der Verkäufe
    const sellsLength = sellsR.length;
    let sellsLoop = 0;
    sellsR.forEach((sell) => {
      sellsLoop++;
      const name = sell.name;
      const tempArraySells = [];
      const sellInArray = tempBPArray.findIndex(element => element.name === name);
      if (sellInArray !== -1) {
        // Bereits vorhanden, hinzufügen
        const sellsArray = tempBPArray[sellInArray].sells;
        tempBPArray[sellInArray].sellSum = tempBPArray[sellInArray].sellSum + sell.sells;
        const dateInSell = sellsArray.findIndex(element => element.name === formatDate(sell.date, 'dd.MM.yyyy', 'en-US'));
        if (dateInSell === -1) {
          // Datum noch nicht vorhanden
          const sellObject = {
            name:  formatDate(sell.date, 'dd.MM.yyyy', 'en-US'),
            value: sell.sells
          };
          tempBPArray[sellInArray].sells.push(sellObject);
        } else {
          // Datum bereits vorhanden
          tempBPArray[sellInArray].sells[dateInSell].value = tempBPArray[sellInArray].sells[dateInSell].value + sell.sells;
        }

      } else {
        const sellObject = {
          name:  formatDate(sell.date, 'dd.MM.yyyy', 'en-US'),
          value: sell.sells
      };
      tempArraySells.push(sellObject);
      const Bpobject = {
        name: sell.name,
        sellSum: sell.sells,
        sells: tempArraySells
      };
        tempBPArray.push(Bpobject);
      }
      if (sellsLength === sellsLoop) {
    // Array sortieren
    tempBPArray.sort((a, b) => (a.sellSum > b.sellSum ? -1 : 1));
    // Nur die bestverkauften Produkte ausgeben
    const returnArray = [];
    for (let i = 0; i < produkteanzahl; i++) {
      const produkt = tempBPArray[i];
      const returnObject = {
        'name': produkt.name,
        'series': produkt.sells
      };
      returnArray.push(returnObject);
      if (i === produkteanzahl - 1) {
        this.bestProductsArray = returnArray;
        this.showBestProductsChart = true;
      }
    }
      }
    });

  }

  sellsPerTime = (sellsA) => {
    let i = 0;
    const returnArray = [];
    const sellsLengthA = sellsA.length;
    let sellsLoopA = 0;
    sellsA.forEach((sellR) => {
      sellsLoopA++;
      const tempArrayTime = [];
      const jString = i.toString();
      const jFormat = ( '0' + jString ).substr( -2 );
      const jString2 = (i + 1).toString();
      const jFormat2 = ( '0' + jString2 ).substr( -2 );
      const time = jFormat + ':00 - ' + jFormat2 + ':00';
      const sellsLength = sellR.anzahl.length;
      let sellsLoop = 0;
      if (sellsLength === 0) {
        const tempObjectTime = {
          "name": time,
          "series": tempArrayTime
        };
        returnArray.push(tempObjectTime);
      } else {


      sellR.anzahl.forEach((timeSell) => {
        sellsLoop++;
        // Schauen, ob bereits ein Eintrag vorhanden ist
        const productIndex = tempArrayTime.findIndex(element => element.name === timeSell.name);
        if (productIndex !== -1) {
          // Produkt vorhanden, hinzuzählen
          tempArrayTime[productIndex].value = tempArrayTime[productIndex].value + timeSell.sells;
        } else {
          // Produkt neu hinzufügen
          const tempObject = {
            "name": timeSell.name,
            "value": timeSell.sells
          };
          tempArrayTime.push(tempObject);
        }
              // Objekt fertig stellen
      if (sellsLength === sellsLoop) {
        const tempObjectTime = {
          "name": time,
          "series": tempArrayTime
        };
        returnArray.push(tempObjectTime);
      }
      });
    }
    i++;
    if (sellsLengthA === sellsLoopA && sellsLength === sellsLoop) {
      this.sellsPerTimeArray = returnArray;
      this.showsellsPerTimeChart = true;
    }
    });

  }

  getGewinnGesamt = () => {
    return this.formatCurrency.format(+this.gewinnGesamt.toFixed(2));
  }

  getDepositCount = () => {
    return this.depositCount;
  }

  getDepositValue = () => {
    return this.formatCurrency.format(+this.depositValue.toFixed(2));
  }

  getUmsatzGesamt = () => {
    return this.formatCurrency.format(+this.umsatzGesamt.toFixed(2));
  }

  getGewinn = () => {
    const returnValue = (this.gewinnGesamt / this.daysInputChart).toFixed(2);
    return this.formatCurrency.format(+returnValue);
  }

  getUmsatz = () => {
    const returnValue = (this.umsatzGesamt / this.daysInputChart).toFixed(2);
    return this.formatCurrency.format(+returnValue);
  }

  createRevenues = (sellsR) => {
    const tempArrayUmsatz = [];
    const tempArrayGewinn = [];
    const returnArray = [];
    this.umsatzGesamt = 0;
    this.gewinnGesamt = 0;
    const sellsLength = sellsR.length;
    let sellsLoop = 0;
    sellsR.forEach((sell) => {
      sellsLoop++;
      let vksum = 0;
      let gewinnsum = 0;
      const date = sell.date;
      sell.sells.forEach((datesell) => {
        if (datesell.sells.length > 0) {
          datesell.sells.forEach((einzelverkauf) => {
            const divider = 100 + einzelverkauf.taxrate;
            let vk = 0;
            let depositValue = 0;
            if (einzelverkauf.depositValue) {
              depositValue = einzelverkauf.depositValue;
            }
            // Berechnung bei Pfandprodukten
            if (einzelverkauf.deposit === true) {
              vk = (einzelverkauf.vk - depositValue) / divider * 100;
              this.depositCount++;
              this.depositValue = this.depositValue + depositValue;
            } else {
              vk = einzelverkauf.vk / divider * 100;
            }
            vksum = vksum + vk;
            gewinnsum = gewinnsum + vk - einzelverkauf.ek;
          });
        }
      });
      const tempObjectUmsatz = {
        "value": +vksum.toFixed(2),
        "name": formatDate(date, 'dd.MM.yyyy', 'en-US')
      };
      tempArrayUmsatz.push(tempObjectUmsatz);
      const tempObjectGewinn = {
        "value": +gewinnsum.toFixed(2),
        "name": formatDate(date, 'dd.MM.yyyy', 'en-US')
      };
      tempArrayGewinn.push(tempObjectGewinn);
      this.gewinnGesamt = this.gewinnGesamt + gewinnsum;
      this.umsatzGesamt = this.umsatzGesamt + vksum;
      if (sellsLength === sellsLoop) {
        const gewinnObject = {
          "name": "Rohertrag",
          "series": tempArrayGewinn
        };

        const umsatzObject = {
          "name": "Umsatz",
          "series": tempArrayUmsatz
        };

        returnArray.push(gewinnObject, umsatzObject);
        this.revenueArray = returnArray;
        this.showRevenueChart = true;
      }
    });

  }

  dateTickFormatting = (val: any): string => {
    if (val instanceof Date) {
      return (val).toLocaleString('de-DE');
    }
  }
  getMyProducts = () => {
    return new Promise ((resolve) => {
    this.api.getProducts(this.loggedInUser.customer, this.token)
    .subscribe((resP: any) => {
      resolve(resP);
    });
  });
  }

  showImpressum = () => {
    this.impressum = true;
  }
  hideImpressum = () => {
    this.impressum = false;
  }

  returnTime = (val) => {
    const date = new Date(val);
    return this.datepipe.transform(date, 'dd.MM.yyyy HH:mm');
  }
}
