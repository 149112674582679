<html>
<div class="container" fxLayout="row wrap" fxLayoutGap="16px grid">
  <div fxFlex="30%" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-card class="machinecard">
      <mat-card-header>
        <mat-card-title>Meine Automaten</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-progress-bar mode="indeterminate" *ngIf="loadingMachines"></mat-progress-bar>
        <div>
          <div class="filterField">
            <mat-form-field style="width: 100%">
              <mat-label>Filter</mat-label>
              <input matInput (keyup)="applyFilter($event)" #input>
            </mat-form-field>
          </div>
        <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8 machinetable" #machinesTable="matSort">
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <!-- Owner Column -->
          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Standort </th>
            <td mat-cell *matCellDef="let element"> {{getLocationName(element.location)}} </td>
          </ng-container>

           <!-- Active Column -->
           <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Aktiv </th>
            <td mat-cell *matCellDef="let element"><mat-checkbox [checked]="element.active" (change)="changeActiveState($event, element)"></mat-checkbox></td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Aktionen </th>
           <td mat-cell *matCellDef="let element"> <!--<mat-icon class="actionDelete" (click)="deleteMachine(element._id)">delete</mat-icon>--><mat-icon class="actionEdit" (click)="updateMachine(element)">edit</mat-icon><mat-icon class="actionEdit" (click)="selectMachine(element)">arrow_forward</mat-icon><mat-icon class="actionEdit" *ngIf="element.raspkey.length > 1" (click)="openDialogProtocol(element)">list_alt</mat-icon><mat-icon class="actionEdit" *ngIf="element.raspkey.length > 1" (click)="openDialogSells(element)">shopping_basket</mat-icon><mat-icon class="actionEdit" *ngIf="element.curr_hum > 0" (click)="openDialogTemp(element)">stacked_line_chart</mat-icon></td>
           </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <mat-paginator #machinesPaginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="newMachine()">Neues Gerät</button>
      </mat-card-actions>
    </mat-card>
  </div>
  <div fxFlex="30%" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-card class="machinecard">
      <mat-card-header>
        <mat-card-title>Automatendaten</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div *ngIf="!machineSelected" class="noMachineSelected">Bitte zuerst Automaten wählen (Symbol <mat-icon>arrow_forward</mat-icon>)</div>
<div *ngIf="machineSelected">
  <div>
    <table class="machinesDataTable">
      <tr>
        <td class="machinesDataTableth">Name:</td>
        <td class="machinesDataTabletd">{{selectedMachine.name}}</td>
      </tr>
      <tr>
        <td class="machinesDataTableth">Hersteller / Modell:</td>
        <td class="machinesDataTabletd">{{getManufacturerName(selectedMachine.manufacturer)}} / {{getModelName(selectedMachine.model)}}</td>
      </tr>
      <tr>
        <td class="machinesDataTableth">Seriennummer:</td>
        <td class="machinesDataTabletd">{{selectedMachine.serialnumber}}</td>
      </tr>
      <tr>
        <td class="machinesDataTableth">Standort:</td>
        <td class="machinesDataTabletd">{{getLocationName(selectedMachine.location)}}</td>
      </tr>
    </table>
  </div>
  <div *ngIf="tempAvailable">
    <mat-divider [inset]="true"></mat-divider>
    <table class="machinesDataTable">
      <tr>
        <td class="machinesDataTableth" colspan ="2">Daten vom {{selectedMachine.tempupdate | date:'dd.MM.yyyy HH:mm'}} Uhr</td>
      </tr>
      <tr>
        <td class="machinesDataTableth">Temperatur:</td>
        <td class="machinesDataTabletd">{{selectedMachine.curr_temp}} °C</td>
      </tr>
      <tr>
        <td class="machinesDataTableth">Luftfeuchtigkeit:</td>
        <td class="machinesDataTabletd">{{selectedMachine.curr_hum}} %</td>
      </tr>
    </table>
  </div>
  <div *ngIf="machineIsBinded">
    <mat-divider [inset]="true"></mat-divider>
    <mat-progress-bar mode="indeterminate" *ngIf="satelliteSatusLoading"></mat-progress-bar>
    <table *ngIf="!satelliteSatusLoading" class="machinesDataTable">
<tr>
    <td class="machinesDataTableth">Verbindungsstatus:</td>
    <td class="machinesDataTabletd">
      <div *ngIf="satelliteStatus==1" class="status_indicator status_green"></div>
      <div *ngIf="satelliteStatus==2" class="status_indicator status_grey"></div>
      <div *ngIf="satelliteStatus==3" class="status_indicator status_red"></div>
      <mat-icon class="actionEdit" *ngIf="selectedMachine.raspkey.length > 1" (click)="unbindSatellite(1, '')">desktop_access_disabled</mat-icon><mat-icon *ngIf="satelliteStatus==1" class="actionEdit" (click)="restartSatellite()">refresh</mat-icon><mat-icon *ngIf="satelliteStatus==1 && evatype > 0" class="actionEdit" (click)="initMachine()">task_alt</mat-icon>
    </td>
</tr>
</table>
<mat-divider [inset]="true"></mat-divider>
<table *ngIf="!satelliteSatusLoading" class="machinesDataTable">
<tr *ngIf="evatype > 0">
  <td class="machinesDataTableth">Initialisierungsstatus Verkaufsstatistik:</td>
  <td class="machinesDataTabletd">
    <span *ngIf="initstatus == 0">Noch nicht durchgeführt</span>
    <span *ngIf="initstatus == 1">Wird gerade durchgeführt</span>
    <span *ngIf="initstatus == 2">Erfolgreich durchgeführt</span>
    <span *ngIf="initstatus == 3">Fehler</span>
  </td>
</tr>
<tr>
  <td>
  <mat-checkbox [checked]="selectedMachine.saveSells" (change)="changeStateSaveProducts($event)" class="checkboxSave">Verkaufsdaten speichern?</mat-checkbox>
</td>
</tr>
</table>
  </div>
</div>
      </mat-card-content>
      <mat-card-actions>
      </mat-card-actions>
    </mat-card>
  </div>

  <div fxFlex="40%" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-card class="machinecard">
      <mat-card-header>
        <mat-card-title>Produktübersicht</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div>
       <!--<div class="filterField">
        <mat-form-field style="width: 100%">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilterProduct($event)" #input>
        </mat-form-field>
      </div>-->
       <div class="activeCheckboxField">
         <mat-checkbox (change)="setShowAllProducts($event.checked)" *ngIf="machineSelected">Deaktivierte Fächer anzeigen</mat-checkbox>
         <span *ngIf="machineSelected" (click)="fillAllProducts()" class="actionEdit"><mat-icon>upload</mat-icon>Alle Produkte auffüllen</span>
      </div>
      <form [formGroup]="machinesForm">
        <table mat-table [dataSource]="proddataSource" matSort class="mat-elevation-z8 locationstable" #productsTable="matSort">
          <!-- Shelf Column -->
          <ng-container matColumnDef="shelf">
          <th mat-header-cell *matHeaderCellDef mat-sort-header > Fach </th>
          <td mat-cell *matCellDef="let element"> {{element.shelf}} </td>
        </ng-container>
        <!-- Active Column -->
        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef mat-sort-header > Aktiv </th>
          <td mat-cell *matCellDef="let element"><mat-checkbox [formControl]="formActive.controls[proddataSource.filteredData.indexOf(element)]" (change)="changeActiveStateProduct($event, element)"></mat-checkbox></td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header > Produkt </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field>
              <mat-select [disabled]="!element.active" [ngModel]="element.product" (selectionChange)="changeProduct(element, $event)" [formControl]="formProducts.controls[proddataSource.filteredData.indexOf(element)]">
                <mat-option [value]="">---</mat-option>
                <mat-option *ngFor="let product of allProducts" [value]="product._id">
                  {{getProductName(product._id)}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>
        <!-- Stock Column -->
        <ng-container matColumnDef="stock">
          <th mat-header-cell *matHeaderCellDef mat-sort-header > Warenstand </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field class="stockInput" *ngIf="element.active && formProducts.controls[proddataSource.filteredData.indexOf(element)].value">
              <input matInput [formControl]="formStock.controls[proddataSource.filteredData.indexOf(element)]" min="0" max="100">
              <mat-error *ngIf="formStock.controls[proddataSource.filteredData.indexOf(element)].hasError('required')">
                !
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
         <!-- Max Stock Column -->
         <ng-container matColumnDef="maxstock">
          <th mat-header-cell *matHeaderCellDef mat-sort-header > Max. Produkte </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field class="stockInput" *ngIf="element.active && formProducts.controls[proddataSource.filteredData.indexOf(element)].value">
              <input matInput [formControl]="formMaxStock.controls[proddataSource.filteredData.indexOf(element)]" min="0" max="100">
              <mat-error *ngIf="formMaxStock.controls[proddataSource.filteredData.indexOf(element)].hasError('required')">
                !
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header > Auffüllen </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon class="actionEdit" *ngIf="element.active && formProducts.controls[proddataSource.filteredData.indexOf(element)].value" (click)="fillProducts(proddataSource.filteredData.indexOf(element))">upload</mat-icon>
      </td>
    </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsProd"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsProd;"></tr>

        </table>
      </form>
        <!--<mat-paginator #productsPaginator [pageSizeOptions]="[20, 50, 100]"></mat-paginator>-->
      </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="editStock()" [disabled]="!machinesForm.valid" *ngIf="machineSelected">Warenstand und maximale Produktanzahl speichern</button>
      </mat-card-actions>
    </mat-card>
  </div>


</div>

</html>

