<div>
  <div id="loading" *ngIf="initialLoading">
    <mat-spinner></mat-spinner>
  </div>
<h2 mat-dialog-title *ngIf="data.option !== 3">Zutrittsprotokoll</h2>
<mat-dialog-content class="mat-typography">
  <mat-progress-bar mode="indeterminate" *ngIf="loadingProtocoll"></mat-progress-bar>
  <div>
    <div class="filterField">
      <mat-form-field style="width: 100%">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilterProtocoll($event)" #input>
      </mat-form-field>
    </div>

  <table mat-table matSort [dataSource]="protdataSource" class="mat-elevation-z8" #protocollTable="matSort" *ngIf="data.option !== 3">
      <!-- Time Column -->
      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Zeitpunkt </th>
        <td mat-cell *matCellDef="let element"> {{element.timestamp | date:'dd.MM.yyyy HH:mm:ss'}} </td>
      </ng-container>
            <!-- Typ Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Typ </th>
              <td mat-cell *matCellDef="let element"> {{element.status}} </td>
            </ng-container>
          <!-- Nachricht Column -->
          <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nachricht </th>
            <td mat-cell *matCellDef="let element"> {{element.message}} </td>
          </ng-container>
                            <!-- Code Column -->
      <ng-container matColumnDef="usedcode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
        <td mat-cell *matCellDef="let element"> {{element.usedCode}}# </td>
      </ng-container>
                <!-- Codebezeichnung Column -->
                <ng-container matColumnDef="bezeichnung">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Codebezeichnung </th>
                  <td mat-cell *matCellDef="let element"> {{element.bezeichnung}} </td>
                </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumnsProt"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsProt;" [class.row_red]="row.status===3" [class.row_yellow]="row.status===2"></tr>

  </table>
  <mat-paginator *ngIf="data.option !== 3" #protocollPaginator [pageSizeOptions]="[10,20,50]"></mat-paginator>

  <table mat-table matSort [dataSource]="protdataSource" class="mat-elevation-z8 machinetable" #protocollTable="matSort" *ngIf="data.option === 3">
    <!-- Time Column -->
    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Zeitpunkt </th>
      <td mat-cell *matCellDef="let element"> {{element.time | date:'dd.MM.yyyy HH:mm:ss'}} </td>
    </ng-container>
          <!-- Typ Column -->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Typ </th>
            <td mat-cell *matCellDef="let element"> {{element.statustype}} </td>
          </ng-container>
        <!-- Gerät Column -->
        <ng-container matColumnDef="device">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Gerät </th>
          <td mat-cell *matCellDef="let element"> {{element.device}} </td>
        </ng-container>
    <!-- Gerät Column -->
    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nachricht </th>
      <td mat-cell *matCellDef="let element"> {{element.message}} </td>
    </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumnsProt2"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnsProt2;"></tr>

</table>

  <mat-paginator *ngIf="data.option === 3" #protocollPaginator [pageSizeOptions]="[5,10,50]"></mat-paginator>
</div>

<p><button mat-raised-button color="primary" mat-dialog-close class="dialogButton" (click)="confirm()">Schließen</button></p>
</mat-dialog-content>
</div>
