<html>
<div class="container" fxLayout="row wrap" fxLayoutGap="16px grid">
  <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-card class="admincard">
      <mat-card-header>
        <mat-card-title>Automatenhersteller</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-progress-bar mode="indeterminate" *ngIf="loadingManufacturers"></mat-progress-bar>
        <div>
        <div class="filterField">
        <mat-form-field style="width: 100%">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" #input>
        </mat-form-field>
      </div>
        <table mat-table #manufTable="matSort" [dataSource]="manufSource" matSort class="mat-elevation-z8 manufacturerstable">
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>
           <!-- Active Column -->
           <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Aktiv </th>
            <td mat-cell *matCellDef="let element"><mat-checkbox [checked]="element.active" (change)="changeActiveState($event, element)"></mat-checkbox></td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Aktionen </th>
           <td mat-cell *matCellDef="let element"><mat-icon class="actionDelete" (click)="deleteManufacturer(element._id)">delete</mat-icon><mat-icon class="actionEdit" (click)="updateManufacturer(element)">edit</mat-icon></td>
           </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedManuColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedManuColumns;"></tr>

        </table>
        <mat-paginator #manuPaginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="newManufacturer()">Neuer Hersteller</button>
      </mat-card-actions>
    </mat-card>
  </div>
  <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-card class="locationcard">
      <mat-card-header>
        <mat-card-title>Modelle</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-progress-bar mode="indeterminate" *ngIf="loadingModels"></mat-progress-bar>
        <div>
        <div class="filterField">
        <mat-form-field style="width: 100%">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyTypeFilter($event)" #input>
        </mat-form-field>
      </div>
        <table mat-table [dataSource]="modelSource" matSort class="mat-elevation-z8 manufacturerstable" #typesTable="matSort">
         <!-- Name Column -->
         <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header > Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
         <!-- Hersteller Column -->
         <ng-container matColumnDef="manufacturer">
          <th mat-header-cell *matHeaderCellDef mat-sort-header > Hersteller </th>
          <td mat-cell *matCellDef="let element">{{getManufacturerName(element.manufacturer)}}</td>

        </ng-container>
         <!-- Config Column -->
         <ng-container matColumnDef="config">
          <th mat-header-cell *matHeaderCellDef mat-sort-header > Konfiguration </th>
          <td mat-cell *matCellDef="let element"> {{getConfigName(element.configuration)}} </td>
        </ng-container>
         <!-- Active Column -->
         <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef mat-sort-header > Aktiv </th>
          <td mat-cell *matCellDef="let element"><mat-checkbox [checked]="element.active" (change)="changeActiveStateTypes($event, element)"></mat-checkbox></td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Aktionen </th>
         <td mat-cell *matCellDef="let element"><mat-icon class="actionDelete" (click)="deleteMachineType(element._id)">delete</mat-icon><mat-icon class="actionEdit" (click)="updateMachineType(element)">edit</mat-icon></td>
         </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedModelColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedModelColumns;"></tr>
        </table>
        <mat-paginator #typePaginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="newMachineType()">Neues Modell</button>
      </mat-card-actions>
    </mat-card>
  </div>


  <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-card class="admincard">
      <mat-card-header>
        <mat-card-title>Konfigurationen</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-progress-bar mode="indeterminate" *ngIf="loadingConfig"></mat-progress-bar>
        <div>
        <div class="filterField">
        <mat-form-field style="width: 100%">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyConfigFilter($event)" #input>
        </mat-form-field>
      </div>
        <table mat-table [dataSource]="configSource" matSort class="mat-elevation-z8 manufacturerstable" #configTable="matSort">
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>
            <!-- Active Column -->
         <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef mat-sort-header > Aktiv </th>
          <td mat-cell *matCellDef="let element"><mat-checkbox [checked]="element.active" (change)="changeActiveStateConfig($event, element)"></mat-checkbox></td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Aktionen </th>
         <td mat-cell *matCellDef="let element"><mat-icon class="actionDelete" (click)="deleteMachineConfig(element._id)">delete</mat-icon><mat-icon class="actionEdit" (click)="updateMachineConfig(element)">edit</mat-icon></td>
         </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedConfigColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedConfigColumns;"></tr>
        </table>
        <mat-paginator #configPaginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="newConfig()">Neue Konfiguration</button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-card class="admincard">
      <mat-card-header>
        <mat-card-title>RegioConnect / RegioLock Geräte</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-progress-bar mode="indeterminate" *ngIf="loadingSatellites"></mat-progress-bar>
        <div>
        <div class="filterField">
        <mat-form-field style="width: 100%">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applySatelliteFilter($event)" #input>
        </mat-form-field>
      </div>
        <table mat-table [dataSource]="satelliteSource" matSort class="mat-elevation-z8 manufacturerstable" #satelliteTable="matSort">
          <!-- Key Column -->
          <ng-container matColumnDef="raspkey">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Key </th>
            <td mat-cell *matCellDef="let element"> {{element.raspkey}} </td>
          </ng-container>
        <!-- Interne IP Column -->
        <ng-container matColumnDef="internalid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header > Interne IP </th>
          <td mat-cell *matCellDef="let element"> {{element.int_ip}} </td>
        </ng-container>
            <!-- Active Column -->
         <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef mat-sort-header > Aktiv </th>
          <td mat-cell *matCellDef="let element"><mat-checkbox [checked]="element.active" (change)="changeActiveStateSatellite($event, element)"></mat-checkbox></td>
        </ng-container>

                    <!-- Regiolock Column -->
          <ng-container matColumnDef="regiolock">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > RegioLock </th>
            <td mat-cell *matCellDef="let element"><mat-checkbox [checked]="element.regiolock" [disabled]="true"></mat-checkbox></td>
          </ng-container>
          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Status </th>
            <td mat-cell *matCellDef="let element">
              <div *ngIf="getSatelliteStatus(element)==1" class="status_indicator status_green"></div>
              <div *ngIf="getSatelliteStatus(element)==2" class="status_indicator status_grey"></div>
              <div *ngIf="getSatelliteStatus(element)==3" class="status_indicator status_red"></div>
            </td>
          </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Aktionen </th>
         <td mat-cell *matCellDef="let element"><mat-icon class="actionDelete" (click)="deleteSatellite(element._id)">delete</mat-icon><mat-icon class="actionEdit" (click)="updateSatellite(element)">edit</mat-icon><mat-icon class="actionEdit" *ngIf="(element.flag_stat1 || element.available) && element.active" (click)="unbindSatellite(element)">desktop_access_disabled</mat-icon><mat-icon *ngIf="element.flag_stat1 && element.active" class="actionEdit" (click)="restartSatellite(element)">refresh</mat-icon></td>
         </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedSatellitesColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedSatellitesColumns;"></tr>
        </table>
        <mat-paginator #satPaginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="newSatellite()">Neues Gerät</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

</html>
