import { Component, Input, OnInit, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApiService } from '../api.service';
import { Client, User } from '../schemas';
import { TokenService } from '../token.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as bcrypt from 'bcryptjs';

export interface DialogDataConfirm {
  text: string;
  option: number;
}


@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.css']
})

export class MyprofileComponent implements OnInit {

  @Input() currentClient: Client;
  @Input() loggedInUser: User;
  @ViewChild('productsTable', { read: MatSort, static: true }) proSort: MatSort;
  @ViewChild('productsPaginator', {static: true}) proPaginator: MatPaginator;
  token: string;
  loadingData = false;
  passwordConfirmFalse = false;
  gotUser: any;
  usermail = '';
  userName = '';

  constructor(private api: ApiService, public dialog: MatDialog, private tokenService: TokenService) { }

  userForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    servicelevel: new FormControl(''),
    currentpassword:  new FormControl(''),
    newpassword: new FormControl('', Validators.minLength(6)),
    passwordconfirmation: new FormControl('', Validators.minLength(6))
  });

  ngOnInit(): void {
    this.tokenService.currentToken.subscribe(token => this.token = token);
  }

  ngAfterViewInit() {
    this.getCurrentUserData();
  }

  saveData = () => {
    const formValue = this.userForm.value;
    const newpw = formValue.newpassword;
    const confirmpw = formValue.passwordconfirmation;
    const oldpw = formValue.currentpassword;

    const checkPW = new Promise ((resolve) => {
    const oldpw = formValue.currentpassword;
      if (newpw && oldpw) {
        if (newpw.length >= 6 && oldpw.length >= 3) {
          // Passwörter untereinander vergleichen
          if (newpw === confirmpw) {
            resolve (2);
          } else {
            resolve (3);
          }
        } else {
          resolve (1);
        }
      } else {
        resolve (1);
      }
    });

    checkPW.then((pwstatus: number) => {
      if (pwstatus === 3) {
        this.passwordConfirmFalse = true;
        this.dialog.open(DialogConfirmProfile, {
          width: '40%',
          data: {
             option: 3,
             text: 'Die neuen Passwörter stimmen nicht überein!'
          },
          hasBackdrop: true
        });
        this.resetPWFields();
      } else if (pwstatus === 2) {
        // Altes Passwort prüfen
        this.api.checkpw(oldpw, this.token)
        .subscribe((res: boolean) => {
          if (res) {
            // Aktuelles Passwort OK, daten speichern
            this.saveNewUserdata(true);
          } else {
            this.dialog.open(DialogConfirmProfile, {
              width: '40%',
              data: {
                 option: 3,
                 text: 'Das eingegebene aktuelle Passwort ist falsch'
              },
              hasBackdrop: true
            });
            this.resetPWFields();
          }
            });

      }
    });
  }

  saveNewUserdata = (savepw: boolean) => {
    const userFormValue = this.userForm.value;
    if (savepw) {
      const salt = bcrypt.genSaltSync(10);
      const pass = bcrypt.hashSync(userFormValue.newpassword, salt);
      this.api.changepw(pass, this.token)
      .subscribe((res: boolean) => {
        if (res) {
          this.dialog.open(DialogConfirmProfile, {
            width: '40%',
            data: {
               option: 2,
               text: 'Benutzerdaten erfolgreich geändert'
            },
            hasBackdrop: true
          });
          this.resetPWFields();

        } else {
          this.dialog.open(DialogConfirmProfile, {
            width: '40%',
            data: {
               option: 3,
               text: 'Fehler beim Ändern der Benutzerdaten'
            },
            hasBackdrop: true
          });
          this.resetPWFields();
        }
          });
    }


  }

  resetPWFields = () => {
    this.userForm.patchValue({
      currentpassword: '',
      newpassword: '',
      passwordconfirmation: ''
    });
  }

  confirmPWfalse = () => {
    return this.passwordConfirmFalse;
  }

  currentpwfalse = () => {
    return false;
  }

   public errorHandling = (control: string, error: string) => {
    return this.userForm.controls[control].hasError(error);
  }

  getCurrentUserData = () => {
    this.api.getUserDataFromToken(this.token, this.loggedInUser._id)
    .subscribe((res: User) => {
        this.gotUser = res;
        this.usermail = res.mail;
        this.userName = res.name;
        this.userForm.patchValue({
          name: res.name,
          servicelevel: res.servicelevel
        });
        });

  }



}


@Component({
  selector: 'dialog-confirm-profile',
  templateUrl: 'dialog-confirm.html',
  styleUrls: ['./myprofile.component.css']
})

export class DialogConfirmProfile {


  constructor(
    public dialogRef: MatDialogRef<DialogConfirmProfile>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataConfirm) {}

   confirm(): void {
    this.dialogRef.close(1);
  }

  cancleDialog(): void {
    this.dialogRef.close(0);
  }
}
